import { BsClipboardData, BsFillCalendarCheckFill, BsFillCalendarFill } from 'react-icons/bs'
import { Col, Container, Row } from 'react-bootstrap'
import { BiLeftArrowAlt, BiMessageX } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import { HiCode } from 'react-icons/hi'
import { FaInfo } from 'react-icons/fa'
import { useEffect } from 'react'
import ReactGA from 'react-ga'

import { useGetJobByIdQuery } from '../../../../../api/jobsAPI'
import { CRON_TIME } from '../../../../../helpers/constants'

import './JobDetails.css'

const JobDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data: jobData } = useGetJobByIdQuery(id ? id : '')

    const navigateToScheduleManagement = () => {
        navigate('/dashboard/system-schedule/')
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <button onClick={navigateToScheduleManagement} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                            <BiLeftArrowAlt size={20} /> Back
                        </button>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='d-flex justify-content-between'>
                    <div className='dashboard-body w-49'>
                        <p className='jod-details-title mb-1'>Task Details</p>
                        <p className='job-details-text'>
                            Name: {jobData?.function_name}
                        </p>
                        <p className='job-details-text'>
                            Arguments: {jobData?.function_arguments ? JSON.stringify(jobData?.function_arguments) : ''}
                        </p>
                        <p className='job-details-text'>
                            Queue: {jobData?.queue}
                        </p>
                        <p className='job-details-text'>
                            Created: {jobData?.created_at.split('T')[0]}
                        </p>
                        <p className='job-details-text'>
                            Last run: {jobData?.last_run_at ? jobData?.last_run_at.split('T')[0] : ''}
                        </p>
                        <p className='job-details-text'>
                            Interval: {CRON_TIME[jobData?.periodic_schedule as keyof typeof CRON_TIME]}
                        </p>
                        <p className='job-details-text'>
                            status: {jobData?.status}
                        </p>
                    </div>
                    <div className='dashboard-body w-49'>
                        <p className='jod-details-title mb-1'>Additional Information</p>
                        <p className='job-details-text'>
                            Disabled: {jobData?.disabled ? 'Yes' : 'No'}
                        </p>
                        <p className='job-details-text'>
                            Retries allowed: {jobData?.allow_retries ? 'Yes' : 'No'}
                        </p>
                        <p className='job-details-text'>
                            Running once: {jobData?.is_one_off ? 'Yes' : 'No'}
                        </p>
                        <p className='job-details-text'>
                            Scheduled for retry: {jobData?.is_scheduled_for_retry ? 'Yes' : 'No'}
                        </p>
                        <p className='job-details-text'>
                            Maximum retries: {jobData?.max_retries}
                        </p>
                        <p className='job-details-text'>
                            Retry interval (seconds): {jobData?.retry_seconds_interval}
                        </p>
                        <p className='job-details-text'>
                            Retry times: {jobData?.retry_times}
                        </p>
                    </div>
                </div>
                <div className='dashboard-body mt-3'>
                    <table className='table'>
                        <thead className="thead-light">
                            <tr>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <BsFillCalendarFill className='me-1' size={16} /> Start date
                                    </div>
                                </th>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <BsFillCalendarCheckFill className='me-1' size={16} /> Finish date
                                    </div>
                                </th>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <FaInfo className='me-1' size={18} /> Type
                                    </div>
                                </th>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <BiMessageX className='me-1' size={18} /> Error message
                                    </div>
                                </th>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <HiCode className='me-1' size={18} /> Traceback
                                    </div>
                                </th>
                                <th className='text-center' scope="col">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <BsClipboardData className='me-1' size={18} /> Result
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobData && jobData.results.length > 0 && jobData.results.map((result, idx) => (
                                <tr key={idx} className='table-row-body'>
                                    <td className='text-center job-details-text'>
                                        {result.started_at.split('T')[0]}
                                    </td>
                                    <td className='text-center job-details-text'>
                                        {result.finished_at.split('T')[0]}
                                    </td>
                                    <td className='text-center job-details-text'>
                                        {result.exc_type}
                                    </td>
                                    <td className='text-center job-details-text'>
                                        {result.exc_message}
                                    </td>
                                    <td className='text-center job-details-text max-width-td'>
                                        <span className='wrapped-table-row'>
                                            {result.exc_traceback}  
                                        </span>
                                    </td>
                                    <td className='text-center job-details-text max-width-td'>
                                        <span className='wrapped-table-row'>
                                            {result.result}  
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default JobDetails