import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic';

import './SettingsUserNav.css'

const SettingsUserNav = () => {
    const [isPublic] = useDetectSubdomainOrPublic()

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto settings-nav">
                    <NavLink to="/dashboard/settings-users/">Settings</NavLink>
                    {isPublic && (
                        <>
                            <NavLink to="/dashboard/settings-tenants/">Tenants</NavLink>
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default SettingsUserNav