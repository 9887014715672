import { FC } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";

import { useCompleteOrderTransactionMutation } from '../../../../api/paymentsAPI'
import { TGetOrderResult } from "../../../../api/api.types";

export type TCompletePaymentObject = {
    tenant: number,
    amount: number,
    currency: string,
    order: number,
    kind: string,
    method: string,
    transaction_extra_data: any,
}

type TPayPalButton = {
    orderPay: TGetOrderResult,
    handleCloseManuallPayWithRefetch: () => void,
}

const PayPalButton: FC<TPayPalButton> = ({ orderPay, handleCloseManuallPayWithRefetch }) => {
    const [completeOrderTransaction] = useCompleteOrderTransactionMutation()

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : '';

    const createOrder = (data: any, actions: any) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: orderPay.end_customer_cost / 100,
                        currency_code: orderPay.currency,
                    },
                    custom_id: orderPay.id,
                },
            ],
        });
    };

    const onApprove = (data: any, actions: any) => {
        return actions.order.capture().then(function (details: any) {

            const object = {
                tenant: orderPay.seller_tenant,
                amount: orderPay.end_customer_cost,
                currency: orderPay.currency,
                order: orderPay.id,
                kind: 'payment',
                method: 'paypal',
                transaction_extra_data: details,
            }

            completeOrderTransaction(object).then((res) => {
                if ("error" in res) {
                    toast.error('Something went wrong')
                } else {
                    handleCloseManuallPayWithRefetch()
                    toast.success('The order has been paid successfully')
                }
            })
        })
    };

    const onError = (err: any) => {
        toast.error(`Payment failde: ${err}`)
    };

    return (
        <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
            <PayPalButtons
                className="w-100"
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
            />
        </PayPalScriptProvider>
    )
}

export default PayPalButton
