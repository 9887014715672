import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

import { useInviteExistingUserMutation } from "../../../api/settingsAPI";
import { inviteUserValidationSchema } from "./validator";
import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";

type TInviteUserProps = {
    show: boolean,
    onHide: () => void,
}

export type TInviteUserSubmitData = {
    email: string,
    role: string,
}

const InviteUser: FC<TInviteUserProps> = ({ show, onHide }) => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const [inviteExistingUser] = useInviteExistingUserMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TInviteUserSubmitData>({
        resolver: yupResolver(inviteUserValidationSchema),
    });

    const onSubmit = (data: TInviteUserSubmitData) => {
        inviteExistingUser(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
                onHide()
                toast.success('User invited successfully!')
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Invite Existing User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>Email</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                        {(errors.email || customError === 'User with email specified is already connected to current tenant.') && (
                            <p className="mb-0 form-field-error">{errors.email ? errors.email.message : customError}</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>User Role</Form.Label>
                        <Form.Select defaultValue='STAFF' className="form-input-field" {...register("role")}>
                            <option>Select User Role</option>
                            <option value="OWNER">Owner</option>
                            <option value="STAFF">Staff</option>
                        </Form.Select>
                        {errors.role && <p className="mb-0 form-field-error">{errors.role.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Invite
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default InviteUser