import { createApi } from '@reduxjs/toolkit/query/react';

import { TAddNewTrackingInformation } from '../app/modals/AddTrackingInformation/AddTrackingInformation'

import { TGetOrderResult, TGetOrdersResponse, TOrderNoteSubmitData, TGetTrackingResult } from './api.types';
import { baseQueryWithReAuth } from './apiConfig';

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Orders', 'Order'],
    endpoints: builder => ({
        getOrders: builder.query<TGetOrdersResponse, { activePage: string, pageSize: string, defaultFilter: string, searchFilter: string }>({
            query: ({ activePage, pageSize, defaultFilter, searchFilter }) => {
                return {
                    url: `orders/?expand=line_items,fulfillments,seller_integration&page=${activePage}${pageSize ? `&page_size=${pageSize}` : ''}${defaultFilter && defaultFilter !== 'All' ? `&fulfillments__fulfillment_line_items__status=${defaultFilter}` : ''}${searchFilter ? `&search=${searchFilter}` : ''}`,
                    method: 'GET'
                }
            },
            providesTags: ['Orders']
        }),
        getOrderById: builder.query<TGetOrderResult, number>({
            query: (id) => {
                return {
                    url: `orders/${id}/?expand=line_items.variant_product.images,fulfillments.fulfillment_line_items.variant_product.images,seller_integration,shipping_address,`,
                    method: 'GET',
                }
            },
            providesTags: ['Order']
        }),
        fulfillOrder: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/coherence/integrations/fulfill_order/${id}/`,
                    method: 'PATCH'
                }
            },
            invalidatesTags: ['Orders']
        }),
        cancelOrder: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/coherence/integrations/cancel_order/${id}/`,
                    method: 'PATCH'
                }
            },
            invalidatesTags: ['Orders']
        }),
        addOrderNote: builder.mutation<void, TOrderNoteSubmitData>({
            query: (data) => {
                return {
                    url: `orders/notes/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Order']
        }),
        getTrackingById: builder.query<TGetTrackingResult, number>({
            query: (id) => {
                return {
                    url: `orders/tracking/?fulfillment=${id}`,
                    method: 'GET',
                }
            },
            providesTags: ['Order']
        }),
        submitTrackingInformation: builder.mutation<void, TAddNewTrackingInformation>({
            query: (data) => {
                return {
                    url: `orders/tracking/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Order']
        })
    })
})

export const {
    useGetOrdersQuery,
    useGetOrderByIdQuery,
    useFulfillOrderMutation,
    useCancelOrderMutation,
    useAddOrderNoteMutation,
    useGetTrackingByIdQuery,
    useSubmitTrackingInformationMutation
} = ordersApi
