import { useState } from "react"
import { BsCalendarDate, BsInfoCircle } from "react-icons/bs"
import { AiOutlineBarcode } from "react-icons/ai"
import { TbDiscount2 } from "react-icons/tb"

import { useGetTenantAffiliateQuery } from "../../../../api/affiliateAPI"
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic"

import ChangeAffiliateCode from "../../../modals/ChangeAffiliateCode/ChangeAffiliateCode"

const SettingsAffiliateBlock = () => {
    const [isPublic, subdomain] = useDetectSubdomainOrPublic()
    const [changeCode, setChangeCode] = useState(false)

    const { data: affiliateData } = useGetTenantAffiliateQuery(subdomain, { skip: isPublic || !subdomain })

    const handleChangeCode = () => {
        setChangeCode(true)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="settings-card px-3 mt-4 pb-2">
                    <div className="row dashboard-menu-sub d-flex align-items-center">
                        <div className="col-lg-7">
                            <div className="left">
                                <ul className="dashboard-menu-ul">
                                    <li className="dashboard-menu-li"><span className="dashboard-menu-a">Affiliate code</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right text-right">
                                <button onClick={handleChangeCode} className="dashboard-menu-button ms-2">
                                    {affiliateData ? 'Change code' : 'Add code'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {!affiliateData ? (
                            <div className="not-have-affiliate" id="no-active-affiliate">
                                You don`t have active affiliate code
                                <button onClick={handleChangeCode} className="dashboard-menu-button mt-2">
                                    Add code
                                </button>
                            </div>
                        ) : (
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className='text-center' scope="col">
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <AiOutlineBarcode className="me-1" size={20} /> Active code
                                            </div>
                                        </th>
                                        <th className='text-center' scope="col">
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <TbDiscount2 className="me-1" size={20} /> Benefits discount
                                            </div>
                                        </th>
                                        <th className='text-center' scope="col">
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <BsCalendarDate className="me-1" size={18} /> Expiration date
                                            </div>
                                        </th>
                                        <th className='text-center' scope="col">
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <BsInfoCircle className="me-1" size={18} /> Active
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={affiliateData.id}>
                                        <td className='text-center'>{affiliateData.program.code}</td>
                                        <td className='text-center'>{affiliateData.program.benefits_discount} %</td>
                                        <td className='text-center'>{affiliateData.get_period}</td>
                                        <td className='text-center'>{affiliateData.is_active ? 'Yes' : 'No'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {changeCode && (
                <ChangeAffiliateCode
                    show={changeCode}
                    onHide={() => setChangeCode(false)}
                />
            )}
        </div>
    )
}

export default SettingsAffiliateBlock