import { FC, useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai"
import { BiInfoCircle } from "react-icons/bi"
import { FaCcStripe, FaUser, FaUserTag } from "react-icons/fa"
import { HiOutlineCog } from "react-icons/hi"
import { RxEnvelopeClosed } from "react-icons/rx"
import { toast } from "react-toastify"
import { IoCheckmark } from "react-icons/io5";

import { useDeleteStripePaymentMethodMutation, useDeleteWiseReceiptMutation, useGetStripePaymentMethodsQuery, useGetWiseReceiptsQuery } from "../../../../api/paymentsAPI"
import { useGetTenantUsersQuery, useUpdateTenantUserMutation } from "../../../../api/settingsAPI"

import PagePagination from "../../common/Pagination/PagePagination"
import InviteUser from "../../../modals/InviteUser/InviteUser"
import AddNewUser from "../../../modals/AddNewUser/AddNewUser"
import usePagination from "../../../../hooks/usePagination"
import AddPaymentMethod from "../../../modals/AddPaymentMethod/AddPaymentMethod"
import { BsArrowUpRightSquare } from "react-icons/bs"
import StripePaymentMethod from "../../../modals/StripePaymentMethod/StripePaymentMethod"
import WiseReceipt from "../../../modals/WiseReceipt/WiseReceipt"

type TSettingsUsersFinancialsBlock = {
    isPublic: boolean,
}

const SettingsUsersFinancialsBlock: FC<TSettingsUsersFinancialsBlock> = ({ isPublic }) => {
    const [showUsers, setShowUsers] = useState(true)
    const [addPayment, setAddPayment] = useState(false)
    const [addNewUserModal, setAddNewUserModal] = useState(false)
    const [inviteUserModal, setInviteUserModal] = useState(false)
    const [viewPaymentMethodModal, setViewPaymentMethodModal] = useState(false)
    const [paymentMethodViewId, setPaymentMethodViewId] = useState(0)
    const [viewWiseReceiptModal, setViewWiseReceiptModal] = useState(false)
    const [wiseReceiptViewId, setWiseReceiptViewId] = useState(0)
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', 10)
    const [updateTenantUser] = useUpdateTenantUserMutation()
    const [deletePaymentMethod] = useDeleteStripePaymentMethodMutation()
    const [deleteWiseReceipt] = useDeleteWiseReceiptMutation()

    const { data: tenantUsers } = useGetTenantUsersQuery(activePage, { skip: isPublic })
    const { data: stripePaymentsMethods, refetch: refetchStripePayments } = useGetStripePaymentMethodsQuery(undefined, { skip: isPublic })
    const { data: wiseReceipts, refetch: refetchWiseReceipts } = useGetWiseReceiptsQuery(undefined, { skip: isPublic })

    const refetchPaymentMethodsAfterUpdate = () => {
        refetchStripePayments()
    }

    const refetchWiseReceiptsAfterUpdate = () => {
        refetchWiseReceipts()
    }

    const handleCloseViewPaymentModal = () => {
        setViewPaymentMethodModal(false)
        setPaymentMethodViewId(0)
    }

    const handleDeletePaymentMethod = (id: number) => {
        deletePaymentMethod(id)
    }

    const handleViewPaymentMethod = (id: number) => {
        setPaymentMethodViewId(id)
        setViewPaymentMethodModal(true)
    }

    const handleCloseWiseReceiptModal = () => {
        setViewWiseReceiptModal(false)
        setWiseReceiptViewId(0)
    }

    const handleViewWiseReceipt = (id: number) => {
        setWiseReceiptViewId(id)
        setViewWiseReceiptModal(true)
    }

    const handleDeleteWiseReceipt = (id: number) => {
        deleteWiseReceipt(id)
    }

    const handleChangeUserRole = (id: number, role: string, status: boolean) => {
        updateTenantUser({ data: { role: { role, is_active: status } }, id }).then((response) => {
            if ('error' in response) {
                toast.error('You don`t have permission to perform this action!')
            }
        })
    }

    useEffect(() => {
        if (tenantUsers) {
            handlePagesCount(tenantUsers?.count)
        }
    }, [tenantUsers, handlePagesCount])

    return (
        <div className="row">
            <div className="col-12">
                <div className="settings-card px-3 mt-4 pb-2">
                    <div className="row dashboard-menu-sub d-flex align-items-center">
                        <div className="col-lg-7">
                            <div className="left">
                                <ul className="dashboard-menu-ul">
                                    <li onClick={() => setShowUsers(true)} className="dashboard-menu-li">
                                        <span className={showUsers ? "dashboard-menu-a" : 'dashboard-menu-a border-0'}>Users</span>
                                    </li>
                                    <li onClick={() => setShowUsers(false)} className="dashboard-menu-li">
                                        <span className={!showUsers ? "dashboard-menu-a" : 'dashboard-menu-a border-0'}>Financials</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {showUsers ? (
                                <div className="right text-right">
                                    <button onClick={() => setAddNewUserModal(true)} className="dashboard-menu-button ms-2">
                                        <AiOutlinePlus size={18} /> Add new User
                                    </button>
                                    <button onClick={() => setInviteUserModal(true)} className="dashboard-menu-button ms-2">
                                        <AiOutlinePlus size={18} /> Invite existing User
                                    </button>
                                </div>
                            ) : (
                                <div className="right text-right">
                                    <button onClick={() => setAddPayment(true)} className="dashboard-menu-button ms-2">
                                        Add payment/deposit method
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="settingsUesrManagetable">
                        {showUsers ? (
                            <>
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className='text-center' scope="col">
                                                <FaUser /> Name
                                            </th>
                                            <th className='text-center' scope="col">
                                                <RxEnvelopeClosed size={20} /> Email
                                            </th>
                                            <th className='text-center' scope="col">
                                                <FaUserTag size={20} /> Active
                                            </th>
                                            <th className='text-center' scope="col">
                                                <BiInfoCircle size={20} /> Role
                                            </th>
                                            <th className='text-center' scope="col">
                                                <HiOutlineCog size={20} /> Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tenantUsers && tenantUsers.results.map((user) => (
                                            <tr key={user.id}>
                                                <td className='text-center'>{user.first_name} {user.last_name}</td>
                                                <td className='text-center'>{user.email}</td>
                                                <td className='text-center'>{user.role.is_active ? 'Yes' : 'No'}</td>
                                                <td className='text-center'>
                                                    <Dropdown className='d-flex justify-content-center'>
                                                        <Dropdown.Toggle style={{ width: '110px' }} className="catalog-button-blue dropdown-settings-button">
                                                            {user.role.role === 'Owner' ? 'Owner' : 'Staff'}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleChangeUserRole(user.id, 'OWNER', user.role.is_active)} >Owner</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleChangeUserRole(user.id, 'STAFF', user.role.is_active)} >Staff</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td className='text-center'>
                                                    <Dropdown className='d-flex justify-content-center'>
                                                        <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                                            Action
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleChangeUserRole(user.id, user.role.role.toUpperCase(), !user.role.is_active)}>
                                                                {user.role.is_active ? 'Deactivate' : 'Activate'}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {pages.length > 1 && (
                                    <PagePagination
                                        pages={pages}
                                        activePage={activePage}
                                        handlePage={handlePage}
                                    />
                                )}
                            </>
                        ) : (
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className='text-center' scope="col">
                                            Method
                                        </th>
                                        <th className='text-center' scope="col">
                                            Actions
                                        </th>
                                        <th className='text-center' scope="col" style={{ width: '40px' }}>
                                            Default
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stripePaymentsMethods && stripePaymentsMethods.results.length > 0 && stripePaymentsMethods.results.map((method) => (
                                        <tr key={method.id}>
                                            <td className='text-center'>
                                                <FaCcStripe size={22} className='me-1' /> Stripe card **** **** **** {method.last4} ({method.brand})
                                            </td>
                                            <td className='text-center'>
                                                <button onClick={() => handleViewPaymentMethod(method.id)} className="square-white-button">
                                                    <BsArrowUpRightSquare className="mb-1" size={16} fill="green" />
                                                </button>
                                                <button onClick={() => handleDeletePaymentMethod(method.id)} className="square-white-button">
                                                    <AiOutlineDelete size={20} fill="red" />
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                    {method.default && <span className="default-marker"><IoCheckmark style={{ color: 'green' }}/></span>}
                                            </td>
                                        </tr>
                                    ))}
                                    {wiseReceipts && wiseReceipts.results.length > 0 && wiseReceipts.results.map((wise) => (
                                        <tr key={wise.id}>
                                            <td className='text-center'>
                                                Bank Account: {wise.full_name}
                                            </td>
                                            <td className='text-center'>
                                                <button onClick={() => handleViewWiseReceipt(wise.id)} className="square-white-button">
                                                    <BsArrowUpRightSquare className="mb-1" size={16} fill="green" />
                                                </button>
                                                <button onClick={() => handleDeleteWiseReceipt(wise.id)} className="square-white-button">
                                                    <AiOutlineDelete size={20} fill="red" />
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                    {wise.default && <span className="default-marker"><IoCheckmark style={{ color: 'green' }}/></span>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {addNewUserModal && <AddNewUser show={addNewUserModal} onHide={() => setAddNewUserModal(false)} />}
            {inviteUserModal && <InviteUser show={inviteUserModal} onHide={() => setInviteUserModal(false)} />}
            {addPayment && <AddPaymentMethod show={addPayment} onHide={() => setAddPayment(false)} />}
            {viewPaymentMethodModal && <StripePaymentMethod refetchPaymentMethodsAfterUpdate={refetchPaymentMethodsAfterUpdate} show={viewPaymentMethodModal} onHide={handleCloseViewPaymentModal} id={paymentMethodViewId} />}
            {viewWiseReceiptModal && <WiseReceipt refetchWiseReceiptsAfterUpdate={refetchWiseReceiptsAfterUpdate} show={viewWiseReceiptModal} onHide={handleCloseWiseReceiptModal} id={wiseReceiptViewId} />}
        </div>
    )
}

export default SettingsUsersFinancialsBlock