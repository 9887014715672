import { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai'
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga'

import "./FAQ.css"

const FAQ = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
       <div className='min-block-height d-flex justify-content-center align-items-center bg-white py-5 px-4'>
            <Col lg={8}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I get paid as an FBA seller?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                When the order is fully executed then the money will be transferred to the Stripe account, which is connected to CloudOrder.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How am I get charged to dropship products from CloudOrder?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                When you set up your account with CloudOrder, please have a credit card added to your account.
                                Charges will be applied using the credit card from the default payment method in your account.
                                The default payment method can be set on the “Financial settings” profile page.
                                When you click the payment button you will be asked to type in your credit card data.
                                <br />
                                <p>&nbsp;</p>
                                You may also choose not to use automatic payment. In that case, for manual payment you need to:
                                <br />
                                - Go to the “Orders” section in the main menu on the left.
                                <br /> 
                                - Select the target order you wish to make payment.
                                <br />
                                - Press the “Select” button on the left and choose “Pay” button.
                                <br />
                                - After that you will be able to choose one of your cards and use it to pay for the order.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />If I am an FBA seller and have a shopify store, how is my transaction get charged and paid?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                            If you are an FBA seller and a Shopify store owner, you may set up your CloudOrder account with both an Amazon integration and a Shopify integration. Your orders from Shopify store will be received by CloudOrder and fulfilled by your Amazon seller account. On order import to CloudOrder from your shopify store and fulfilled by your Amazon Seller account, you will only be charged for CloudOrder transaction fees. You will not be charged or paid for the order since you own the product.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I connect CloudOrder to my Amazon Store?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                - Login into your CloudOrder tenant.
                                <br />
                                - In the Lower left corner select the “Settings” (Сogwheel).
                                <br />
                                - When you open the “Settings”, Click the button “Add new integration” in the integration table.
                                <br /> 
                                - From the list of proposed integrations, select the Amazon FBA.
                                <br />
                                - Follow the instructions in the form, fill the form and click "Connect".
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I connect CloudOrder to my Shopify Store?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                There are two ways you can connect your Shopify store to CloudOrder:
                                <br />
                                <p>&nbsp;</p>
                                A: In your Shopify store you can install CloudOrder application from the Shopify App store:
                                <br />
                                <a href="https://apps.shopify.com/multi-channel-drop-shipping">https://apps.shopify.com/multi-channel-drop-shipping</a>
                                <p>&nbsp;</p>
                                <br />
                                B: You can also connect your Shopify store by adding a Shopify integration in CloudOrder by :
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Lower left corner select the “Settings” (Сogwheel).
                                <br />
                                - On the following page, click the “Add new integration” button in the integration table.
                                <br />
                                - From the list of proposed integrations, select the Shopify.
                                <br />
                                - Fill in your shopify store name and click "Connect".
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I import products from my Amazon store to CloudOrder?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                - Login into your CloudOrder tenant.
                                <br />
                                - In the Lower left corner select the “Settings” (Сogwheel).
                                <br />
                                - On the following page, click “Select” on the right side of the required integration row.
                                <br />
                                - From the list of proposed actions, select the “Import” and confirm the action.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="6">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I change the product cost/pricing for my products listed on CloudOrder?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                A: To manually change the cost for a single product:
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Left Menu select the “Inventory”.
                                <br />
                                - On the following page, click “Select” on the right side of the required product row.
                                <br />
                                - From the list of proposed actions, select the “Change Cost” and confirm the action.
                                <br />
                                <p>&nbsp;</p>
                                B: To change the pricing for bulk product list:
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Left Menu select the “Inventory”.
                                <br />
                                - On the following page, click on the checkbox on the left side of required product rows.
                                <br />
                                - Once you select products click the “Export” button on the top right corner.
                                <br />
                                - Make your changes to *.CSV file you downloaded to your desktop.
                                <br />
                                - Save the changes. ( Please make sure your file is saved as *.CSV file type)
                                <br />
                                - Import the updated file by clicking on the “Import” button on the top right corner.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="7">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />Why aren’t my products showing up in CloudOrder's catalog?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                By default, all products you imported from Amazon are private to your own account. In order for your products to be seen
                                and available in CloudOrder public catalog, products need to be made public. CloudOrder administrators will review the
                                product and approve the status. Follow the steps below to make the products public:
                                <br />
                                <p>&nbsp;</p>
                                Request to make single product into CloudOrder public catalog:
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Left Menu select the “Inventory”.
                                <br />
                                - On the following page, click “Select” on the right side of the required product row.
                                <br />
                                - From the list of proposed actions, select the “Make Public” and confirm the action.
                                <br />
                                - Allow 24 hours for CloudOrder admin to review and approve the request.
                                <br /> 
                                <p>&nbsp;</p>
                                Request to make multiple products public:
                                <br />
                                - Login into your CloudOrder tenant.
                                <br />
                                - In the Left Menu select the “Inventory”.
                                <br />
                                - On the following page, click on the checkbox on the left side of required product rows.
                                <br />
                                - Once you select products click the “Export” button on the top right corner.
                                <br />
                                - Make your changes to *.CSV file by updating the products to “public”.
                                <br />
                                - Save the changes. ( Please make sure your file is saved as *.csv file type)
                                <br />
                                - Import the updated file by clicking on the “Import” button on the top right corner.
                                <br />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="8">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />I have a lot of SKUs, how do I bulk edit the cost/price of my products?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                - Login into your CloudOrder account.
                                <br />
                                - In the left menu select the “Inventory”.
                                <br />
                                - On the following page, click on the checkbox on the left side of desired product rows.
                                <br />
                                - Once you select products click the “Export” button on the top right corner.
                                <br />
                                - Make your changes to the downloaded *.CSV file (update cost). Save the file. ( *.csv ).
                                <br />
                                - Import the updated file by clicking on the “Import” button on the top right corner.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="9">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />What are the different shipping tiers and shipping rates?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                Platforms such as Shopify by default use three different delivery methods:
                                <br />
                                - Standard
                                <br />
                                - Economy
                                <br />
                                - Free Shipping
                                <br />
                                <p>&nbsp;</p>
                                On CloudOrder all these methods by default are mapped to Standard delivery.
                                <p>&nbsp;</p>
                                CloudOrder uses "Standard" delivery method by default, which maps to Amazon FBA’s "Standard" delivery method. “Standard”
                                delivery usually delivers products within 5 business days. The fees will depend on the product’s weight and size,
                                according to FBA policy.
                                <p>&nbsp;</p>
                                The delivery fees are calculated by Amazon fulfillment service and passed on to the order as the "Standard" delivery
                                fee. Shipping fees can be found in the order details.
                                <p>&nbsp;</p>
                                In addition, Shopify store owners can also add two more delivery methods: Expedited (two working days), Priority (one
                                working day). They are mapped to Amazon’s shipping methods. Amazon fulfillment fees will be applied accordingly.
                                Delivery fee will be updated after order is fulfilled by Amazon. The fee details can be found in the order details.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="10">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How often are my products synced from Amazon?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                Your products will be synced automatically once per day. By default, only new products are synced.
                                <br />
                                
                                If you require an update of existing product, goto
                                <br />
                                <p>&nbsp;</p>
                                Integrations -&gt; Select -&gt; Import and Update products
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="11">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How are reviews imported from Amazon to CloudOrder?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                CloudOrder automatically imports product reviews every week.
                                <br />
                                <p>&nbsp;</p>
                                To initiate import procedure manually:
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Lower left corner select the “Settings” (Сogwheel).
                                <br />
                                - On the following page, click “Select” on the right side of the required integration row.
                                <br />
                                - From the list of proposed actions, select the “Import Product Reviews” and confirm the action.
                                <br />
                                <p>&nbsp;</p>
                                The process of import reviews will take some xxx hours depending on the number of products.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="12">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How to export reviews from CloudOder to Shopify store?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Left Menu select the “Catalog”.
                                <br />
                                - Select the product you need to export reviews for.
                                <br />
                                - Click the “Actions” button on the top right corner of the page.
                                <br />
                                - From the list of proposed actions, select the “Export to Store”.
                                <br />
                                - Click on the checkbox on the left side of “Override existing products(only for Shopify integration)” and confirm the action.
                                <br />
                                <p>&nbsp;</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="13">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I check the status of my product imports from Amazon?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                Initial product import takes some time, depending on the number of SKUs in your store.
                                <br />
                                Please allow 24 hours for the process to finish.
                                <br />
                                You may also request information from CloudOrder support at: 
                                <br />
                                <a href="mailto:info@cloudorder.us">info@cloudorder.us</a>
                                <br />
                                CloudOrder will reply with related information.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="14">
                        <Accordion.Header className='collapse-btn'><AiOutlinePlus className="me-2" />How do I find a specific category of products to dropship for my Shopify store?</Accordion.Header>
                        <Accordion.Body>
                            <div className='faq-collapse-body'>
                                All products can be filtered by category, search by name or search by SKU:
                                <br />
                                - Login into your CloudOrder account.
                                <br />
                                - In the Left Menu select the “Catalog”.
                                <br />
                                - On the following page, select the required category by clicking the “Category” button on the top navigation bar.
                                <br />
                                <p>&nbsp;</p>
                                If you do not have a CloudOrder account, you may use the public catalog to search for products.
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
       </div>
    );
}
export default FAQ;