import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { useGetShopifyLinksMutation } from '../../../api/integrationsAPI'
import { addShopifyIntegrationValidationSchema } from './validator'

import shopify from '../../assets/images/shopify.jpg'

import './AddShopifyIntegration.css'

export type TAddShopifyIntegrationSubmitData = {
    shop: string,
}

type TAddShopifyIntegrationProps = {
    show: boolean,
    onHide: () => void,
}

const AddShopifyIntegration: FC<TAddShopifyIntegrationProps> = ({ show, onHide }) => {
    const [getShopifyLinks, { data: shopifyLink }] = useGetShopifyLinksMutation()
    
    const { register, handleSubmit, formState: { errors } } = useForm<TAddShopifyIntegrationSubmitData>({
        resolver: yupResolver(addShopifyIntegrationValidationSchema),
    });

    const onSubmit = (data: TAddShopifyIntegrationSubmitData) => {
        getShopifyLinks(data)
    }

    useEffect(() => {
        if (shopifyLink) {
            window.location.href = shopifyLink
        }
    }, [shopifyLink])

    return (
        <Modal size='xl' centered show={show} onHide={onHide}>
            <Modal.Header className="border-0 pb-1" closeButton></Modal.Header>
            <Modal.Body className="px-3 pt-0 d-flex">
                <Form onSubmit={handleSubmit(onSubmit)} className="w-100 mt-2">
                    <Row className='p-0 m-0'>
                        <Col className='col-lg-6 pe-2'>
                            <div className='d-flex align-items-center'>
                                <img width={50} src={shopify} alt="" />
                                <span className='shopify-logo-title'>Shopify</span>
                            </div>
                            <p className='mt-2'>
                                Enabling
                                <span className='text-bold ms-1 me-1'>only</span>
                                Orders will allow you to:
                            </p>
                            <p>
                                - Sync your sales channel orders.
                            </p>
                            <p>
                                - Ship orders with carrier integrations.
                            </p>
                            <p>
                                - Manage customer info.
                            </p>
                            <p className='mt-2'>
                                You
                                <span className='text-bold ms-1 me-1'>
                                    will not
                                </span>
                                be able to use:
                            </p>
                            <p>
                                - Dashboard analytics.
                            </p>
                            <p>
                                - Reports.
                            </p>
                            <p className='mt-2'>
                                To use these features you
                                <span className='text-bold ms-1 me-1'>
                                    must enable
                                </span>
                                inventory management.
                            </p>
                            <Form.Group className='mt-2'>
                                <Form.Label className='form-input-label ps-1'>
                                    Shop Name
                                    <span className='red-color'> *</span>
                                </Form.Label>
                                <Form.Control className="form-input-field" type="text" placeholder="Shop Name"  {...register("shop")} />
                                {errors.shop && <p className="mb-0 form-field-error">{errors.shop.message}</p>}
                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 ps-2'>
                            <div className='d-flex flex-column aling-items-center'>
                                <h3 className='red-color text-center'>Integration Guide:</h3>
                                <p className='red-color mt-3 text-center'>
                                    <span className='text-bold red-color me-1'>Please Note:</span>
                                    CloudOrder is not compatible with Multiple warehouse configurations.
                                </p>
                            </div>
                            <ul className='red-color'>
                                <li className='list-item-disc'>Select what you want to sync from your sales channel. Orders, or Orders AND Inventory.</li>
                                <li className='list-item-disc'>Be Aware: if you choose Orders and inventory management, you must update your stocks only from within CloudOrder.</li>
                                <li className='list-item-disc'>Input your store name and press Connect.</li>
                                <li className='list-item-disc'>Once you have done this, you will be redirected to Shopify login. Enter your Email and Password.</li>
                                <li className='list-item-disc'>This will forward you to your store where you will need to tell shopify that you agree to share your information with CloudOrder.</li>
                                <li className='list-item-disc'>Once agreed, it will take you back to CloudOrder and begin importing your products and inventory.</li>
                            </ul>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-end'>
                        <button className="dashboard-menu-button me-3" type="submit">
                            Connect
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddShopifyIntegration