import { FC, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";

import { selectIsAuth } from '../../../../redux/reducers/auth/authSlice';
import { useAppSelector } from '../../../../redux/hooks';

import DashboardHeader from './DashboardHeader/DashboardHeader';

import Logo from '../../../assets/images/logo.png'

import './Header.css';

type THeaderProps = {
    setShowSidebar?: (value: boolean) => void;
    showSidebar?: boolean
}

const Header: FC<THeaderProps> = ({ setShowSidebar, showSidebar }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const isAuth = useAppSelector(selectIsAuth)

    return (
        <>
            {
                isAuth ? (
                    <DashboardHeader expanded={expanded} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                ) : (
                    <Navbar expanded={expanded} expand="lg" className='header px-4 py-0'>
                        <Navbar.Brand className='col-xl-3 col-lg-3 m-0' >
                            <Link to='/'>
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Navbar.Brand >
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                        <Navbar.Collapse id="basic-navbar-nav" className='col-xl-9 col-lg-9 justify-content-between'>
                            <Nav className='col-xl-7 col-lg-7'>
                                <NavLink className='link me-2' to="/" onClick={() => setExpanded(false)}>Home</NavLink>
                                <NavLink className='link me-2' to="/catalog" onClick={() => setExpanded(false)}>Catalog</NavLink>
                                <NavLink className='link me-2' to="/FAQ" onClick={() => setExpanded(false)}>FAQ</NavLink>
                            </Nav>
                            <Nav>
                                <Link className='login me-3' to="/login" onClick={() => setExpanded(false)}>Login</Link>
                                <Link className='register' to="/register" onClick={() => setExpanded(false)}>Register</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar >
                )}
        </>
    );
}
export default Header;