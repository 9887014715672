import { KeyboardEvent, useState } from "react"
import { useSearchParams } from "react-router-dom";

type TReturnFilters = {
    handleDefaultFilter: (param: string, value: string | null, handlePage?: (value: string) => void) => void,
    onKeyDownSearch: (e: KeyboardEvent<HTMLInputElement>, handlePage?: (value: string) => void) => void,
    handleSearch: (value: string) => void,
    handlePageSize: (value: string | null, handlePage?: (value: string) => void) => void,
    handleProductsStatusFilter: (value: string | null, handlePage?: (value: string) => void) => void,
    handleSearchFilter: (handlePage?: (value: string) => void) => void,
    handleCategoryFilter: (value: string, handlePage?: (value: string) => void) => void,
    productStatusFilter: { is_public: string, in_review: string },
    clearFilters: (handlePage?: (value: string) => void) => void,
    categoryFilter: string,
    defaultFilter: string,
    searchFilter: string,
    pageSize: string,
    search: string,
}

const useFilters = (defaultFilterName?: string): TReturnFilters => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchFilter, setSearchFilter] = useState<string>(searchParams.get('search') ? searchParams.get('search') as string : '')
    const [search, setSearch] = useState<string>(searchParams.get('search') ? searchParams.get('search') as string : '')
    const [pageSize, setPageSize] = useState(searchParams.get('page_size') ? searchParams.get('page_size') as string : '10')
    const [defaultFilter, setDefaultFilter] = useState(searchParams.get(`${defaultFilterName}`) ? searchParams.get(`${defaultFilterName}`) as string : '')
    const [categoryFilter, setCategoryFilter] = useState(searchParams.get('category_id') ? searchParams.get('category_id') as string : '')
    const [productStatusFilter, setProductStatusFilter] = useState({
        in_review: searchParams.get('in_review') ? searchParams.get('in_review') as string : '',
        is_public: searchParams.get('is_public') ? searchParams.get('is_public') as string : '',
    })

    const handleSearch = (value: string) => {
        setSearch(value)
    }

    const handleProductsStatusFilter = (value: string | null, handlePage?: (value: string) => void) => {
        switch (value) {
            case 'public': {
                setSearchParams((prev) => {
                    prev.set('page', '1')
                    prev.set('in_review', 'false')
                    prev.set('is_public', 'true')

                    if (handlePage) {
                        handlePage('1')
                    }

                    return prev
                })
                setProductStatusFilter({
                    in_review: 'false',
                    is_public: 'true'
                })
                break;
            }
            case 'private': {
                setSearchParams((prev) => {
                    prev.set('page', '1')
                    prev.set('in_review', 'false')
                    prev.set('is_public', 'false')

                    if (handlePage) {
                        handlePage('1')
                    }

                    return prev
                })
                setProductStatusFilter({
                    in_review: 'false',
                    is_public: 'false'
                })
                break;
            }
            case 'in_review': {
                setSearchParams((prev) => {
                    prev.set('page', '1')
                    prev.set('in_review', 'true')
                    prev.set('is_public', 'false')

                    if (handlePage) {
                        handlePage('1')
                    }

                    return prev
                })
                setProductStatusFilter({
                    in_review: 'true',
                    is_public: 'false'
                })
                break;
            }
            default: {
                break;
            }
        }
    }

    const handleSearchFilter = (handlePage?: (value: string) => void) => {
        setSearchParams((prev) => {
            prev.set('search', search)
            prev.set('page', '1')

            if (handlePage) {
                handlePage('1')
            }

            return prev
        })
        setSearchFilter(search)
    }

    const handleDefaultFilter = (param: string, value: string | null, handlePage?: (value: string) => void) => {
        if (value) {
            setSearchParams((prev) => {
                prev.set(`${[param]}`, value)
                prev.set('page', '1')

                if (handlePage) {
                    handlePage('1')
                }

                return prev
            })
            setDefaultFilter(value)
        }
    }

    const handlePageSize = (value: string | null, handlePage?: (value: string) => void) => {
        if (value) {
            setSearchParams((prev) => {
                prev.set('page_size', value)
                prev.set('page', '1')

                if (handlePage) {
                    handlePage('1')
                }

                return prev
            })
            setPageSize(value)
        }
    }

    const onKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>, handlePage?: (value: string) => void) => {
        if (e.code === "Enter") {
            handleSearchFilter(handlePage)
        }
    }

    const handleCategoryFilter = (value: string, handlePage?: (value: string) => void) => {
        setSearchParams((prev) => {
            prev.set('category_id', value)
            prev.set('page', '1')

            if (handlePage) {
                handlePage('1')
            }

            return prev
        })
        setCategoryFilter(value)
    }

    const clearFilters = (handlePage?: (value: string) => void) => {
        setSearchFilter('')
        setSearch('')
        setPageSize('10')
        setDefaultFilter('')
        setCategoryFilter('')
        setProductStatusFilter({
            is_public: '',
            in_review: ''
        })
        setSearchParams((prev) => {
            if (handlePage) {
                handlePage('1')
            }

            return { 'page': '1' }
        })
    }

    return {
        search,
        handleSearch,
        handleSearchFilter,
        searchFilter,
        pageSize,
        handlePageSize,
        clearFilters,
        productStatusFilter,
        handleProductsStatusFilter,
        onKeyDownSearch,
        handleDefaultFilter,
        defaultFilter,
        categoryFilter,
        handleCategoryFilter
    }
}

export default useFilters