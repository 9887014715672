
import { FC } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';


import { useSubmitTrackingInformationMutation } from "../../../api/ordersAPI";
import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";

type TTrackingInfoProps = {
    show: boolean,
    onHide: () => void,
    fulfillment: number
}

export type TAddNewTrackingInformation = {
    carrier_code: string,
    tracking_number: string,
    fulfillment: number,
}
const AddTrackingInformation: FC<TTrackingInfoProps> = ({ show, onHide, fulfillment}) => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const { register, handleSubmit, formState: { errors } } = useForm<TAddNewTrackingInformation>({
        defaultValues: {
            fulfillment, 
            carrier_code: '',
            tracking_number: '',
        },
    });
    const [submitTrackingInformation] = useSubmitTrackingInformationMutation()


    const onSubmit = (data: TAddNewTrackingInformation) => {
        submitTrackingInformation(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
                onHide()
                toast.success('Tracking information updated successfully!')
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Add tracking information</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>Tracking Number</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Tracking number"  {...register("tracking_number")} />
                        {(errors.tracking_number || customError === 'Tracking number error.') && (
                            <p className="mb-0 form-field-error">{errors.tracking_number ? errors.tracking_number.message : customError}</p>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='form-input-label'>Carrier code</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Carrier code"  {...register("carrier_code")} />
                        {(errors.carrier_code || customError === 'Carrier code error.') && (
                            <p className="mb-0 form-field-error">{errors.carrier_code ? errors.carrier_code.message : customError}</p>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Add
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddTrackingInformation