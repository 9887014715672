import { createSlice } from '@reduxjs/toolkit'

import { TUser } from './authTypes';
import { RootState } from '../../store'
import { authApi } from '../../../api/authAPI';

type TAuthState = {
    isAuth: boolean,
    isStaff: boolean,
    user: null | TUser,
    accessToken: string | null,
    verifyEmail: boolean,
}

const initialState: TAuthState = {
    isAuth: false,
    isStaff: false,
    user: null,
    accessToken: null,
    verifyEmail: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, { payload }) => {
            state.accessToken = payload.accessToken
        },
        signIn: (state, { payload }) => {
            state.isAuth = true
            state.isStaff = payload.is_staff
            state.user = payload
        },
        signOut: (state) => {
            state.isAuth = false
            state.isStaff = false
            state.user = null
            state.accessToken = null
        },
        updateUser: (state, { payload }) => {
            state.user = payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                state.accessToken = payload.access_token
            }
        )
        builder.addMatcher(
            authApi.endpoints.getUser.matchFulfilled,
            (state, { payload }) => {
                if (payload.is_staff) {
                    state.user = payload
                    state.isAuth = true
                    state.isStaff = payload.is_staff
                }
            }
        )
        builder.addMatcher(
            authApi.endpoints.logout.matchFulfilled,
            (state) => {
                state.accessToken = null
                state.user = null
                state.isAuth = false
                state.isStaff = false
            }
        )
        builder.addMatcher(
            authApi.endpoints.registerUser.matchFulfilled,
            (state) => {
                state.verifyEmail = true
            }
        )
    }
})

export const { setToken, signIn, signOut, updateUser } = authSlice.actions

export const selectIsAuth = (state: RootState) => state.auth.isAuth
export const selectUser = (state: RootState) => state.auth.user
export const selectIsStaff = (state: RootState) => state.auth.isStaff
export const selectAccessToken = (state: RootState) => state.auth.accessToken
export const selectVerifyEmail = (state: RootState) => state.auth.verifyEmail

export default authSlice.reducer