import { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { GoTrash } from 'react-icons/go'
import { BiSearch } from 'react-icons/bi'
import StarRatings from 'react-star-ratings'
import { useNavigate } from 'react-router-dom'
import { SlArrowRight } from 'react-icons/sl'
import ReactGA from 'react-ga'

import { useGetPublicProductsQuery } from '../../../../api/productsAPI'
import { useGetCategoriesQuery } from '../../../../api/categoriesAPI'
import usePagination from '../../../../hooks/usePagination'
import useFilters from '../../../../hooks/useFilters'

import PagePagination from '../../../components/common/Pagination/PagePagination'
import CategoryTree from '../../../components/layout/CategoryTree/CategoryTree'

import './Catalog.css'

const Catalog = () => {
    const { search, handleSearch, handleSearchFilter, searchFilter, onKeyDownSearch, handlePageSize, pageSize, clearFilters, categoryFilter, handleCategoryFilter } = useFilters()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)
    const [openCategories, setOpenCategories] = useState(false)
    const navigate = useNavigate()

    const { data: categories } = useGetCategoriesQuery()
    const { data } = useGetPublicProductsQuery({
        activePage,
        searchFilter,
        pageSize,
        categoryFilter
    })

    const handleViewProduct = (id: number) => {
        navigate(`/catalog/product/${id}`)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (data) {
            handlePagesCount(data.count)
        }
    }, [data, handlePagesCount])

    return (
        <section id="ordersMainPage" className="mt-2 mb-5">
            <Container fluid className="dashboard-body">
                <Row>
                    <Col className='px-4'>
                        <div className="dashboard-header">
                            <Row className="align-items-center">
                                <Col className="col-lg-12">
                                    <div className="left">
                                        <ul className="dashboard-menu-ul ps-0">
                                            <li className="dashboard-menu-li">
                                                <span className="catalog-active active-public public-menu-a">Products</span>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <div>
                                    <Row className="d-flex align-items-center">
                                        <Col className="col-lg-12 d-flex justify-content-between">
                                            <ul className="dashboard-menu-ul d-flex align-items-start ps-0">
                                                <li className='max-height-38' >
                                                    <div className="inputBox dashboard-input-box max-height-38">
                                                        <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                                            <Dropdown.Toggle className="catalog-button-blue max-height-38">
                                                                Results per page: {pageSize}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item eventKey={5}>Results per page: 5</Dropdown.Item>
                                                                <Dropdown.Item eventKey={10}>Results per page: 10</Dropdown.Item>
                                                                <Dropdown.Item eventKey={20}>Results per page: 20</Dropdown.Item>
                                                                <Dropdown.Item eventKey={50}>Results per page: 50</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </li>
                                                <li>
                                                    <button onClick={() => clearFilters(handlePage)} className="btn btn-no-link catalog-button-blue max-height-38">
                                                        <GoTrash className='me-2' /> Clear filters/search
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="search-box d-flex flex-row align-items-center ">
                                                <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                                    <input
                                                        value={search}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                        onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                                        className="catalog-search-input"
                                                        placeholder="Search"
                                                    />
                                                    <button onClick={() => handleSearchFilter(handlePage)} className="catalog-search-button" type='button'>
                                                        <BiSearch size={20} />
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <form className="h-100">
                                    <div className="categories-wrapper-container">
                                        <button onClick={() => setOpenCategories(!openCategories)} className="categories-button-show pl-0" type="button">
                                            <span className='me-2'>Categories</span>
                                            <SlArrowRight size={15} />
                                        </button>
                                        {openCategories && categories && (
                                            <CategoryTree
                                                categories={categories?.results}
                                                handleCategoryFilter={handleCategoryFilter}
                                                handlePage={handlePage}
                                                setOpenCategories={setOpenCategories}
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="row justify-content-center mt-5">
                                {data && data.results.length > 0 && data.results.map((product) => (
                                    <div key={product.id} className="card card-catalog mb-3">
                                        <span onClick={() => handleViewProduct(product.id)} className="quick-view">
                                            View Product
                                        </span>
                                        <img className="category-cart-image" src={product.variants[0]?.images[0]?.url} alt='' />
                                        <div className="category-card-info d-flex justify-content-end flex-column">
                                            <p className="text-truncate text-center">
                                                {product.title}
                                            </p>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-1 mb-1'>
                                                        <StarRatings
                                                            rating={parseFloat(product.rank ? product.rank.rating : '0')}
                                                            numberOfStars={5}
                                                            starRatedColor="yellow"
                                                            starDimension="20px"
                                                            starSpacing="1px"
                                                        />
                                                    </div>
                                                    <span style={{ marginTop: '3px' }} className="number-rating">{product.rank ? product.rank.rating : '0'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {pages.length > 1 && (
                                    <PagePagination
                                        activePage={activePage}
                                        pages={pages}
                                        handlePage={handlePage}
                                    />
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Catalog