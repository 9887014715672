import { FaShippingFast, FaWeightHanging } from "react-icons/fa"
import { BsCurrencyDollar } from "react-icons/bs"
import { RxDimensions } from "react-icons/rx"
import { useForm } from "react-hook-form"
import { Form } from "react-bootstrap"
import { toast } from "react-toastify"
import { useEffect } from "react"

import { useGetFinancialRatesQuery, useUpdateFinancialRatesMutation } from "../../../../api/settingsAPI"
import { TRatesObject } from "../../../../api/api.types"

export type TRatesData = {
    [key: string]: number,
}

const SettingsRates = () => {
    const { data } = useGetFinancialRatesQuery()

    const [updateFinancialRates] = useUpdateFinancialRatesMutation()

    const { register, handleSubmit, setValue } = useForm<TRatesData>();

    const onSubmit = (formData: TRatesData) => {
        const updatedData: TRatesObject[] = [];

        if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
            data[0].shipping_rates_json.forEach((rate) => {
                updatedData.push({
                    ...rate,
                    min_weight: formData[`min_weight_${rate.id}`],
                    max_weight: formData[`max_weight_${rate.id}`],
                    cost: formData[`cost_${rate.id}`] * 100
                })
            })

            updateFinancialRates({ shipping_rates_json: updatedData }).then((res) => {
                if ('error' in res) {
                    toast.error('Something went wrong!')
                } else {
                    toast.success('Shipping rates have been updated!')
                }
            })
        }

    }

    useEffect(() => {
        if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
            data[0].shipping_rates_json.forEach((rate) => {
                setValue(`min_weight_${rate.id}`, rate.min_weight)
                setValue(`max_weight_${rate.id}`, rate.max_weight)
                setValue(`cost_${rate.id}`, rate.cost / 100)
            })
        }
    }, [data, setValue])

    return (
        <div className="row">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <div className="settings-card px-3 mt-4 pb-2">
                        <div className="row dashboard-menu-sub d-flex align-items-center">
                            <div className="col-lg-7">
                                <div className="left">
                                    <ul className="dashboard-menu-ul">
                                        <li className="dashboard-menu-li"><span className="dashboard-menu-a">Shipping Rates</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="right text-right d-flex justify-content-end">
                                    <button type='submit' className="catalog-button-blue dropdown-settings-button">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="settingsUesrManagetable">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className='text-center' scope="col">
                                            <FaShippingFast size={20} /> Shipping Method
                                        </th>
                                        <th className='text-center' scope="col">
                                            <FaWeightHanging size={17} /> Min. Weight
                                        </th>
                                        <th className='text-center' scope="col">
                                            <FaWeightHanging size={17} /> Max. Weight
                                        </th>
                                        <th className='text-center' scope="col">
                                            <BsCurrencyDollar size={20} /> Cost
                                        </th>
                                        <th className='text-center' scope="col">
                                            <RxDimensions size={20} /> Dimension
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="rates-table-body">
                                    {data && data.length > 0 && data[0].shipping_rates_json.length > 0 && data[0].shipping_rates_json.map((rate) => (
                                        <tr key={rate.id}>
                                            <td className='text-center'>{rate.shipping_method}</td>
                                            <td className='text-center'>
                                                <Form.Group>
                                                    <Form.Control className="form-input-field" type="number" placeholder="Email"  {...register(`min_weight_${rate.id}`)} />
                                                </Form.Group>
                                            </td>
                                            <td className='text-center'>
                                                <Form.Group>
                                                    <Form.Control className="form-input-field" type="number" placeholder="Email"  {...register(`max_weight_${rate.id}`)} />
                                                </Form.Group>
                                            </td>
                                            <td className='text-center'>
                                                <Form.Group>
                                                    <Form.Control className="form-input-field" type="number" placeholder="Email"  {...register(`cost_${rate.id}`)} />
                                                </Form.Group>
                                            </td>
                                            <td className='text-center'>{rate.weight_demension}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Form>
        </div >
    )
}

export default SettingsRates