import { FC, useState } from "react"
import { BiLockAlt } from "react-icons/bi"
import { TfiPencilAlt } from "react-icons/tfi"

import { TUser } from "../../../../redux/reducers/auth/authTypes"

import ChangePassword from "../../../modals/ChangePassword/ChangePassword"
import UpdateProfile from "../../../modals/UpdateProfile/UpdateProfile"

type TSettingsAccountBlockProps = {
    user: TUser | null,
}

const SettingsAccountBlock: FC<TSettingsAccountBlockProps> = ({ user }) => {
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [updateProfileModal, setUpdateProfileModal] = useState(false)

    return (
        <div className="col-6">
            <div className="mb-3 card-height settings-card px-3">
                <ul className="dashboard-menu-ul">
                    <li className="dashboard-menu-li">
                        <span className="dashboard-menu-a">Account</span>
                    </li>
                </ul>
                <div className="h-75 d-flex justify-content-between flex-column pb-3">
                    <ul className="dashboard-body-settings ps-0 row">
                        <div className="col-12">
                            <li className="dashboard-body-li d-flex justify-content-start my-3">
                                <span className="w-25 text-muted">
                                    Email:
                                </span>
                                <span className="active-text">
                                    {user?.email}
                                </span>
                            </li>
                            <li className="dashboard-body-li d-flex justify-content-start text-capitalize my-3">
                                <span className="w-25 text-muted">
                                    Name:
                                </span>
                                <span className="active-text">
                                    {user?.first_name} {user?.last_name}
                                </span>
                            </li>
                            <li className="dashboard-body-li d-flex justify-content-start my-3">
                                <span className="w-25 text-muted">
                                    User role:
                                </span>
                                <span className="active-text">
                                    {user?.is_staff ? 'Admin' : 'User'}
                                </span>
                            </li>
                        </div>
                    </ul>
                    <div className="d-flex justify-content-between">
                        <button type='button' onClick={() => setChangePasswordModal(true)} className="dashboard-menu-button margin-top me-3">
                            <BiLockAlt size={20} /> Change Password
                        </button>
                        <button onClick={() => setUpdateProfileModal(true)} className="dashboard-menu-button margin-top" type="button">
                            <TfiPencilAlt size={20} className='pb-1' /> Update Profile
                        </button>
                    </div>
                </div>
            </div>
            {changePasswordModal && <ChangePassword show={changePasswordModal} onHide={() => setChangePasswordModal(false)} />}
            {updateProfileModal && <UpdateProfile show={updateProfileModal} onHide={() => setUpdateProfileModal(false)} />}
        </div>
    )
}

export default SettingsAccountBlock