import { FC, useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Form, Modal } from "react-bootstrap"

import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { updateProfileValidationSchema } from "./validator";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser, updateUser } from "../../../redux/reducers/auth/authSlice";
import { useUpdateProfileMutation } from "../../../api/settingsAPI";

type TUpdateProfileProps = {
    show: boolean,
    onHide: () => void,
}

export type UpdateProfileSubmitData = {
    first_name: string,
    last_name: string,
    email: string,
}

const UpdateProfile: FC<TUpdateProfileProps> = ({ show, onHide }) => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const [updateProfile] = useUpdateProfileMutation()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<UpdateProfileSubmitData>({
        resolver: yupResolver(updateProfileValidationSchema),
    });

    const onSubmit = (data: UpdateProfileSubmitData) => {
        updateProfile(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
                dispatch(updateUser(response.data))
                onHide()
            }
        })
    }

    useEffect(() => {
        if (user) {
            setValue('first_name', user.first_name)
            setValue('last_name', user.last_name)
            setValue('email', user.email)
        }
    }, [user, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Update profile</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>First Name</Form.Label>
                        <Form.Control className="form-input-field" type="string" placeholder="First Name"  {...register("first_name")} />
                        {errors.first_name && <p className="mb-0 form-field-error">{errors.first_name.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>Last Name</Form.Label>
                        <Form.Control className="form-input-field" type="string" placeholder="Last Name" {...register("last_name")} />
                        {errors.last_name && <p className="mb-0 form-field-error">{errors.last_name.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>Email</Form.Label>
                        <Form.Control className="form-input-field" type="string" placeholder="Email" {...register("email")} />
                        {(errors.email || customError === 'A user with that email already exists.') && (
                            <p className="mb-0 form-field-error">{errors.email ? errors.email.message : customError}</p>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Update Profile
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateProfile