import { FC } from "react"
import { Modal, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import { useAddCreditsMutation } from "../../../api/paymentsAPI"

export type TAddCreditsSubmitData = {
    amount: number,
    description: string,
    tenant?: number,
}

type TAddCreditsProps = {
    show: boolean,
    onHide: () => void,
    tenantId: number | null,
}

const AddCredits: FC<TAddCreditsProps> = ({ show, onHide, tenantId }) => {
    const [addCredits] = useAddCreditsMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TAddCreditsSubmitData>();

    const onSubmit = (data: TAddCreditsSubmitData) => {
        if (tenantId) {
            const object = {
                ...data, tenant: tenantId, amount: data.amount * 100
            }
            addCredits(object).then((res) => {
                if ('error' in res) {
                    toast.error('Something went wrong, please try again later.')
                } else {
                    toast.success('The credits has been added successfully!')
                    onHide()
                }
            })
        }
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Add Credits</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>Credits</Form.Label>
                        <Form.Control className="form-input-field" type="number" placeholder="Credits"  {...register("amount", {
                            required: true,
                        })} />
                        {errors.amount && <p className="mb-0 form-field-error">{errors.amount.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>Description</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Description"  {...register("description")} />
                        {errors.description && <p className="mb-0 form-field-error">{errors.description.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Add credits
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCredits