import { Carousel, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { HiOutlineCog6Tooth } from 'react-icons/hi2'
import { BsBookmark } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga'

import { useAddProductNoteMutation, useGetProductVariantQuery } from '../../../../api/productsAPI'
import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic'
import { TProductCategoryParent, TProductNoteSubmitData } from '../../../../api/api.types'

import UpdateProductAmount from '../../../modals/UpdateProductAmount/UpdateProductAmount'
import UpdateProductPrice from '../../../modals/UpdateProductPrice/UpdateProductPrice'
import ModifyAutoUpdate from '../../../modals/ModifyAutoUpdate/ModifyAutoUpdate'

import './ProductDetails.css'

const ProductDetails = () => {
    const { id } = useParams()
    const [isPublic] = useDetectSubdomainOrPublic()
    const [modifyAuto, setModifyAuto] = useState(false)
    const [updateAmount, setUpdateAmount] = useState(false)
    const [updatePrice, setUpdatePrice] = useState(false)
    const [addProductNote] = useAddProductNoteMutation()

    const { data } = useGetProductVariantQuery(Number(id))

    const { register, handleSubmit, reset, formState: { errors } } = useForm<TProductNoteSubmitData>();

    const onSubmit = (data: TProductNoteSubmitData) => {
        addProductNote({
            product: Number(id),
            text: data.text,
        }).then((res) => {
            if ('error' in res) {
                toast.error('Something went wrong')
            } else {
                reset()
            }
        })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <div className='page-wrapper'>
            <div className='dashboard-body'>
                <Row className='d-flex align-items-center'>
                    <Col className='col-lg-7'>
                        <h4 className='mb-0 product-name-title'>
                            {data?.title}
                        </h4>
                    </Col>
                    <Col className='col-lg-5 d-flex justify-content-end'>
                        <Dropdown className='d-flex justify-content-center me-2'>
                            <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                <BsBookmark size={17} className='me-1' /> Category
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {data?.categories && data?.categories.length > 0 && Array.isArray(data?.categories[data.categories.length - 1].parents) ? (
                                    <>
                                        {
                                            (data?.categories[data.categories.length - 1].parents as TProductCategoryParent[]).map((category) => (
                                                <Dropdown.Item key={category.id}>{category.name}</Dropdown.Item>
                                            ))
                                        }
                                    </>
                                )
                                    : data?.categories && data?.categories.length > 0 && (
                                        <Dropdown.Item key={(data?.categories[0].parents as TProductCategoryParent).id}>{(data?.categories[0].parents as TProductCategoryParent).name}</Dropdown.Item>
                                    )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        {!isPublic && (
                            <Dropdown className='d-flex justify-content-center'>
                                <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                    <HiOutlineCog6Tooth size={21} className='me-1' /> Action
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setModifyAuto(true)}>
                                        Modify auto update
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setUpdateAmount(true)}>
                                        Update amount
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setUpdatePrice(true)}>
                                        Update price
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='col-lg-4'>
                        <Carousel className='product-details-carousel'>
                            {data?.images && data?.images.length > 0 && data?.images.map((el) => (
                                <Carousel.Item key={el.id}>
                                    <img
                                        className="d-block w-100"
                                        src={el.url}
                                        alt=""
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <h5>Details</h5>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    {data?.properties.map((property) => (
                                        <th key={property.id} style={{ textTransform: 'capitalize' }} className='text-center' scope="col">
                                            {property.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {data?.properties.map((property) => (
                                        <td key={property.id} className='text-center'>
                                            {property.value}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <h5>Product info</h5>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        PRODUCT NAME
                                    </th>
                                    <th className='text-center' scope="col">
                                        SKU
                                    </th>
                                    <th className='text-center' scope="col">
                                        ASIN
                                    </th>
                                    <th className='text-center' scope="col">
                                        TOTAL
                                    </th>
                                    <th className='text-center' scope="col">
                                        IN-ORDER
                                    </th>
                                    <th className='text-center' scope="col">
                                        AVAILABLE
                                    </th>
                                    <th className='text-center' scope="col">
                                        PRODUCT COST
                                    </th>
                                    <th className='text-center' scope="col">
                                        MSRP
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center max-width-td'>
                                        <span className='wrapped-table-row'>
                                            {data?.title}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        {data?.sku}
                                    </td>
                                    <td className='text-center'>
                                        {data?.amazon_product_variant.asin}
                                    </td>
                                    <td className='text-center'>
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.amount_total}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className='text-center'>
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.amount_reserve}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className='text-center'>
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.amount_available}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className='text-center'>
                                        {data?.prices.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.currency.sign}{el.amount / 100}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className='text-center'>
                                        {data?.prices.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.currency.sign}{el.msrp_amount / 100}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <h5>Notes:</h5>
                        {data?.product_notes_by_tenant && data?.product_notes_by_tenant.length > 0 && (
                            <table className='table order-shipment-detail-table mb-1'>
                                <thead></thead>
                                <tbody>
                                    {data.product_notes_by_tenant.map((note, idx) => (
                                        <tr key={idx}>
                                            <td className='pe-0'>
                                                <div className='note-block'>
                                                    {note.text}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        <Form className='mt-1' onSubmit={handleSubmit(onSubmit)}>
                            <Form.Control
                                className="form-input-field product-details-textarea"
                                type="text"
                                as='textarea'
                                placeholder="Enter a note"
                                {...register("text", {
                                    required: true,
                                })}
                            />
                            {errors.text && <p className="mb-0 form-field-error">{errors.text.message}</p>}
                            <div className='d-flex justify-content-end mt-2'>
                                <button className="dashboard-menu-button">
                                    Add note
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
            {modifyAuto && (<ModifyAutoUpdate show={modifyAuto} onHide={() => setModifyAuto(false)} product={data} />)}
            {updateAmount && (<UpdateProductAmount show={updateAmount} onHide={() => setUpdateAmount(false)} product={data} />)}
            {updatePrice && (<UpdateProductPrice show={updatePrice} onHide={() => setUpdatePrice(false)} product={data} />)}
        </div>
    )
}

export default ProductDetails