import React, { useRef, useEffect } from 'react';
import YouTube from 'react-youtube';

interface YouTubePlayerProps {
  videoId: string;
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ videoId }) => {
    const playerRef = useRef<any>(null);
  const opts = {
    height: '273',
    width: '448',
    playerVars: {
        autoplay: 0,
      // Optional: You can specify player parameters here.
      // For example, to hide the YouTube branding, use modestbranding: 1.
      // For more player options, check the YouTube Player API documentation.
    },
  };

  const onReady = (event: any) => {
    playerRef.current = event.target; // Set the player reference when it is ready
  };

  useEffect(() => {
    return () => {
      // Cleanup function to stop or pause the video when the component unmounts
      if (playerRef.current && playerRef.current.pauseVideo) {
        playerRef.current.pauseVideo(); // Pause the video when the component unmounts
      }
    };
  }, []);


  return <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
};

export default YouTubePlayer;