import * as yup from "yup";

import { passwordRegex, THIS_FIELD_IS_REQUIRED } from "../../../../../../helpers/constants";

export const registerFirstStepValidatonSchema = yup.object().shape({
    first_name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    last_name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    subdomain: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    email: yup
        .string()
        .email("Email address is invalid")
        .required("Email is required"),
    password1: yup
        .string()
        .matches(passwordRegex, 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number')
        .required(THIS_FIELD_IS_REQUIRED),
    password2: yup
        .string()
        .oneOf([yup.ref("password1")], "Passwords do not match"),
});