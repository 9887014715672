import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { setToken, signOut } from '../redux/reducers/auth/authSlice';
import { RootState } from '../redux/store'

export const ACCESS_TOKEN_INVALID_MESSAGE = 'Given token not valid for any token type';
export const TOKEN_NOT_PROVIDED = 'Authentication credentials were not provided.';

const BASE_URL = `${window.location.origin}/api/`

export type TRefreshResponse = {
    data: TRefreshResponseData,
}

export type TRefreshResponseData = {
    access: string,
}

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        }

        return headers;
    },
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions) as any;

    if ((result.error && result.error.status === 403 && result.error.data?.detail === ACCESS_TOKEN_INVALID_MESSAGE) ||
        (result.error && result.error.status === 401 && result.error.data?.detail === TOKEN_NOT_PROVIDED)) {

        const refreshResult = await baseQuery(
            {
                url: 'users/token/refresh/',
                method: 'POST',
            },
            api,
            extraOptions
        ) as TRefreshResponse;
       
        if (refreshResult?.data) {
            // set new access token
            api.dispatch(setToken({ accessToken: refreshResult.data.access }));
            
            // retry query
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(signOut());
        }
    }

    return result;
};
