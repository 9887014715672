import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap/dist/react-bootstrap.min.js';

import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import Home from './Home/Home'
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import FAQ from "./FAQ/FAQ";
import Catalog from "./Catalog/Catalog";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import Terms from "./Terms/Terms";
import VerifyEmail from "./Auth/Register/VerifyEmail/VerifyEmail";
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import SetNewPassword from './Auth/ResetPassword/SetNewPassword/SetNewPassword';
import AcceptInvatedUser from './Auth/AcceptInvatedUser/AcceptInvatedUser';
import AcceptInvitedExistingUser from './Auth/AcceptInvitedExistingUser/AcceptInvitedExistingUser';
import CatalogProduct from './Catalog/CatalogProduct/CatalogProduct';
import AmazonHome from './AmazonHome/AmazonHome';
import ShopifyHome from './ShopifyHome/ShopifyHome';

type TLandingPageProps = {
    isLoading: boolean,
} 

const LendingPage: FC<TLandingPageProps> = ({ isLoading }) => {
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Home isLoading={isLoading} />}></Route>
                <Route path="*" element={<Home isLoading={isLoading} />}></Route>
                <Route path="/catalog" element={<Catalog />} ></Route>
                <Route path="/FAQ" element={<FAQ />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/terms" element={<Terms />}></Route>
                <Route path="/verify_email/:id" element={<VerifyEmail />}></Route>
                <Route path="/reset-password" element={<ResetPassword />}></Route>
                <Route path="/reset_password/:id/:code" element={<SetNewPassword />}></Route>
                <Route path="/invitations/accept_as_new/:code" element={<AcceptInvatedUser />}></Route>
                <Route path="/invitations/accept_as_existing/:code" element={<AcceptInvitedExistingUser />}></Route>
                <Route path="/catalog/product/:id" element={<CatalogProduct />} ></Route>
                <Route path="/integrations/redirect-amazon-login/" element={<AmazonHome />}></Route>
                <Route path="/coherence/integrations/shopify/oauth/redirect/" element={<ShopifyHome />}></Route>
            </Routes>
            <Footer />
        </div>
    );
}

export default LendingPage;