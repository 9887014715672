import { useEffect, useState } from "react"
import { Carousel, Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import StarRatings from "react-star-ratings"
import { IoClose } from "react-icons/io5"
import { FiCheck } from "react-icons/fi"
import ReactGA from 'react-ga'

import { useGetPublicProductByIdQuery } from "../../../../../api/productsAPI"

import './CatalogProduct.css'

const CatalogProduct = () => {
    const [activeVariant, setActiveVariant] = useState(0)
    const { id } = useParams()

    const { data } = useGetPublicProductByIdQuery(id ? Number(id) : 0)

    const handleChangeVariant = (variant: number) => {
        setActiveVariant(variant)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <div className="min-block-height">
            <Row className="p-4 m-0">
                <Col className="catalog-product-wrapper">
                    <div className="d-flex">
                        <div style={{ maxWidth: '352px' }}>
                            <Carousel className='product-details-carousel'>
                                {data?.variants && data?.variants[activeVariant].images.length > 0 ? data?.variants[activeVariant].images.map((el) => (
                                    <Carousel.Item className="d-flex justify-content-center" key={el.id}>
                                        <img
                                            className="d-block"
                                            src={el.url}
                                            alt=""
                                            style={{ height: '352px', width: '352px' }}
                                        />
                                    </Carousel.Item>
                                )) : (
                                    <Carousel.Item>
                                        <div
                                            className="d-block"
                                            style={{ width: '352px', height: '352px' }}
                                        />
                                    </Carousel.Item>
                                )}
                            </Carousel>
                            <div className='product-variants-choose-block'>
                                {data?.variants && data.variants.length > 0 && data.variants.map((variant, idx) => (
                                    <div key={idx} onClick={() => handleChangeVariant(idx)} className={activeVariant === idx ? 'product-variant-block-active' : 'product-variant-block'}>
                                        <img src={variant.images[0]?.url} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="ps-3">
                            <h5>{data?.title}</h5>
                            <div className='d-flex flex-column mt-2'>
                                <span>
                                    Product SKU: {data?.variants[activeVariant].sku}
                                </span>
                                <span>
                                    MSRP: {data?.variants[activeVariant].prices[0].currency.sign}{data?.variants[activeVariant]?.prices[0]?.amount && data?.variants[activeVariant]?.prices[0]?.msrp_amount / 100}
                                </span>
                                <span className='dashboard-catalog-product-description'>
                                    Description: {data?.variants[activeVariant].description}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Row className='mt-3'>
                        <Col>
                            <h6>Customers reviews:</h6>
                            {data?.variants && data.variants[activeVariant].reviews.length > 0 ? data?.variants[activeVariant].reviews.map((review, idx) => (
                                <div className='review-block' key={idx}>
                                    <div className='d-flex justify-content-between'>
                                        <span>
                                            <span className='bold-text'>Title</span>: {review.review_title}
                                        </span>
                                        <span>{review.review_date}</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <span>
                                            <span className='bold-text'>Review variant</span>: {review.review_variant}
                                        </span>
                                        <span>
                                            <span className='bold-text'>Verified</span>:
                                            {review.review_verified ? <FiCheck size={22} color="green" /> : <IoClose size={22} color='red' />}
                                        </span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <span>{review.review_content}</span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>
                                            <span className='bold-text'>Author</span>: {review.review_author}
                                        </span>
                                        <span>
                                            <StarRatings
                                                rating={parseFloat(review.review_rating ? String(review.review_rating) : '0')}
                                                numberOfStars={5}
                                                starRatedColor="yellow"
                                                starDimension="20px"
                                                starSpacing="1px"
                                            />
                                        </span>
                                    </div>
                                </div>
                            )) : (
                                <div className='product-no-reviews-block'>
                                    This product don`t have any reviews yet!
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default CatalogProduct