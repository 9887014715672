import * as yup from 'yup'

import { THIS_FIELD_IS_REQUIRED } from '../../../helpers/constants';

export const addNewUserValidationSchema = yup.object().shape({
    first_name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    last_name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    email: yup
        .string()
        .email('Email address is invalid')
        .required(THIS_FIELD_IS_REQUIRED),
    role: yup
        .string()
        .oneOf(['OWNER', 'STAFF'])
        .required(THIS_FIELD_IS_REQUIRED),
});