import { FC } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { BiLoaderAlt } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { useCheckAffiliateCodeMutation, useUpdateTenantAffiliateCodeMutation } from "../../../api/affiliateAPI";
import { toast } from "react-toastify";

export type TChangeAffiliateCodeSubmitData = {
    affiliate_code: string,
}

type TChangeAffiliateCode = {
    show: boolean,
    onHide: () => void,
}

const ChangeAffiliateCode: FC<TChangeAffiliateCode> = ({ show, onHide }) => {
    let timeoutId: NodeJS.Timeout;
    const [checkAffiliateCode, { data, isLoading: isLoadingCheckCode }] = useCheckAffiliateCodeMutation()
    const [updateAffiliateCode] = useUpdateTenantAffiliateCodeMutation()

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<TChangeAffiliateCodeSubmitData>();

    const handleChangeAffiliateCode = (value: string) => {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            checkAffiliateCode(value)
        }, 500)
    }

    const onSubmit = (data: TChangeAffiliateCodeSubmitData) => {
        updateAffiliateCode(data).then((res) => {
            if ('error' in res) {
                toast.error('Something went wrong')
            } else {
                onHide()
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Affiliate code</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <div className='block-affiliate-code'>
                            <Form.Control className="form-input-field" type="text" placeholder="Affiliate code"  {...register("affiliate_code", {
                                onChange: (e) => handleChangeAffiliateCode(e.target.value),
                            })} />
                            {isLoadingCheckCode && (
                                <BiLoaderAlt className='affiliate-code-check-loading' size={18} />
                            )}
                            {!isLoadingCheckCode && data && (
                                <MdOutlineDone className='affiliate-code-check-done' size={18} />
                            )}
                            {!isLoadingCheckCode && getValues('affiliate_code') && !data && (

                                <OverlayTrigger
                                    
                                    placement="bottom"
                                    delay={{ show: 700, hide: 0 }}
                                    overlay={<Tooltip id="tooltip-error" className = "affiliate-code-tooltip-error">Unable to find affiliate code</Tooltip>}>
                                    <span><IoCloseOutline className='affiliate-code-check-error' size={18} /></span>
                                </OverlayTrigger>
                            )}
                        </div>
                        {errors.affiliate_code && <p className="mb-0 form-field-error">{errors.affiliate_code.message}</p>}
                        {data && (
                            <div className="affiliate-code-info-block mt-1">
                                <span className="me-2">{data.benefits_discount_period + ','}</span>
                                <span>{data.benefits_discount + '% DISCOUNT'}</span>
                            </div>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <button disabled={data ? false : true} type='submit' className="dashboard-menu-button mt-2">
                            Add code
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default ChangeAffiliateCode