import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CiCircleCheck } from 'react-icons/ci'
import ReactGA from 'react-ga'

import { useVerifyEmailMutation } from "../../../../../../api/authAPI";
import { LOGIN_PAGE_URL } from "../../../../../../helpers/constants";

import Loader from "../../../../../components/common/Loader/Loader";

import './VerifyEmail.css'

const VerifyEmail = () => {
    const [verifyEmail, { isLoading, isSuccess }] = useVerifyEmailMutation()
    const { id } = useParams();
    const navigate = useNavigate()

    const redirectToLogin = () => {
        navigate(LOGIN_PAGE_URL)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (id) {
            verifyEmail(id)
        }
    }, [id, verifyEmail])

    if (isLoading) {
        return <Loader text="Confirming email..." />
    } else if (isSuccess) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <CiCircleCheck className="mb-3" fill='rgb(18, 63, 115)' size={80} />
                <span className="email-vefiried-text">Your email successfully verified</span>
                <button className="dark-button" onClick={redirectToLogin}>Go to login page</button>
            </div>
        )
    }

    return <Loader />
}

export default VerifyEmail