import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import ReactGA from 'react-ga'

import { useResetPasswordConfirmMutation } from '../../../../../../api/authAPI'
import { LOGIN_PAGE_URL } from '../../../../../../helpers/constants'
import { newPasswordValidationSchema } from '../validator'

import UserAlert from '../../../../../components/common/UserAlert/UserAlert'
import Loader from '../../../../../components/common/Loader/Loader'

import './SetNewPassword.css'

export type TSetNewPasswordForm = {
    new_password1: string,
    new_password2: string,
}

const SetNewPassword = () => {
    const [setNewPassword, { isLoading, isSuccess }] = useResetPasswordConfirmMutation()
    const navigate = useNavigate()
    const { id, code } = useParams()

    const { register, handleSubmit, formState: { errors } } = useForm<TSetNewPasswordForm>({
        resolver: yupResolver(newPasswordValidationSchema),
    });

    const redirectToLogin = () => {
        navigate(LOGIN_PAGE_URL)
    }

    const onSubmit = (data: TSetNewPasswordForm) => {
        if (id && code) {
            const requestData = {
                ...data,
                uid: id,
                token: code,
            }
            setNewPassword(requestData)
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <Container className='min-block-height d-flex justify-content-center align-items-center'>
            {isSuccess ? (
                <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                    <UserAlert text1={'Your password successfully updated'} />
                    <button className="dark-button" onClick={redirectToLogin}>Go to login page</button>
                </div>
            ) : (
                <Form className='login-wrap' onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="login-title">New Password</h3>
                    <Form.Group className='mt-3'>
                        <Form.Control className="form-input-field" type="password" placeholder="New Password"  {...register("new_password1")} />
                        {errors.new_password1 && <p className="mb-0 form-field-error">{errors.new_password1.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Control className="form-input-field" type="password" placeholder="Confirm Password"  {...register("new_password2")} />
                        {errors.new_password2 && <p className="mb-0 form-field-error">{errors.new_password2.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button className="dark-button" type="submit">Save</button>
                    </div>
                </Form>
            )}
        </Container>
    )
}

export default SetNewPassword