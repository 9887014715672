import { FaLink, FaBuilding } from 'react-icons/fa'
import { BsGear } from 'react-icons/bs'
import { useEffect, useState } from "react"
import {Col, Container, Dropdown, Row} from "react-bootstrap"
import ReactGA from 'react-ga'

import { useGetActiveTenantsQuery, useGetCanceledTenantsQuery, useUpdateTenantStatusMutation } from "../../../../api/settingsAPI"
import usePagination from '../../../../hooks/usePagination'
import { TTenant } from '../../../../api/api.types'

import SettingsUserNav from "../../../components/layout/SettingsUserNav/SettingsUserNav"
import PagePagination from '../../../components/common/Pagination/PagePagination'
import AddCredits from '../../../modals/AddCredits/AddCredits'
import useFilters from "../../../../hooks/useFilters";
import {BiSearch} from "react-icons/bi";

const SettingsTenants = () => {
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', 10)
    const { search, searchFilter, handleSearchFilter, handleSearch, onKeyDownSearch } = useFilters('status')
    const [activeDisabledPage, disabledPages, handleDisabledPage, handleDisabledPagesCount] = usePagination('disabled_page', 10)
    const [addCredits, setAddCredits] = useState(false)
    const [tenantId, setTenantId] = useState<number | null>(null)
    const [updateTenantStatus] = useUpdateTenantStatusMutation()
    const { data: activeTenants } = useGetActiveTenantsQuery({page: activePage, searchFilter: searchFilter})
    const { data: canceledTenants } = useGetCanceledTenantsQuery(activeDisabledPage)

    const goToDashboard = (url: string) => {
        window.location.href = url
    }

    const handleAddCredits = (id: number) => {
        setTenantId(id)
        setAddCredits(true)
    }

    const handleCancelSubscription = (status: boolean, subdomain: string) => {
        updateTenantStatus({ data: { is_active: status }, subdomain })

        if (activeTenants && status === false && activeTenants.results?.length === 1 && activePage !== '1') {
            handlePage(String(Number(activePage) - 1))
        } else if (canceledTenants && status === true && canceledTenants.results?.length === 1 && activeDisabledPage !== '1') {
            handleDisabledPage(String(Number(activeDisabledPage) - 1))
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (canceledTenants) {
            handleDisabledPagesCount(canceledTenants.count)
        }
    }, [canceledTenants, handleDisabledPagesCount])

    useEffect(() => {
        if (activeTenants) {
            handlePagesCount(activeTenants.count)
        }
    }, [activeTenants, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-2'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2 ms-1">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                    className="catalog-search-input"
                                    placeholder="Search"
                                />
                                <button onClick={() => handleSearchFilter(handlePage)} className="catalog-search-button pb-1">
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body pb-4'>
                    <SettingsUserNav />
                    <div>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        Subdomain
                                    </th>
                                    <th className='text-center' scope="col">
                                        Company name
                                    </th>
                                    <th className='text-center' scope="col">
                                        Company url
                                    </th>
                                    <th className='text-center' scope="col">
                                        Country
                                    </th>
                                    <th className='text-center' scope="col">
                                        City
                                    </th>
                                    <th className='text-center' scope="col">
                                        Region
                                    </th>
                                    <th className='text-center' scope="col">
                                        Phone
                                    </th>
                                    <th className='text-center' scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeTenants && activeTenants.results?.map((tenant) => (
                                    <tr key={tenant.id} className='table-row-body'>
                                        <td width='13%' className='text-center'>
                                            {tenant.subdomain}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.name}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.website}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.country}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.city}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.state}
                                        </td>
                                        <td className='text-center'>
                                            {tenant.details.phone}
                                        </td>
                                        <td width='13%' className='text-center'>
                                            <Dropdown className='d-flex justify-content-center'>
                                                <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                                    Action
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => goToDashboard(tenant.full_url)}>Go to Dashboard</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleCancelSubscription(false, tenant.subdomain)}>Cancel Subscription</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleAddCredits(tenant.id)}>Add credit</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {pages.length > 1 && (
                            <PagePagination
                                pages={pages}
                                activePage={activePage}
                                handlePage={handlePage}
                            />
                        )}
                    </div>
                    {canceledTenants && canceledTenants.results.length > 0 && (
                        <div className="mt-4">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className='text-center' scope="col">
                                            <FaLink size={18} className="me-1" />Subdomain
                                        </th>
                                        <th className='text-center' scope="col">
                                            <FaBuilding size={18} className="me-1" />Cancelled tenant name
                                        </th>
                                        <th className='text-center' scope="col">
                                            <BsGear size={20} className="me-1" />Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {canceledTenants && canceledTenants.results?.map((tenant: TTenant) => (
                                        <tr key={tenant.id} className='table-row-body'>
                                            <td width='13%' className='text-center'>
                                                {tenant.subdomain}
                                            </td>
                                            <td className='text-center'>
                                                {tenant.details.name}
                                            </td>
                                            <td width='13%' className='text-center'>
                                                <Dropdown className='d-flex justify-content-center'>
                                                    <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                                        Action
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleCancelSubscription(true, tenant.subdomain)}>Enable</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {disabledPages.length > 1 && (
                                <PagePagination
                                    pages={disabledPages}
                                    activePage={activeDisabledPage}
                                    handlePage={handleDisabledPage}
                                />
                            )}
                        </div>
                    )}
                </div>
                {addCredits && (
                    <AddCredits show={addCredits} onHide={() => setAddCredits(false)} tenantId={tenantId} />
                )}
            </div>
        </section>
    )
}

export default SettingsTenants