import { FC } from "react"
import { Pagination } from "react-bootstrap"

type TPagePaginationProps = {
    pages: string[],
    activePage: string,
    handlePage: (page: string) => void,
} 

const PagePagination: FC<TPagePaginationProps> = ({ pages, activePage, handlePage }) => {
    return (
        <>
            {pages.length < 6 ? (
                <div className='w-100 d-flex align-items-center justify-content-center'>
                    <Pagination className='d-flex align-items-center'>
                        <Pagination.Prev disabled={activePage === '1' ? true : false} onClick={() => handlePage(String(Number(activePage) - 1))} className='d-flex align-items-center me-3'>
                            {'<'} Prev
                        </Pagination.Prev>
                        {pages.map((el, idx) => (
                            <Pagination.Item key={idx} active={activePage === el ? true : false} onClick={() => handlePage(el)} >{el}</Pagination.Item>
                        ))}
                        <Pagination.Next disabled={String(pages.length) === activePage ? true : false} onClick={() => handlePage(String(Number(activePage) + 1))} className='d-flex align-items-center ms-3'>
                            Next {'>'}
                        </Pagination.Next>
                    </Pagination>
                </div>
            ) : (
                <div className='w-100 d-flex align-items-center justify-content-center'>
                    <Pagination className='d-flex align-items-center'>
                        <Pagination.Prev disabled={activePage === '1' ? true : false} onClick={() => handlePage(String(Number(activePage) - 1))} className='d-flex align-items-center me-3'>
                            {'<'} Prev
                        </Pagination.Prev>
                        {activePage === '1' || activePage === '2' ? (
                            <>
                                <Pagination.Item onClick={() => handlePage('1')} active={activePage === '1' ? true : false} >{1}</Pagination.Item>
                                <Pagination.Item onClick={() => handlePage('2')} active={activePage === '2' ? true : false}>{2}</Pagination.Item>
                                <Pagination.Item onClick={() => handlePage('3')}>{3}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item active={activePage === String(pages.length) ? true : false} onClick={() => handlePage(String(pages.length))}>{pages.length}</Pagination.Item>
                            </>
                        ) : (
                            <>
                                {pages.map((el, idx) => {
                                    if (String(idx + 1) === String(pages.length)) {
                                        return null
                                    } else if (Number(activePage) - 2 <= Number(el) && Number(activePage) + 2 >= Number(el)) {
                                        return (
                                            <Pagination.Item key={idx} onClick={() => handlePage(el)} active={activePage === el ? true : false} >{el}</Pagination.Item>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                                <Pagination.Ellipsis />
                                <Pagination.Item active={activePage === String(pages.length) ? true : false} onClick={() => handlePage(String(pages.length))}>{pages.length}</Pagination.Item>
                            </>
                        )}
                        <Pagination.Next disabled={String(pages.length) === activePage ? true : false} onClick={() => handlePage(String(Number(activePage) + 1))} className='d-flex align-items-center ms-3'>
                            Next {'>'}
                        </Pagination.Next>
                    </Pagination>
                </div>
            )}
        </>
    )
}

export default PagePagination