import { ChangeEvent, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import './StripeForm.css'

const StripeForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/dashboard/billing-receipts/`,
            },
        })

        if (error) {
            if (!error.message) return;

            setErrorMessage(error.message);
        } else {
            setErrorMessage("An unexpected error occurred.");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-100" >
            {errorMessage && <div className='text-center mb-2 red-color'>{errorMessage}</div>}
            <div className='payment-element-wrapper'>
                <PaymentElement />
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <button className='dark-button' disabled={!stripe}>Submit</button>
            </div>
        </form>
    )
}

export default StripeForm