import { FC } from 'react'

import loadingGif from '../../../assets/images/loading.gif'

import './Loader.css'

type TLoadingProps = {
    text?: string
}

const Loader: FC<TLoadingProps> = ({ text }) => {
    return (
        <div className='min-block-height loader-wrapper'>
            <img className='loader-gif' src={loadingGif} alt="" />
            { text && ( <span className='loader-text'>{text}</span> )}
        </div>
    )
}

export default Loader