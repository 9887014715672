import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { IoCloseOutline } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import ReactGA from 'react-ga'
import { MdOutlineDone } from 'react-icons/md';
import { BiLoaderAlt } from 'react-icons/bi';

import useHandleCustomRtkQueryError from '../../../../../../hooks/useHandleCustomRtkQueryError';
import { useCheckAffiliateCodeMutation } from '../../../../../../api/affiliateAPI';
import { useRegisterUserMutation } from '../../../../../../api/authAPI';
import { registerFirstStepValidatonSchema } from './validator';

import Loader from '../../../../../components/common/Loader/Loader';

import './RegisterFirstStep.css'

export type TRegisterFirstStepForm = {
    first_name: string,
    last_name: string,
    subdomain: string,
    email: string,
    password1: string,
    password2: string,
    affiliate_code?: string,
}

const RegisterFirstStep = () => {
    let timeoutId: NodeJS.Timeout;
    const [registerUser, { isLoading }] = useRegisterUserMutation()
    const [checkAffiliateCode, { data, isLoading: isLoadingCheckCode }] = useCheckAffiliateCodeMutation()
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const [captcha, setCaptcha] = useState(false)
    const [termsAgree, setTermsAgree] = useState(false)

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<TRegisterFirstStepForm>({
        resolver: yupResolver(registerFirstStepValidatonSchema),
    });

    const handleCaptcha = (value: string | null) => {
        setCaptcha(() => {
            if (value) {
                return true
            } else {
                return false
            }
        })
    }

    const handleExpired = () => {
        setCaptcha(false)
    }

    const handleTermsCheck = (e: ChangeEvent<HTMLInputElement>) => {
        setTermsAgree((prev) => {
            return !prev
        })
    }

    const handleChangeAffiliateCode = (value: string) => {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            checkAffiliateCode(value)
        }, 500)
    }

    const onSubmit = (formData: TRegisterFirstStepForm) => {
        const object: TRegisterFirstStepForm = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            subdomain: formData.subdomain,
            email: formData.email,
            password1: formData.password1,
            password2: formData.password2,
        }

        if (data && formData.affiliate_code) {
            object.affiliate_code = formData.affiliate_code
        }

        registerUser(object).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
            }
        })
    }

    const domainPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Subdomain name</Popover.Header>
            <Popover.Body>
                What is a subdomain name?
                Subdomain name is a website address used to access your account. When you create an account, we create a domain at cloudorder.us for you.
                What is a cloudorder.us domain?
                Your cloudorder.us account name will look like this:your-account.cloudorder.us
                Your cloudorder.us is used:
                so you and your staff can access your storeas a unique way to identify your account when contacting support Your cloudorder.us account name cannot be changed.
            </Popover.Body>
        </Popover>
    )

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="min-block-height d-flex align-items-center justify-content-center">
            <Container>
                <div className="d-flex justify-content-center my-5">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="login-title text-center">Create Account</h3>
                        <div>
                            <Form.Group className='mb-3 mt-4'>
                                {customError && <p className='form-field-error mb-1'>{customError}</p>}
                                <Form.Control className="form-input-field" type="text" placeholder="First Name"  {...register("first_name")} />
                                {errors.first_name && <p className="mb-0 form-field-error">{errors.first_name.message}</p>}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control className="form-input-field" type="text" placeholder="Last Name"  {...register("last_name")} />
                                {errors.last_name && <p className="mb-0 form-field-error">{errors.last_name.message}</p>}
                            </Form.Group>
                            <div className="mb-3">
                                <Form.Group className='register-input-relative-group'>
                                    <Form.Control className="register-input-width-60 form-input-field" type="text" placeholder="Subdomain"  {...register("subdomain")} />
                                    <div className="register-input-group-domain register-input-width-40">
                                        <span className="w-100 h-100 domain-style d-flex align-items-center justify-content-between">.cloudorder.us
                                            <OverlayTrigger trigger="click" placement="right" overlay={domainPopover}>
                                                <svg type="button" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </Form.Group>
                                {errors.subdomain && <p className="mb-0 form-field-error">{errors.subdomain.message}</p>}
                            </div>
                            <Form.Group className='mb-3'>
                                <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                                {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control className="form-input-field" type="password" placeholder="Password"  {...register("password1")} />
                                {errors.password1 && <p className="mb-0 form-field-error" style={{ maxWidth: '400px' }}>{errors.password1.message}</p>}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control className="form-input-field" type="password" placeholder="Confirm password"  {...register("password2")} />
                                {errors.password2 && <p className="mb-0 form-field-error">{errors.password2.message}</p>}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <div className='block-affiliate-code'>
                                    <Form.Control className="form-input-field" type="text" placeholder="Affiliate code"  {...register("affiliate_code", {
                                        onChange: (e) => handleChangeAffiliateCode(e.target.value),
                                    })} />
                                    {isLoadingCheckCode && (
                                        <BiLoaderAlt className='affiliate-code-check-loading' size={18} />
                                    )}
                                    {!isLoadingCheckCode && data && (
                                        <MdOutlineDone className='affiliate-code-check-done' size={18} />
                                    )}
                                    {!isLoadingCheckCode && getValues('affiliate_code') && !data && (
                                        <IoCloseOutline className='affiliate-code-check-error' size={18} />
                                    )}
                                </div>
                                {errors.affiliate_code && <p className="mb-0 form-field-error">{errors.affiliate_code.message}</p>}
                                {data && (
                                    <div className="affiliate-code-info-block mt-1">
                                        <span className="me-2">{data.benefits_discount_period + ','}</span>
                                        <span>{data.benefits_discount + '% DISCOUNT'}</span>
                                    </div>
                                )}
                            </Form.Group>
                            <div className="col-20 my-2">
                                <ReCAPTCHA
                                    sitekey="6LescSEkAAAAAPCbn7XK7VJBhkw2gzWPXIcAE8c0"
                                    onChange={handleCaptcha}
                                    onExpired={handleExpired}
                                />
                                <div className="d-flex align-items-center mt-3">
                                    <input checked={termsAgree} onChange={handleTermsCheck} className='me-2' style={{ width: "20px", height: "20px" }} type="checkbox" />
                                    <label>I agree to cloudorder.us <Link to='/terms'>Terms & Conditions.</Link></label>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-column justify-content-center p-0 mt-3">
                                <button disabled={captcha && termsAgree ? false : true} className="dark-button" type="submit">Continue</button>
                                <div className="mt-2 align-self-center">Already have an account?<Link to='/login'>&nbsp;Log in</Link></div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Container>
        </div>
    )
}

export default RegisterFirstStep
