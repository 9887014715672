import { useEffect } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { AiOutlineInfoCircle, AiOutlineNumber } from "react-icons/ai";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { FaFingerprint, FaRegMoneyBillAlt } from "react-icons/fa";
import { BsCalendarDate, BsSortDown } from "react-icons/bs";
import { GoTrash } from "react-icons/go";
import ReactGA from 'react-ga'

import { useGetTransactionsQuery } from "../../../../../api/paymentsAPI";
import usePagination from "../../../../../hooks/usePagination";
import useFilters from "../../../../../hooks/useFilters";

import PagePagination from "../../../../components/common/Pagination/PagePagination";

const BillingPaypalReceipts = () => {
    const { pageSize, handlePageSize, clearFilters, handleDefaultFilter, defaultFilter } = useFilters('kind')
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)

    const { data: paypalReceiptsData } = useGetTransactionsQuery({
        activePage,
        pageSize,
        defaultFilter
    })

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (paypalReceiptsData) {
            handlePagesCount(paypalReceiptsData.count)
        }
    }, [paypalReceiptsData, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0 ms-1">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Results per page: {pageSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>Results per page: 5</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>Results per page: 10</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>Results per page: 20</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>Results per page: 50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handleDefaultFilter('kind', e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <BsSortDown size={18} className='me-2' /> Filter by kind
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey="payment">Payment</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey="refund">Refund</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> Clear filters
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="billing-title-no-data">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineNumber size={21} />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FaFingerprint size={17} className="me-1" /> Order ID
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <BsCalendarDate size={17} className="me-1" /> Date Paid
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FaRegMoneyBillAlt size={19} className="me-1" /> Amount
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <MdOutlineCurrencyExchange size={17} className="me-1" /> Currency
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineInfoCircle size={19} className="me-1" /> Kind
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paypalReceiptsData && paypalReceiptsData.results.length > 0 && paypalReceiptsData.results.map((receipt) => (
                                    <tr key={receipt.id}>
                                        <td className='text-center'>{receipt.id}</td>
                                        <td className='text-center'>{receipt.order.custom_order_id}</td>
                                        <td className='text-center'>{receipt.created_at.split('T')[0]}</td>
                                        <td className='text-center'>{receipt.amount / 100}</td>
                                        <td className='text-center'>{receipt.currency}</td>
                                        <td className='text-center'>{receipt.kind}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default BillingPaypalReceipts