import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'

import rootReducers from './rootReducer'
import { authApi } from '../api/authAPI'
import { jobsApi } from '../api/jobsAPI'
import { statsApi } from '../api/statsAPI'
import { ordersApi } from '../api/ordersAPI'
import { paymentsApi } from '../api/paymentsAPI'
import { settingsApi } from '../api/settingsAPI'
import { productsApi } from '../api/productsAPI'
import { affiliateApi } from '../api/affiliateAPI'
import { categoriesApi } from '../api/categoriesAPI'
import { integrationsApi } from '../api/integrationsAPI'
import { notificationsApi } from '../api/notificationsAPI'

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
        authApi.middleware,
        ordersApi.middleware,
        settingsApi.middleware,
        productsApi.middleware,
        integrationsApi.middleware,
        notificationsApi.middleware,
        paymentsApi.middleware,
        jobsApi.middleware,
        affiliateApi.middleware,
        categoriesApi.middleware,
        statsApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
