import { FC } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";

import StripeForm from "../../components/common/StripeForm/StripeForm";

import './StripePayment.css'

type StripeOptionsType = {
    clientSecret: string,
    appearance: { theme: "stripe" | "night" | "flat" | "none" }
}

type TStripePaymentProps = {
    show: boolean,
    onHide: () => void,
    clientSecret: string,
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : '');

const StripePayment: FC<TStripePaymentProps> = ({ show, onHide, clientSecret }) => {
    const appearance: { theme: "stripe" | "night" | "flat" | "none" } = {
        theme: 'stripe',
    };

    const options: StripeOptionsType = {
        clientSecret,
        appearance,
    };

    return (
        <Modal className="stripe-payment-modal" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Requires Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center w-100 pt-0">
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <StripeForm />
                    </Elements>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default StripePayment