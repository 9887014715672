import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import ReactGA from 'react-ga'

import { useGetGraphsDataQuery, useGetPieDataQuery, useGetStatsDataQuery } from '../../../../api/statsAPI';
import { getDateByFilterType } from '../../../../helpers/functions';

import './TotalOrders.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    },
};

const TotalOrders = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(searchParams.get('type') ? searchParams.get('type') as string : 'retail')
    const [dateType, setDateType] = useState(searchParams.get('date_type') ? searchParams.get('date_type') as string : 'This Week')
    const [filterDate, setFilterDate] = useState({
        start_date: searchParams.get('start_date') ? searchParams.get('start_date') as string : getDateByFilterType('This Week').startDate,
        end_date: searchParams.get('end_date') ? searchParams.get('end_date') as string : getDateByFilterType('This Week').endDate
    })

    const { data: graphsData } = useGetGraphsDataQuery({ type, filterDate })
    const { data: statsData } = useGetStatsDataQuery({ type, filterDate })
    const { data: pieCategoriesData } = useGetPieDataQuery()

    const labels = useMemo(() => {
        if (graphsData) {
            return graphsData?.retail_orders.map((el) => el.date);
        }
    }, [graphsData])

    const retailOrders = useMemo(() => {
        if (graphsData) {
            return graphsData.retail_orders.map((el) => el.value)
        }
    }, [graphsData])

    const retailProducts = useMemo(() => {
        if (graphsData) {
            return graphsData.retail_products.map((el) => el.value)
        }
    }, [graphsData])

    const retailSales = useMemo(() => {
        if (graphsData) {
            return graphsData.retail_sales.map((el) => String(Number(el.value) / 100))
        }
    }, [graphsData])

    const pieCategoriesArray = useMemo(() => {
        if (pieCategoriesData) {
            return Object.keys(pieCategoriesData).map((key) => pieCategoriesData[key])
        }
    }, [pieCategoriesData])

    const pieData = {
        labels: pieCategoriesData ? Object.keys(pieCategoriesData).map((key) => key) : [],
        datasets: [
            {
                label: 'Products',
                data: pieCategoriesArray,
                backgroundColor: [
                    'rgb(230, 230, 23)',
                    'rgb(29, 117, 175)',
                    'rgb(152, 117, 27)',
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(153, 102, 255)',
                    'rgb(255, 159, 64)',
                    'rgb(203, 127, 51)',
                ],
                borderColor: [
                    'rgb(255, 255, 255)',
                    'rgb(255, 255, 255)',
                    'rgb(255, 255, 255)',
                ],
                borderWidth: 2,
            },
        ],
    };

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                lineTension: 0.5,
                label: 'Retail Orders',
                data: retailOrders,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                fill: true,
                lineTension: 0.5,
                label: 'Retail Products',
                data: retailProducts,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                fill: true,
                lineTension: 0.5,
                label: 'Retail Sales',
                data: retailSales,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const handleSelectType = (value: string | null) => {
        if (value) {
            setType(value)
            setSearchParams((prev) => {
                prev.set('type', value)

                return prev
            })
        }
    }

    const handleDateFilter = (value: string | null) => {
        if (value) {
            const response = getDateByFilterType(value)

            if (response.startDate && response.endDate) {
                setDateType(value)
                setSearchParams((prev) => {
                    prev.set('start_date', response.startDate)
                    prev.set('end_date', response.endDate)
                    prev.set('date_type', value)

                    return prev
                })
                setFilterDate({
                    start_date: response.startDate,
                    end_date: response.endDate
                })
            }
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard w-100">
                <Row className='ps-2 w-100 m-0'>
                    <Col className="w-100 p-0 d-flex justify-content-between align-items-center">
                        <div className="inputBox dashboard-input-box max-height-38">
                            <Dropdown onSelect={handleDateFilter}>
                                <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                    {dateType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='This Week'>This Week</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='This Month'>This Month</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='Last Week'>Last Week</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='Last Two Week'>Last Two Week</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='Last 30 Days'>Last 30 Days</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='Last 12 Month'>Last 12 Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="inputBox dashboard-input-box max-height-38">
                            <Dropdown onSelect={handleSelectType}>
                                <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                    {type === 'retail' ? 'Retail Data' : 'Dropshipping Data'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='retail'>Retail Data</Dropdown.Item>
                                    <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='dropshipping'>Dropshipping Data</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <Row className=''>
                    <Col className='mb-1'>
                        <div className='total-section'>
                            <h3 className='mb-0'>{statsData?.total_orders ? statsData?.total_orders : '0'}</h3>
                            <h6 className='mb-0'>{type === 'retail' ? 'Total Retail Orders' : 'Total Orders'}</h6>
                        </div>
                    </Col>
                    {type === 'retail' && (
                        <Col>
                            <div className='total-section'>
                                <h3 className='mb-0'>{statsData?.total_returned_orders ? statsData?.total_returned_orders : '0'}</h3>
                                <h6 className='mb-0'>Total Returned Order</h6>
                            </div>
                        </Col>
                    )}
                    <Col>
                        <div className='total-section'>
                            <h3 className='mb-0'>{statsData?.total_products ? statsData?.total_products : '0'}</h3>
                            <h6 className='mb-0'>{type === 'retail' ? 'Total Retail Products' : 'Total Products'}</h6>
                        </div>
                    </Col>
                    <Col>
                        <div className='total-section'>
                            <h3 className='mb-0'>${statsData?.total_sales ? Number(statsData?.total_sales) : '0'}</h3>
                            <h6 className='mb-0'>{type === 'retail' ? 'Total Retail Sales' : 'Total Sales'}</h6>
                        </div>
                    </Col>
                    <Col>
                        <div className='total-section'>
                            <h3 className='mb-0'>${statsData?.total_balance ? statsData?.total_balance : '0'}</h3>
                            <h6 className='mb-0'>Total Balance</h6>
                        </div>
                    </Col>
                    <Col>
                        <div className='total-section'>
                            <h3 className='mb-0'>${statsData?.credit ? statsData?.credit : '0'}</h3>
                            <h6 className='mb-0'>Credit</h6>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className='page-wrapper page-wrapper-graphs m-0 pt-0'>
                <Col className='col-lg-8 p-0 pe-2'>
                    <div className='total-charts-block w-100 h-100 d-flex align-items-center pt-3'>
                        <Line options={options} data={data} />
                    </div>
                </Col>
                <Col className='col-lg-4 p-0 ps-3'>
                    <div className='total-charts-block w-100 h-100 d-flex align-items-center'>
                        <Pie data={pieData} />
                    </div>
                </Col>
            </Row>
        </section >
    )
}

export default TotalOrders