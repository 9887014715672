import { useEffect } from 'react';
import ReactGA from 'react-ga'

import emailVerification from '../../../../../assets/images/email_verification.png'

import './RegisterPending.css'

const RegisterPending = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <div className='min-block-height d-flex align-items-center justify-content-center'>
            <div className="text-center register-pending-wrapper">
                <h2 className="mb-2">Registration</h2>
                <div className="login-wrap">
                    <div>
                        <label className='mb-1'>
                            Please check your email inbox. To register, please follow the link in the email you receive.
                        </label>
                        <div className="mt-3">
                            <img className="register-pending-img" src={emailVerification} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterPending