import * as yup from 'yup'

export const updateCompanyDetailsValidationSchema = yup.object().shape({
    name: yup
        .string()
        .notRequired(),
    country: yup
        .string()
        .notRequired(),
    state: yup
        .string()
        .notRequired(),
    city: yup
        .string()
        .notRequired(),
    address_line_1: yup
        .string()
        .notRequired(),
    address_line_2: yup
        .string()
        .notRequired(),
    zip_code: yup
        .string()
        .notRequired(),
    phone: yup
        .string()
        .notRequired(),
    website: yup
        .string()
        .url('Please enter a valid URL.')
        .notRequired(),
});