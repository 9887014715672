import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TEditIntegrationSubmitData } from "../app/modals/EditIntegration/EditIntegration";
import { TCompleteShopifyIntegrationData, TConfirmAmazonIntegration, TConfirmAmazonIntegrationResponse, TGetAmazonCofirmLinkResponse, TGetAmazonLinks, TGetIntegrationsResponse, TGetIntegrationsResults } from "./api.types";

export const integrationsApi = createApi({
    reducerPath: 'integrationsApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Integrations', 'Integration'],
    endpoints: builder => ({
        getAmazonLinks: builder.query<TGetAmazonLinks, void>({
            query: () => {
                return {
                    url: `coherence/integrations/amazon/links/`,
                    method: 'GET',
                }
            }
        }),
        confirmAmazonIntegration: builder.mutation<TConfirmAmazonIntegrationResponse, TConfirmAmazonIntegration>({
            query: (data) => {
                return {
                    url: `coherence/integrations/amazon/oauth/redirect/?spapi_oauth_code=${data.spapi_oauth_code}&state=${data.state}&selling_partner_id=${data.selling_partner_id}`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Integrations']
        }),
        getIntegrations: builder.query<TGetIntegrationsResponse, void>({
            query: () => {
                return {
                    url: `coherence/integrations/`,
                    method: 'GET',
                }
            },
            providesTags: ['Integrations']
        }),
        deleteIntegration: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `coherence/integrations/${id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Integrations']
        }),
        getIntegrationById: builder.query<TGetIntegrationsResults, number>({
            query: (id) => {
                return {
                    url: `coherence/integrations/${id}/`,
                    method: 'GET',
                }
            },
            providesTags: ['Integration']
        }),
        updateIntegration: builder.mutation<void, { data: TEditIntegrationSubmitData, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `coherence/integrations/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['Integrations', 'Integration']
        }),
        importProducts: builder.mutation<void, { integration_id: number }>({
            query: (data) => {
                return {
                    url: `coherence/integrations/amazon/product_update_import/${data.integration_id}/`,
                    method: 'PATCH',
                }
            }
        }),
        getAmazonConfirmLink: builder.mutation<TGetAmazonCofirmLinkResponse, TConfirmAmazonIntegration>({
            query: ({ spapi_oauth_code, state, selling_partner_id }) => {
                return {
                    url: `coherence/integrations/amazon/tenant/link/?spapi_oauth_code=${spapi_oauth_code}&state=${state}&selling_partner_id=${selling_partner_id}`,
                    method: 'GET'
                }
            }
        }),
        getShopifyLinks: builder.mutation<string, { shop: string }>({
            query: (data) => {
                return {
                    url: `coherence/integrations/shopify/links/`,
                    method: 'POST',
                    body: data
                }
            }
        }),
        completeShopifyIntegration: builder.mutation<string, TCompleteShopifyIntegrationData>({
            query: ({ code, hmac, host, shop, state, timestamp }) => {
                return {
                    url: `coherence/integrations/shopify/oauth/redirect/?${code ? `code=${code}` : ''}&hmac=${hmac}&host=${host}&shop=${shop}${state ? `&state=${state}` : ''}&timestamp=${timestamp}`,
                    method: 'GET',
                }
            }
        })
    })
})

export const {
    useGetAmazonLinksQuery,
    useConfirmAmazonIntegrationMutation,
    useGetIntegrationsQuery,
    useDeleteIntegrationMutation,
    useGetIntegrationByIdQuery,
    useUpdateIntegrationMutation,
    useImportProductsMutation,
    useGetAmazonConfirmLinkMutation,
    useGetShopifyLinksMutation,
    useCompleteShopifyIntegrationMutation
} = integrationsApi