import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TCreateAffiliateSubmitData } from "../app/modals/AffiliateCode/AffiliateCode";
import { TCheckAffiliateCodeResponse, TGetAffiliteCodes, TPayCodeSubmitData, TTenantAffiliateData } from "./api.types";
import { TChangeAffiliateCodeSubmitData } from "../app/modals/ChangeAffiliateCode/ChangeAffiliateCode";

export const affiliateApi = createApi({
    reducerPath: 'affiliateApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Affiliates', 'AffiliateCode'],
    endpoints: builder => ({
        getAffiliateCodes: builder.query<TGetAffiliteCodes, string>({
            query: (page) => {
                return {
                    url: `/affiliate_program/affiliate_code/?page=${page}`,
                    method: 'GET',
                }
            },
            providesTags: ['Affiliates']
        }),
        createAffiliateCode: builder.mutation<void, TCreateAffiliateSubmitData>({
            query: (data) => {
                return {
                    url: `/affiliate_program/affiliate_code/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Affiliates']
        }),
        deleteAffiliateCode: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/affiliate_program/affiliate_code/${id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Affiliates']
        }),
        checkAffiliateCode: builder.mutation<TCheckAffiliateCodeResponse, string>({
            query: (code) => {
                return {
                    url: `/affiliate_program/affiliate_code_check/${code}/`,
                    method: 'GET',
                }
            }
        }),
        getTenantAffiliate: builder.query<TTenantAffiliateData, string>({
            query: (tenant) => {
                return {
                    url: `/affiliate_program/tenant_affiliate_code/${tenant}/?expand=program`,
                    method: 'GET',
                }
            },
            providesTags: ['AffiliateCode']
        }),
        updateTenantAffiliateCode: builder.mutation<void, TChangeAffiliateCodeSubmitData>({
            query: (data) => {
                return {
                    url: `/affiliate_program/manipulate_tenant_code/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['AffiliateCode']
        }),
        payAffiliateCode: builder.mutation<void, TPayCodeSubmitData>({
            query: (data) => {
                return {
                    url: `/affiliate_program/affiliate_code_pay/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Affiliates']
        })
    })
})

export const {
    useGetAffiliateCodesQuery,
    useCreateAffiliateCodeMutation,
    useDeleteAffiliateCodeMutation,
    useCheckAffiliateCodeMutation,
    useGetTenantAffiliateQuery,
    useUpdateTenantAffiliateCodeMutation,
    usePayAffiliateCodeMutation
} = affiliateApi