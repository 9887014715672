import { FC } from "react"
import { Form, Modal } from "react-bootstrap"
import { useGetIntegrationsQuery } from '../../../api/integrationsAPI'
import { useForm } from 'react-hook-form'
import { toast } from "react-toastify"

import { useExportProductsMutation } from "../../../api/productsAPI"

export type TSelectIntegrationData = {
    integration_id: number,
    override_existing_status: boolean,
}

type TShowIntegrationProps = {
    show: boolean,
    onHide: () => void,
    selectedProducts: number[],
    setSelectedProducts: (value: number[]) => void,
}

const SelectIntegration: FC<TShowIntegrationProps> = ({ show, onHide, selectedProducts, setSelectedProducts }) => {
    const [exportProducts] = useExportProductsMutation()
    const { data: integrations } = useGetIntegrationsQuery()

    const { register, handleSubmit } = useForm<TSelectIntegrationData>();

    const onSubmit = (data: TSelectIntegrationData) => {
        const object = {
            ids_list: selectedProducts,
            integration_id: data.integration_id,
            override_existing_status: data.override_existing_status,
        }
        exportProducts(object).then((res) => {
            if ("error" in res) {
                toast.error('Something went wrong')
            } else {
                setSelectedProducts([])
                toast.success('Products successfully exported!')
                onHide()
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Select integration</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Select defaultValue='' className="form-input-field mt-2"  {...register("integration_id")}>
                        <option value="">Select integration</option>
                        {integrations && integrations.results.map((integration) => {
                            if (integration.partner === 'Shopify Integration') {
                                return (
                                    <option key={integration.id} value={integration.id}>{integration.name}</option>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Form.Select>
                    <Form.Group className="d-flex align-items-center mt-1">
                        <Form.Check
                            className="me-2"
                            {...register("override_existing_status")}
                        />
                        <Form.Label style={{ marginTop: '5px' }} className='form-input-label'>Override existing status</Form.Label>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <button type='submit' className="dashboard-menu-button mt-3">
                            Export products to store
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default SelectIntegration