import { FC, useEffect } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useModifyProductAutoUpdateMutation } from "../../../api/productsAPI";
import { TProductVariants } from "../../../api/api.types";

export type TModifyAutoUpdateSubmitData = {
    disallow_auto_update_price: boolean | string,
    disallow_auto_update_stock: boolean | string,
}

type TModifyAutoUpdate = {
    show: boolean,
    onHide: () => void,
    product: TProductVariants | undefined,
}

const ModifyAutoUpdate: FC<TModifyAutoUpdate> = ({ show, onHide, product }) => {
    const [modifyAuto] = useModifyProductAutoUpdateMutation()

    const { register, handleSubmit, setValue } = useForm<TModifyAutoUpdateSubmitData>();

    const onSubmit = (data: TModifyAutoUpdateSubmitData) => {
        if (product) {
            const object = {
                data: {
                    disallow_auto_update_price: data.disallow_auto_update_price === 'true' ? true : false,
                    disallow_auto_update_stock: data.disallow_auto_update_stock === 'true' ? true : false,
                },
                id: product?.id,
            }

            modifyAuto(object).then((res) => {
                if ('error' in res) {
                    toast.error('Something went wrong')
                } else {
                    onHide()
                }
            })
        }
    }

    useEffect(() => {
        if (product) {
            setValue('disallow_auto_update_price', product?.disallow_auto_update_price ? 'true' : 'false')
            setValue('disallow_auto_update_stock', product?.disallow_auto_update_stock ? 'true' : 'false')
        }
    }, [product, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Modify Auto Update</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Disallow auto update price</Form.Label>
                        <Form.Select className="form-input-field" {...register("disallow_auto_update_price")}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Disallow auto update stock</Form.Label>
                        <Form.Select className="form-input-field" {...register("disallow_auto_update_stock")}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button">
                            Modify Auto Update
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModifyAutoUpdate