import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useHandleCustomRtkQueryError from '../../../hooks/useHandleCustomRtkQueryError'
import { useCreateAffiliateCodeMutation } from '../../../api/affiliateAPI'
import { addAffiliateValidationSchema } from './validator'
import { TAffiliateCode } from '../../../api/api.types'

export type TCreateAffiliateSubmitData = {
    first_name: string,
    last_name: string,
    email: string,
    community_name: string,
    code: string,
    revenue_per_order: string,
    benefits_discount_period: string,
    benefits_discount: string,
}

type TAffiliateCodeProps = {
    show: boolean,
    onHide: () => void,
    affiliate: null | TAffiliateCode,
}

const AffiliateCode: FC<TAffiliateCodeProps> = ({ show, onHide, affiliate }) => {
    const [createAffiliateCode] = useCreateAffiliateCodeMutation()
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TCreateAffiliateSubmitData>({
        resolver: yupResolver(addAffiliateValidationSchema),
    });

    const onSubmit = (data: TCreateAffiliateSubmitData) => {
        createAffiliateCode(data).then((res) => {
            if ("error" in res) {
                handleCustomError(res.error)
            } else {
                toast.success('The affiliate code has been added successfully')
                setCustomError('')
                onHide()
            }
        })
    }

    useEffect(() => {
        if (customError) {
            toast.error(customError)
        }
    }, [customError])

    useEffect(() => {
        if (affiliate) {
            setValue('first_name', affiliate.first_name)
            setValue('last_name', affiliate.last_name)
            setValue('email', affiliate.email)
            setValue('community_name', affiliate.community_name)
        }
    }, [affiliate, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>Name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="First name"  {...register("first_name")} />
                        {errors.first_name && <p className="mb-0 form-field-error">{errors.first_name.message}</p>}
                        <Form.Control className="form-input-field mt-2" type="text" placeholder="Last name"  {...register("last_name")} />
                        {errors.last_name && <p className="mb-0 form-field-error">{errors.last_name.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>Email</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                        {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>Community name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Community name"  {...register("community_name")} />
                        {errors.community_name && <p className="mb-0 form-field-error">{errors.community_name.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>Code name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Code name"  {...register("code")} />
                        {errors.code && <p className="mb-0 form-field-error">{errors.code.message}</p>}
                    </Form.Group>
                    <Form.Group className='d-flex flex-column'>
                        <Form.Label className='affiliate-header-title mb-1'>Code attributes</Form.Label>
                        <Form.Label className='affiliate-input-label mb-1'>Community owner revenue per order</Form.Label>
                        <Form.Select className="form-input-field" {...register("revenue_per_order")}>
                            <option value="0.05">$0.05</option>
                            <option value="0.10">$0.10</option>
                            <option value="0.15">$0.15</option>
                            <option value="0.20">$0.20</option>
                            <option value="0.25">$0.25</option>
                            <option value="0.30">$0.30</option>
                        </Form.Select>
                        {errors.revenue_per_order && <p className="mb-0 form-field-error">{errors.revenue_per_order.message}</p>}
                        <Form.Label className='affiliate-input-label mt-2 mb-1'>Community member benefits - period</Form.Label>
                        <Form.Select className="form-input-field" {...register("benefits_discount_period")}>
                            <option value="1">1 month</option>
                            <option value="2">2 month</option>
                            <option value="3">3 month</option>
                            <option value="4">4 month</option>
                            <option value="5">5 month</option>
                            <option value="6">6 month</option>
                            <option value="12">12 month</option>
                            <option value="FREE_FOREVER">Free for life</option>
                        </Form.Select>
                        {errors.benefits_discount_period && <p className="mb-0 form-field-error">{errors.benefits_discount_period.message}</p>}
                        <Form.Label className='affiliate-input-label mt-2 mb-1'>Community member benefits - period</Form.Label>
                        <Form.Select className="form-input-field" {...register("benefits_discount")}>
                            <option value="0">No discount</option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                            <option value="20">20%</option>
                            <option value="25">25%</option>
                            <option value="30">30%</option>
                        </Form.Select>
                        {errors.benefits_discount && <p className="mb-0 form-field-error">{errors.benefits_discount.message}</p>}
                    </Form.Group>
                    <button className="affiliate-button mt-4 w-100" type="submit">Save affiliate details</button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AffiliateCode