import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

const SystemNav = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto settings-nav">
                    <NavLink to="/dashboard/system-schedule/">Schedule management</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default SystemNav