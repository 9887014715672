import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Cookies from "universal-cookie"
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import ReactGA from 'react-ga'

import { useAcceptUserAsExistingMutation, useCheckAcceptCodeAsExistingQuery, useGetTenantsListMutation, useLogoutMutation } from "../../../../../api/authAPI"
import { selectAccessToken } from "../../../../../redux/reducers/auth/authSlice"
import { LOGIN_PAGE_URL } from "../../../../../helpers/constants"
import { useAppSelector } from "../../../../../redux/hooks"

import TenantsModal from "../TenantsModal/TenantsModal"
import Loader from "../../../../components/common/Loader/Loader"

const AcceptInvitedExistingUser = () => {
    const [logout] = useLogoutMutation()
    const [getTenants, { data: tenantsList, isSuccess }] = useGetTenantsListMutation()
    const [acceptUser] = useAcceptUserAsExistingMutation()
    const accessToken = useAppSelector(selectAccessToken)
    const cookies = new Cookies()
    const navigate = useNavigate()
    const { code } = useParams()

    const { data } = useCheckAcceptCodeAsExistingQuery(code ? code : '')

    const redirectToLogin = () => {
        navigate(LOGIN_PAGE_URL)
    }

    const handleAcceptUser = () => {
        if (code && accessToken) {
            acceptUser(code).then((response) => {
                if ('error' in response) {
                    toast.error('Something went wrong. Please try again later!')
                } else {
                    cookies.remove('invitationLink', {
                        path: "/",
                        secure: true,
                    })
                    getTenants(accessToken)
                }
            })
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        const cookies = new Cookies()
        const invitationLink = cookies.get('invitationLink')

        if (!invitationLink) {
            logout()
            cookies.set('invitationLink', code, {
                path: "/",
                secure: true,
            })
        } else {
            cookies.remove('invitationLink', {
                path: "/",
                secure: true,
            })
        }
    }, [code, logout, accessToken])

    if (code && !accessToken) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <AiOutlineExclamationCircle fill="rgb(18, 63, 115)" size={50} className="mb-3" />
                <span className="email-vefiried-text">You have been invited to one of the tenants of Cloudorder.us.</span>
                <span className="email-vefiried-text">To accept invitation you need to login.</span>
                <button className="dark-button" onClick={redirectToLogin}>Go to login</button>
            </div>
        )
    }

    if (code && accessToken && data && data.expired === false) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <AiOutlineExclamationCircle fill="rgb(18, 63, 115)" size={50} className="mb-3" />
                <span className="email-vefiried-text">You have been invited to one of the tenants of Cloudorder.us.</span>
                <button className="dark-button" onClick={handleAcceptUser}>Accept invitation</button>
                <TenantsModal show={isSuccess} tenants={tenantsList ? tenantsList.results : null} />
                <ToastContainer
                    position="bottom-right"
                />
            </div>
        )
    }

    if (code && accessToken && data && data.expired === true) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <span className="email-vefiried-text">Your invitation link is out of date</span>
            </div>
        )
    }

    return <Loader />
}

export default AcceptInvitedExistingUser