import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FC } from "react"
import { Modal, Form } from "react-bootstrap"
import { toast } from 'react-toastify';

import { addNewUserValidationSchema } from "./validator";
import { useCreateUserMutation } from "../../../api/settingsAPI";
import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";

type TAddNewUserProps = {
    show: boolean,
    onHide: () => void,
}

export type TAddNewUserSubmitData = {
    first_name: string,
    last_name: string,
    email: string,
    role: string,
}

const AddNewUser: FC<TAddNewUserProps> = ({ show, onHide }) => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const [addNewUser] = useCreateUserMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TAddNewUserSubmitData>({
        resolver: yupResolver(addNewUserValidationSchema),
    });

    const onSubmit = (data: TAddNewUserSubmitData) => {
        addNewUser(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
                onHide()
                toast.success('New user added successfully!')
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Add New User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>First Name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="First Name"  {...register("first_name")} />
                        {errors.first_name && <p className="mb-0 form-field-error">{errors.first_name.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>Last Name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Last Name"  {...register("last_name")} />
                        {errors.last_name && <p className="mb-0 form-field-error">{errors.last_name.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>Email</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                        {(errors.email || customError === 'A user with that email already exists.') && (
                            <p className="mb-0 form-field-error">
                                {errors.email ? errors.email.message : customError}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className='form-input-label'>User Role</Form.Label>
                        <Form.Select defaultValue='STAFF' className="form-input-field" {...register("role")}>
                            <option>Select User Role</option>
                            <option value="OWNER">Owner</option>
                            <option value="STAFF">Staff</option>
                        </Form.Select>
                        {errors.role && <p className="mb-0 form-field-error">{errors.role.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Add
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddNewUser