import * as yup from 'yup'

import { THIS_FIELD_IS_REQUIRED } from '../../../helpers/constants'

const affiliateCodeRegex = new RegExp('^[A-Za-z0-9_]*$')

export const addAffiliateValidationSchema = yup.object().shape({
    first_name: yup.string().required(THIS_FIELD_IS_REQUIRED),
    last_name: yup.string().required(THIS_FIELD_IS_REQUIRED),
    email: yup.string().email('Email address is invalid').required(THIS_FIELD_IS_REQUIRED),
    community_name: yup.string().required(THIS_FIELD_IS_REQUIRED),
    code: yup.string().required(THIS_FIELD_IS_REQUIRED).matches(affiliateCodeRegex, 'Code must contain only letters, number and _ symbol'),
    revenue_per_order: yup.string().required(THIS_FIELD_IS_REQUIRED),
    benefits_discount_period: yup.string().required(THIS_FIELD_IS_REQUIRED),
    benefits_discount: yup.string().required(THIS_FIELD_IS_REQUIRED),
})