import { createApi } from "@reduxjs/toolkit/query/react";

import { TUser } from './../redux/reducers/auth/authTypes';
import { UpdateProfileSubmitData } from "../app/modals/UpdateProfile/UpdateProfile";
import { TSetNewPasswordForm } from "../app/pages/LendingPage/Auth/ResetPassword/SetNewPassword/SetNewPassword";
import { TDetailResponse, TGetTenantDetailResponse } from "../redux/reducers/auth/authTypes";
import { TAddNewUserSubmitData } from '../app/modals/AddNewUser/AddNewUser'
import { TGetTenantsListResponse, TGetTenantUsersResponse, TResponseRatesList, TTenant, TUpdateRatesList, TUpdateTenantUserRequest } from "./api.types";
import { baseQueryWithReAuth } from "./apiConfig";
import { TInviteUserSubmitData } from "../app/modals/InviteUser/InviteUser";

export const settingsApi = createApi({
    reducerPath: 'settingsApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['TenantDetails', 'Tenants', 'TenantUsers', 'Rates'],
    endpoints: builder => ({
        changePassword: builder.mutation<TDetailResponse, TSetNewPasswordForm>({
            query: (data) => {
                return {
                    url: `users/password/change/`,
                    method: 'POST',
                    body: data
                };
            },
        }),
        updateProfile: builder.mutation<TUser, UpdateProfileSubmitData>({
            query: (data) => {
                return {
                    url: `users/user/`,
                    method: 'PUT',
                    body: data,
                }
            }
        }),
        getTenantDetails: builder.query<TGetTenantDetailResponse, string>({
            query: (subdomain) => {
                return {
                    url: `users/tenant_details/${subdomain}/`,
                    method: 'GET',
                }
            },
            providesTags: ['TenantDetails'],
        }),
        updateTenantDetails: builder.mutation<void, { data: TGetTenantDetailResponse, subdomain: string }>({
            query: ({ data, subdomain }) => {
                return {
                    url: `users/tenant_details/${subdomain}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['TenantDetails']
        }),
        getActiveTenants: builder.query<TGetTenantsListResponse, { page: string, searchFilter: string }>({
            query: ({page, searchFilter}) => {
                return {
                    url: `users/tenants/?expand=details&is_active=true${page ? `&page=${page}` : ''}${searchFilter ? `&search=${searchFilter}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Tenants']
        }),
        getCanceledTenants: builder.query<TGetTenantsListResponse, string>({
            query: (page) => {
                return {
                    url: `users/tenants/?expand=details&is_active=false${page ? `&page=${page}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Tenants']
        }),
        updateTenantStatus: builder.mutation<void, { data: { is_active: boolean }, subdomain: string }>({
            query: ({ data, subdomain }) => {
                return {
                    url: `users/tenants/${subdomain}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Tenants']
        }),
        createUser: builder.mutation<void, TAddNewUserSubmitData>({
            query: (data) => {
                return {
                    url: `users/tenant_users/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['TenantUsers']
        }),
        getTenantUsers: builder.query<TGetTenantUsersResponse, string>({
            query: (page) => {
                return {
                    url: `users/tenant_users/${page ? `?page=${page}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['TenantUsers']
        }),
        updateTenantUser: builder.mutation<void, { data: TUpdateTenantUserRequest, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `users/tenant_users/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['TenantUsers']
        }),
        inviteExistingUser: builder.mutation<void, TInviteUserSubmitData>({
            query: (data) => {
                return {
                    url: `users/tenant_users/invite`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['TenantUsers']
        }),
        getTenantData: builder.query<TTenant, string>({
            query: (subdomain) => {
                return {
                    url: `users/tenants/${subdomain}/`,
                    method: 'GET',
                }
            }
        }),
        getFinancialRates: builder.query<TResponseRatesList[], void>({
            query: () => {
                return {
                    url: `financial/rates/`,
                    method: 'GET',
                }
            },
            providesTags: ['Rates']
        }),
        updateFinancialRates: builder.mutation<void, TUpdateRatesList>({
            query: (data) => {
                return {
                    url: `financial/rates/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Rates']
        })
    })
})

export const {
    useChangePasswordMutation,
    useUpdateProfileMutation,
    useGetTenantDetailsQuery,
    useUpdateTenantDetailsMutation,
    useGetActiveTenantsQuery,
    useGetCanceledTenantsQuery,
    useUpdateTenantStatusMutation,
    useCreateUserMutation,
    useGetTenantUsersQuery,
    useUpdateTenantUserMutation,
    useInviteExistingUserMutation,
    useGetTenantDataQuery,
    useGetFinancialRatesQuery,
    useUpdateFinancialRatesMutation
} = settingsApi