import * as yup from 'yup'
import { passwordRegex, THIS_FIELD_IS_REQUIRED } from "../../../helpers/constants";

export const passwordForInvitedUserValidationSchema = yup.object().shape({
    password1: yup
        .string()
        .matches(passwordRegex, 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number')
        .required(THIS_FIELD_IS_REQUIRED),
    password2: yup
        .string()
        .oneOf([yup.ref("password1")], "Passwords do not match"),
});