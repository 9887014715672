import { createApi } from "@reduxjs/toolkit/query/react";

import { TGetProductsResponse, TProduct, TProductNoteSubmitData, TProductVariants } from "./api.types";
import { baseQueryWithReAuth } from "./apiConfig";
import { TModifyAutoUpdateSubmitData } from "../app/modals/ModifyAutoUpdate/ModifyAutoUpdate";
import { TUpdateProductAmountSubmitData } from "../app/modals/UpdateProductAmount/UpdateProductAmount";
import { TUpdateProductPriceSubmitData } from "../app/modals/UpdateProductPrice/UpdateProductPrice";

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Products', 'ProductVariant'],
    endpoints: builder => ({
        getProducts: builder.query<TGetProductsResponse, { activePage: string, search: string, pageSize: string, statusFilters: { in_review: string, is_public: string } }>({
            query: ({ activePage, search, pageSize, statusFilters }) => {
                return {
                    url: `products/?expand=rank,variants,variants.amazon_product_variant,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties&page=${activePage}${search ? `&search=${search}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${statusFilters ? `&in_review=${statusFilters.in_review}&is_public=${statusFilters.is_public}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Products']
        }),
        getPublicProducts: builder.query<TGetProductsResponse, { activePage: string, searchFilter: string, pageSize: string, categoryFilter: string }>({
            query: ({ activePage, searchFilter, pageSize, categoryFilter }) => {
                return {
                    url: `products/public/?expand=rank,variants,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties&page=${activePage}${searchFilter ? `&search=${searchFilter}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${categoryFilter ? `&category_id=${categoryFilter}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Products']
        }),
        getProductVariant: builder.query<TProductVariants, number>({
            query: (id) => {
                return {
                    url: `products/variant/${id}/?expand=amazon_product_variant,prices,inventories,images,reviews,properties,prices.currency,categories`,
                    method: 'GET',
                }
            },
            providesTags: ['ProductVariant']
        }),
        makeProductPublic: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/make_public/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        makeProductPrivate: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/make_private/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        getPublicProductById: builder.query<TProduct, number>({
            query: (id) => {
                return {
                    url: `products/public/${id}/?expand=rank,variants,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties`
                }
            }
        }),
        cancelProductReview: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/cancel_review/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        exportProducts: builder.mutation<{ created: boolean }, { ids_list: number[], integration_id: number, override_existing_status: boolean }>({
            query: (data) => {
                return {
                    url: `coherence/integrations/shopify/export_products/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        addProductNote: builder.mutation<void, TProductNoteSubmitData>({
            query: (data) => {
                return {
                    url: `products/notes/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        modifyProductAutoUpdate: builder.mutation<void, { data: TModifyAutoUpdateSubmitData, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `/products/modify_auto_update/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        updateProductAmount: builder.mutation<void, { id: number, data: TUpdateProductAmountSubmitData }>({
            query: ({ id, data }) => {
                return {
                    url: `products/update_amount/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant', 'Products']
        }),
        updateProductPrice: builder.mutation<void, { id: number, data: TUpdateProductPriceSubmitData }>({
            query: ({ id, data }) => {
                return {
                    url: `products/update_price/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        deleteProducts: builder.mutation<void, { ids_list: number[] }>({
            query: (data) => {
                return {
                    url: `products/remove/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        getCsvFile: builder.mutation<string, void>({
            query: () => {
                return {
                    url: `products/csv/`,
                    method: 'GET',
                    responseHandler: (res) => res.text(),
                }
            }
        }),
        importCsvFile: builder.mutation<{ created: boolean }, FormData>({
            query: (data) => {
                return {
                    url: `products/csv/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Products']
        }),
    })
})

export const {
    useGetProductsQuery,
    useGetPublicProductsQuery,
    useGetProductVariantQuery,
    useMakeProductPublicMutation,
    useMakeProductPrivateMutation,
    useGetPublicProductByIdQuery,
    useCancelProductReviewMutation,
    useExportProductsMutation,
    useAddProductNoteMutation,
    useModifyProductAutoUpdateMutation,
    useUpdateProductAmountMutation,
    useUpdateProductPriceMutation,
    useDeleteProductsMutation,
    useGetCsvFileMutation,
    useImportCsvFileMutation
} = productsApi