import { FC } from "react"
import { Modal } from "react-bootstrap"
import { useGetCsvFileMutation } from "../../../api/productsAPI"
import { toast } from "react-toastify"

type TExportCsvProps = {
    show: boolean,
    onHide: () => void,
}

const ExportCsv: FC<TExportCsvProps> = ({ show, onHide }) => {
    const [exportCsvFile] = useGetCsvFileMutation()

    const handleExportCsvFile = async () => {
        exportCsvFile().then((res) => {
            if ("error" in res) {
                toast.error('Something went wrong')
            } else {
                downloadCsv(res.data, 'products.csv');
                onHide()
            }
        })
    }

    const downloadCsv = (data: string, filename: string) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();

        URL.revokeObjectURL(url);
    };

    return (
        <Modal size="lg" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Export Products</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="d-flex flex-column">
                    <span>When saving a file, please use a comma delimiter and Unicode UTF-8 encoding.</span>
                    <span className="mt-1">For Mac and Windows, save as type: Comma Separated Values(CSV)</span>
                    <div className="d-flex justify-content-end mt-2">
                        <button className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38" onClick={handleExportCsvFile}>Export</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ExportCsv