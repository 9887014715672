import { FC, useState } from 'react';
import { FaFingerprint, FaUserTag, FaHandsHelping, FaCogs, FaCheck } from 'react-icons/fa'
import { RxEnvelopeClosed } from 'react-icons/rx';
import { AiOutlinePlus } from 'react-icons/ai';
import { Dropdown } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr'
import { FiUsers } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { useDeleteIntegrationMutation, useGetIntegrationsQuery, useImportProductsMutation } from '../../../../api/integrationsAPI';

import AddShopifyIntegration from '../../../modals/AddShopifyIntegration/AddShopifyIntegration';
import AddAmazonIntegration from '../../../modals/AddAmazonIntegration/AddAmazonIntegration';

import amazonFba from '../../../assets/images/amazonfba.jpg'
import shopify from '../../../assets/images/shopify.jpg'

type TSettingsIntegrationsBlock = {
    isPublic: boolean,
    handleEditIntegration: (id: number) => void,
}

const SettingsIntegrationsBlock: FC<TSettingsIntegrationsBlock> = ({ isPublic, handleEditIntegration }) => {
    const [addShopify, setAddShopify] = useState(false)
    const [addAmazon, setAddAmazon] = useState(false)
    const [importProducts] = useImportProductsMutation()
    const [deleteIntegration] = useDeleteIntegrationMutation()

    const { data: integrations } = useGetIntegrationsQuery(undefined, { skip: isPublic })

    const handleDeleteIntegration = (id: number) => {
        deleteIntegration(id)
    }

    const handleImportProducts = (id: number) => {
        importProducts({ integration_id: id }).then((res) => {
            if ('error' in res) {
                toast.error('Product import failed!')
            } else {
                toast.success('Product import started!')
            }
        })
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="settings-card px-3 mt-4 pb-2">
                    <div className="row dashboard-menu-sub d-flex align-items-center">
                        <div className="col-lg-7">
                            <div className="left">
                                <ul className="dashboard-menu-ul">
                                    <li className="dashboard-menu-li"><span className="dashboard-menu-a">Integrations</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right text-right">
                                <Dropdown className='d-flex justify-content-end dropdown-without-triangle'>
                                    <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                        Add new integration <AiOutlinePlus size={18} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setAddAmazon(true)} className='d-flex align-items-center'>
                                            <img className='add-integration-image' src={amazonFba} alt="" />
                                            <h5 className='mb-0'>Amazon FBA</h5>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => setAddShopify(true)} className='d-flex align-items-center'>
                                            <img className='add-integration-image' src={shopify} alt="" />
                                            <h5 className='mb-0'>Shopify</h5>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="settingsUesrManagetable">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <FaFingerprint size={20} /> Integration name
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaUserTag size={20} /> Seller
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaUserTag size={20} /> Vendor
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FiUsers size={20} /> Fullfilment Service
                                    </th>
                                    <th className='text-center' scope="col">
                                        <RxEnvelopeClosed size={20} /> Status
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaHandsHelping size={20} /> Partner
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaCogs size={20} /> Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {integrations && integrations.results.length > 0 && integrations.results.map((integration) => (
                                    <tr key={integration.id}>
                                        <td className='text-center'>{integration.name}</td>
                                        <td className='text-center'>
                                            {integration.is_seller ? (
                                                <FaCheck size={18} />
                                            ) : (
                                                <GrClose size={18} />
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {integration.is_vendor ? (
                                                <FaCheck size={18} />
                                            ) : (
                                                <GrClose size={18} />
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {integration.is_fulfillment_service ? (
                                                <FaCheck size={18} />
                                            ) : (
                                                <GrClose size={18} />
                                            )}
                                        </td>
                                        <td className='text-center'>{integration.status}</td>
                                        <td className='text-center'>{integration.partner}</td>
                                        <td className='text-center'>
                                            <Dropdown className='d-flex justify-content-center'>
                                                <Dropdown.Toggle style={{ width: '110px' }} className="catalog-button-blue dropdown-settings-button">
                                                    Select
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleEditIntegration(integration.id)}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    {integration.partner === 'Amazon Integration' && (
                                                        <Dropdown.Item onClick={() => handleImportProducts(integration.id)}>
                                                            Import products
                                                        </Dropdown.Item>
                                                    )}
                                                    <Dropdown.Item onClick={() => handleDeleteIntegration(integration.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {addShopify && <AddShopifyIntegration show={addShopify} onHide={() => setAddShopify(false)} />}
            {addAmazon && <AddAmazonIntegration show={addAmazon} onHide={() => setAddAmazon(false)} />}
        </div>
    )
}

export default SettingsIntegrationsBlock