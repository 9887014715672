import { FC, useEffect } from "react"
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form'
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { useUpdateProductPriceMutation } from "../../../api/productsAPI";
import { TProductVariants } from "../../../api/api.types";

export type TUpdateProductPriceSubmitData = {
    amount: string,
}

type TUpdateProductPriceProps = {
    show: boolean,
    onHide: () => void,
    product: TProductVariants | undefined,
}

const UpdateProductPrice: FC<TUpdateProductPriceProps> = ({ show, onHide, product }) => {
    const [updateProductPrice] = useUpdateProductPriceMutation()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TUpdateProductPriceSubmitData>();

    const onSubmit = (data: TUpdateProductPriceSubmitData) => {
        if (product) {
            updateProductPrice({
                id: product?.inventories[0].id,
                data: { amount: (Number(data.amount) * 100).toFixed(2) }
            }).then((res) => {
                if ('error' in res) {
                    toast.error('Something went wrong')
                } else {
                    onHide()
                }
            })
        }
    }

    useEffect(() => {
        if (product) {
            setValue('amount', String(product.prices[0].amount / 100))
        }
    }, [product, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Update Product Price</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Total price</Form.Label>
                        <Form.Control className="form-input-field" type="text" {...register("amount", { required: true })} />
                        {errors.amount && <p className="mb-0 form-field-error">{errors.amount.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button">
                            Update Product Price
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateProductPrice