import { FC, useEffect } from 'react';
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TWiseErrorDataValidation, TWiseRequirementData, TWiseRequirementResponse } from '../../../../api/api.types';
import { useCreateWiseReceiptMutation } from '../../../../api/paymentsAPI';
import { THIS_FIELD_IS_REQUIRED } from '../../../../helpers/constants';

export type TAddBankAccountFormData = {
    [key: string]: string,
}

type TAddPaymentFormProps = {
    el: TWiseRequirementData,
    getExtraWiseRequirements: (data: { [key: string]: string | { [key: string]: string } }) => Promise<TWiseRequirementResponse>,
    setRequirements: (data: TWiseRequirementData[]) => void,
    selectedTab: string,
    onHide: () => void,
}

const AddPaymentForm: FC<TAddPaymentFormProps> = ({ el, getExtraWiseRequirements, setRequirements, selectedTab, onHide }) => {
    const [createWiseReceipt] = useCreateWiseReceiptMutation()

    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm<TAddBankAccountFormData>({ reValidateMode: "onChange" });

    const handleChangeValue = async () => {
        const values = getValues()
        const requestData: { [key: string]: string | { [key: string]: string } } = {}

        el.fields.forEach((field) => {
            if (field.group[0].refreshRequirementsOnChange) {
                const splittedKey = `${field.group[0].key}`.split('.')
                const firstKey = splittedKey[0]

                if (splittedKey.length === 1) {
                    requestData[firstKey] = values[firstKey]
                } else if (splittedKey.length === 2) {
                    requestData[firstKey] = requestData[firstKey] || {}
                    const reqObj = requestData[firstKey];
                    const valObj = values[firstKey];
                    if (typeof reqObj === 'object' && typeof valObj === 'object') {
                        reqObj[splittedKey[1]] = valObj[splittedKey[1]]
                    }
                }
            }
        })

        const response = await getExtraWiseRequirements(requestData)
        setRequirements(response.data)
    }

    const onSubmit = (data: TAddBankAccountFormData) => {
        const object = { data: { ...data, type: el.type, currency: 'USD', details: data } }

        createWiseReceipt(object).then((res) => {
            if ('error' in res) {
                const err = JSON.stringify(res.error)

                let errors = JSON.parse(err) as TWiseErrorDataValidation

                errors.data.forEach((error) => {
                    toast.error(error.message, {
                        autoClose: false,
                    })
                })
            } else {
                onHide()
                toast.success('The bank account has been added successfully')
            }
        })
    }

    useEffect(() => {
        reset()
    }, [selectedTab, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="mt-2 d-flex flex-column payment-method-form">
            <div className='payment-method-requirement-block mb-3'>
                <h5 className='payment-method-requirement-title'>{el.title}</h5>
                <span className='payment-method-requirement-type'>{el.type}</span>
                {el.fields.length > 0 && el.fields.map((field, idx) => {
                    if (field.group[0].type === 'text') {
                        return (
                            <Form.Group className='mb-1' key={idx}>
                                <Form.Label className='form-input-label'>{field.name} { field.group[0].example ? `(Ex: ${field.group[0].example})` : ''}</Form.Label>
                                <Form.Control className="form-input-field" type="text" placeholder={field.name}
                                    {...register(field.group[0].key, {
                                        onChange: () => field.group[0].refreshRequirementsOnChange ? handleChangeValue() : null,
                                        required: { value: field.group[0].required, message: THIS_FIELD_IS_REQUIRED },
                                        pattern: { value: field.group[0].validationRegexp ? new RegExp(field.group[0].validationRegexp) : new RegExp(''), message: 'Please type value in correct format' },
                                        minLength: { value: field.group[0].minLength ? field.group[0].minLength : 1, message: `Minimum length is ${field.group[0].minLength} characters` },
                                        maxLength: { value: field.group[0].maxLength ? field.group[0].maxLength : 100000, message: `Maximum length is ${field.group[0].maxLength} characters` }
                                    })
                                    }
                                />
                                {errors[`${field.group[0].key}`] && <p className="mb-0 form-field-error">{errors[`${field.group[0].key}`]?.message}</p>}
                            </Form.Group>
                        )
                    } else if (field.group[0].type === 'select' || field.group[0].type === 'radio') {
                        return (
                            <Form.Group className='mb-1' key={idx}>
                                <Form.Label className='form-input-label'>{field.name}</Form.Label>
                                <Form.Select className="form-input-field"
                                    {...register(field.group[0].key, {
                                        onChange: () => field.group[0].refreshRequirementsOnChange ? handleChangeValue() : null,
                                        required: { value: field.group[0].required, message: THIS_FIELD_IS_REQUIRED }
                                    }
                                    )}
                                >
                                    {field.group[0].valuesAllowed.length > 0 && field.group[0].valuesAllowed.map((value, idx) => (
                                        <option key={idx} value={value.key}>{value.name}</option>
                                    ))}
                                </Form.Select>
                                {errors[`${field.group[0].key}`] && <p className="mb-0 form-field-error">{errors[`${field.group[0].key}`]?.message}</p>}
                            </Form.Group>
                        )
                    } else {
                        return null
                    }
                })}
                <div className='d-flex justify-content-end mt-2'>
                    <button className="dark-button py-2" type="submit">Submit</button>
                </div>
            </div>
        </Form>
    )
}

export default AddPaymentForm