import { useNavigate } from 'react-router-dom'
import { Col, Container, Dropdown, Form, Nav, Row } from 'react-bootstrap'
import { FaInfoCircle, FaFingerprint, FaCogs, FaStoreAlt, FaCalendarAlt, FaDollarSign, FaCheck, FaExchangeAlt, FaWindowClose } from 'react-icons/fa'
import { GoTrash } from 'react-icons/go'
import { useEffect, useState } from 'react'
import { MdLocalShipping, MdPayments } from 'react-icons/md'
import { BiSearch } from 'react-icons/bi'
import ReactGA from 'react-ga'

import { useCancelOrderMutation, useFulfillOrderMutation, useGetOrdersQuery } from '../../../../api/ordersAPI'
import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic'
import usePagination from '../../../../hooks/usePagination'
import { TGetOrderResult } from '../../../../api/api.types'
import useFilters from '../../../../hooks/useFilters'

import PagePagination from '../../../components/common/Pagination/PagePagination'
import PayPalPayment from '../../../modals/PayPalPayment/PayPalPayment'

import './Orders.css'

const Orders = () => {
    const { pageSize, handlePageSize, clearFilters, defaultFilter, handleDefaultFilter, search, handleSearch, handleSearchFilter, onKeyDownSearch, searchFilter } = useFilters('fulfillments__fulfillment_line_items__status')
    const [isPublic] = useDetectSubdomainOrPublic()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)
    const [orderPay, setOrderPay] = useState<TGetOrderResult | null>(null)
    const [paypalPayment, setPaypalPayment] = useState(false)
    const [cancelOrder] = useCancelOrderMutation()
    const [fulfillOrder] = useFulfillOrderMutation()
    const navigate = useNavigate()

    const isCancellable = (status: String) => {
        if (status === "New" || status === "In Progress" || status === "Return In Progress") {
            return true
        }
        else {
            return false
        }
    }

    const isShippable = (status: String) => {
        if (status === "New") {
            return true
        }
        else {
            return false
        }
    }

    const { data: ordersData, refetch } = useGetOrdersQuery({
        activePage,
        pageSize,
        defaultFilter,
        searchFilter
    })

    const handleCloseManuallPayWithRefetch = () => {
        setPaypalPayment(false)
        setOrderPay(null)
        refetch()
    }

    const handleClosePayManually = () => {
        setPaypalPayment(false)
        setOrderPay(null)
    }

    const handlePayManually = (order: TGetOrderResult) => {
        setOrderPay(order)
        setPaypalPayment(true)
    }

    const handleFulfillOrder = (id: number) => {
        fulfillOrder(id)
    }

    const handleCancelOrder = (id: number) => {
        cancelOrder(id)
    }

    const navigateToOrderDetails = (id: number) => {
        navigate(`/dashboard/order-details/${id}`)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (ordersData) {
            handlePagesCount(ordersData.count)
        }
    }, [ordersData, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-2'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                    className="catalog-search-input"
                                    placeholder="Search"
                                />
                                <button onClick={() => handleSearchFilter(handlePage)} className="catalog-search-button pb-1">
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Results per page: {pageSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>Results per page: 5</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>Results per page: 10</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>Results per page: 20</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>Results per page: 50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> Clear filters
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="d-flex align-items-center justify-content-between mt-1">
                        <Col className='col-lg-12'>
                            <Nav className='orders-nav-links' activeKey={defaultFilter ? defaultFilter : 'All'} onSelect={(selectedKey) => handleDefaultFilter('fulfillments__fulfillment_line_items__status', selectedKey, handlePage)}>
                                <Nav.Item>
                                    <Nav.Link eventKey="NEW">New</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="IN_PROGRESS">In progress</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="PARTIALLY_COMPLETED">Partially completed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="COMPLETED">Completed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="RETURN_IN_PROGRESS">Return in progress</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="RETURNED">Returned</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="CANCELED">Canceled</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ERROR">Orders with errors</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="All">All</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <Form.Check />
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaFingerprint size={18} /> Order ID
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaStoreAlt size={20} /> Order store
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaCalendarAlt size={20} /> Creation date
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaDollarSign size={19} /> Retail price
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaCheck size={18} /> Order is paid
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaExchangeAlt size={20} /> Status
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaCogs size={20} /> Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersData && ordersData.results.length > 0 && ordersData.results.map((order) => (
                                    <tr key={order.id}>
                                        <td className='text-center'>
                                            <Form.Check />
                                        </td>
                                        <td className='text-center'>
                                            {order.custom_order_id}
                                        </td>
                                        <td className='text-center'>{order?.seller_integration?.name}</td>
                                        <td className='text-center'>{order.imported_date.split('T')[0]}</td>
                                        <td className='text-center'>${order.end_customer_cost / 100}</td>
                                        <td className='text-center'>
                                            <h6 className='d-flex align-items-center justify-content-center'>
                                                <div className='dashboard-status-color bg-success me-1'></div>
                                                {order.is_paid ? 'paid' : 'not paid'}
                                            </h6>
                                        </td>
                                        <td className='text-center'>{order.status}</td>
                                        <td className='text-center'>
                                            <Dropdown className='d-flex justify-content-center'>
                                                <Dropdown.Toggle style={{ width: '110px' }} className="catalog-button-blue dropdown-settings-button">
                                                    Select
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigateToOrderDetails(order.id)} className='d-flex align-items-center dropdown-item-hover-green'>
                                                        <FaInfoCircle className='me-1' size={20} /> Details
                                                    </Dropdown.Item>
                                                    {
                                                        !isPublic ? (
                                                            <>
                                                                {!order.is_paid ? (
                                                                    <Dropdown.Item onClick={() => handlePayManually(order)} className='d-flex align-items-center dropdown-item-hover-green'>
                                                                        <MdPayments className='me-1' size={20} /> Pay manually
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                                {isShippable(order.status) ? (
                                                                    <Dropdown.Item onClick={() => handleFulfillOrder(order.id)} className='dropdown-item-hover-green'>
                                                                        <MdLocalShipping className='me-1' size={20} />Ship
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                                {isCancellable(order.status) ? (
                                                                    <Dropdown.Item onClick={() => handleCancelOrder(order.id)} className='dropdown-item-hover-green'>
                                                                        <FaWindowClose className='me-1' size={20} />Cancel
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                            </>
                                                        ) : null
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
            {paypalPayment && orderPay && (
                <PayPalPayment
                    show={paypalPayment}
                    onHide={handleClosePayManually}
                    orderPay={orderPay}
                    handleCloseManuallPayWithRefetch={handleCloseManuallPayWithRefetch}
                />
            )}
        </section>
    )
}

export default Orders