import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ReactGA from 'react-ga'

import { useCheckAcceptAsNewCodeQuery } from "../../../../../api/authAPI"

import Loader from "../../../../components/common/Loader/Loader"
import SetInvitedUserPassword from "../../../../modals/SetInvitedUserPassword/SetInvitedUserPassword"

const AcceptInvatedUser = () => {
    const [invitedUserPasswordModal, setInvitedUserPasswordModal] = useState(false)
    const { code } = useParams()
    console.log(code)
    const { data, isLoading, isSuccess } = useCheckAcceptAsNewCodeQuery(code ? code : '')

    const acceptInivation = () => {
        setInvitedUserPasswordModal(true)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    if (isLoading) {
        return <Loader text="Verifying link..." />
    } 
    
    if (isSuccess && data.expired === false) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <span className="email-vefiried-text">You have been invited to one of the tenants of Cloudorder.us</span>
                <button className="dark-button" onClick={acceptInivation}>Accept Invitation</button>
                <SetInvitedUserPassword show={invitedUserPasswordModal} onHide={() => setInvitedUserPasswordModal(false)} code={code ? code : ''} />
            </div>
        )
    } 
    
    if (isSuccess && data.expired === true) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <span className="email-vefiried-text">Your link is out of date</span>
            </div>
        )
    }

    return <Loader />
}

export default AcceptInvatedUser