import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form'
import { Country, State, City, ICountry } from "country-state-city";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { TGetTenantDetailResponse } from "../../../redux/reducers/auth/authTypes";
import { updateCompanyDetailsValidationSchema } from "./validator";
import { useUpdateTenantDetailsMutation } from "../../../api/settingsAPI";

import 'react-phone-number-input/style.css'

type TEditCompanyDetails = {
    show: boolean,
    onHide: () => void,
    tenantDetails: TGetTenantDetailResponse | undefined,
    subdomain: string,
}

const EditCompanyDetails: FC<TEditCompanyDetails> = ({ show, onHide, tenantDetails, subdomain }) => {
    const [customError, setCustomError] = useHandleCustomRtkQueryError()
    const [phone, setPhone] = useState<string>('')
    const [updateTenantDetails] = useUpdateTenantDetailsMutation()
    const [countries, setCountries] = useState<ICountry[]>([])
    const [selectedState, setSelectedState] = useState<null | string>(null)
    const [selectedCountry, setSelectedCountry] = useState<null | string>(null)
    const [invalidNumber, setInvalidNumber] = useState(false)

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TGetTenantDetailResponse>({
        resolver: yupResolver(updateCompanyDetailsValidationSchema),
    });

    const onSubmit = (data: TGetTenantDetailResponse) => {
        if (!invalidNumber) {
            const newData = { ...data, phone: phone ? phone : null }
            updateTenantDetails({ data: newData, subdomain }).then((response) => {
                if ('error' in response) {
                    setCustomError('Something went wrong. Please try later!')
                } else {
                    onHide()
                    setCustomError(null)
                }
            })
        }
    }

    const changeValue = (value: string) => {
        setPhone(value)
        setInvalidNumber(false)
    }

    const checkIfNumberValid = () => {
        if (isValidPhoneNumber(phone)) {
            setInvalidNumber(false)
        } else {
            setInvalidNumber(true)
        }
    }

    const handleChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountry(e.target.value);
    }

    const handleChangeState = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(e.target.value)
    }

    const states = useMemo(() => {
        if (selectedCountry) {
            const country = countries.find((el) => el.name === selectedCountry)
            return State?.getStatesOfCountry(country?.isoCode)
        }
    }, [selectedCountry, countries])

    const cities = useMemo(() => {
        if (selectedState && selectedCountry) {
            const country = countries.find((el) => el.name === selectedCountry)
            const state = states?.find((el) => el.name === selectedState)

            return City.getCitiesOfState(country?.isoCode ? country?.isoCode : '', state?.isoCode ? state?.isoCode : '')
        }
    }, [selectedState, selectedCountry, countries, states])

    useEffect(() => {
        const countries = Country.getAllCountries()
        setCountries(countries)
    }, [])

    useEffect(() => {
        if (tenantDetails && countries) {
            setValue('name', tenantDetails.name)
            setValue('address_line_1', tenantDetails.address_line_1)
            setValue('country', tenantDetails.country)
            setSelectedCountry(tenantDetails.country)
            setValue('address_line_2', tenantDetails.address_line_2)
            setValue('state', tenantDetails.state)
            setSelectedState(tenantDetails.state)
            setPhone(tenantDetails.phone ? tenantDetails.phone : '')
            setValue('city', tenantDetails.city)
            setValue('zip_code', tenantDetails.zip_code)
            setValue('website', tenantDetails.website)
        }
    }, [tenantDetails, countries, setValue])

    return (
        <Modal size="xl" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Edit Company Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {customError && <p className="form-field-error">{customError}</p>}
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Name</Form.Label>
                            <Form.Control className="form-input-field" type="string" placeholder="Name"  {...register("name")} />
                            {errors.name && <p className="mb-0 form-field-error">{errors.name.message}</p>}
                        </Form.Group>
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Address Line 1</Form.Label>
                            <Form.Control className="form-input-field" type="string" placeholder="Address Line 1" {...register("address_line_1")} />
                            {errors.address_line_1 && <p className="mb-0 form-field-error">{errors.address_line_1.message}</p>}
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Country</Form.Label>
                            <div className="form-input-field">
                                <input className="form-control form-input-field" list="countryList" placeholder="Select Country" {...register("country", { onChange: (e) => handleChangeCountry(e) })} />
                                <datalist id="countryList">
                                    <option>Select Country</option>
                                    {countries && countries.map((country) => (
                                        <option key={country.name} value={country.name}> {country.name} </option>
                                    ))}
                                </datalist>
                            </div>
                            {errors.country && <p className="mb-0 form-field-error">{errors.country.message}</p>}
                        </Form.Group>
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Address Line 2</Form.Label>
                            <Form.Control className="form-input-field" type="string" placeholder="Address Line 2" {...register("address_line_2")} />
                            {errors.address_line_2 && <p className="mb-0 form-field-error">{errors.address_line_2.message}</p>}
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>State/Region</Form.Label>
                            <div className="form-input-field">
                                <input className="form-control form-input-field" list="stateList" placeholder="Select State/Region" {...register("state", { onChange: (e) => handleChangeState(e) })} />
                                <datalist id="stateList">
                                    <option>Select State/Region</option>
                                    {states && states.map((state) => (
                                        <option key={state.name} value={state.name}> {state.name} </option>
                                    ))}
                                </datalist>
                            </div>
                            {errors.state && <p className="mb-0 form-field-error">{errors.state.message}</p>}
                        </Form.Group>
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Contact Phone</Form.Label>
                            <div className="form-input-field px-3 py-1">
                                <Form.Control className="form-input-field d-none" type="string" placeholder="Zip Code" {...register("phone")} />
                                <PhoneInput
                                    placeholder="Phone number"
                                    value={phone}
                                    defaultCountry="US"
                                    onChange={changeValue}
                                    onBlur={checkIfNumberValid}
                                />
                            </div>
                            {invalidNumber && <p className="mb-0 form-field-error">Phone number is invalid</p>}
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>City</Form.Label>
                            <div className="form-input-field">
                                <input className="form-control form-input-field" list="cityList" placeholder="Select City" {...register("city")} />
                                <datalist id="cityList">
                                    <option>Select City</option>
                                    {cities && cities.map((city) => (
                                        <option key={city.name} value={city.name}> {city.name} </option>
                                    ))}
                                </datalist>
                            </div>
                            {errors.city && <p className="mb-0 form-field-error">{errors.city.message}</p>}
                        </Form.Group>
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Zip Code</Form.Label>
                            <Form.Control className="form-input-field" type="string" placeholder="Zip Code" {...register("zip_code")} />
                            {errors.zip_code && <p className="mb-0 form-field-error">{errors.zip_code.message}</p>}
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <Form.Group className="w-49">
                            <Form.Label className='form-input-label'>Company url</Form.Label>
                            <Form.Control className="form-input-field" type="string" placeholder="Company url"  {...register("website")} />
                            {errors.website && <p className="mb-0 form-field-error">{errors.website.message}</p>}
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            Update Company Details
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default EditCompanyDetails