import * as yup from "yup";

import { passwordRegex, THIS_FIELD_IS_REQUIRED } from "../../../../../helpers/constants";

export const resetPasswordValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Email address is invalid")
        .required(THIS_FIELD_IS_REQUIRED),
});

export const newPasswordValidationSchema = yup.object().shape({
    new_password1: yup
        .string()
        .matches(passwordRegex, 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number')
        .required(THIS_FIELD_IS_REQUIRED),
    new_password2: yup
        .string()
        .oneOf([yup.ref("new_password1")], "Passwords do not match"),
});