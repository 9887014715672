import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { FaFileSignature, FaCheckDouble } from 'react-icons/fa'
import { BsSortDown, BsCardList } from 'react-icons/bs'
import { GoTrash } from 'react-icons/go'
import { FiClock, FiSettings } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import ReactGA from 'react-ga'

import { useDeleteJobMutation, useGetJobsQuery, useRunJobManuallyMutation, useUpdateJobMutation } from '../../../../../api/jobsAPI'
import usePagination from '../../../../../hooks/usePagination'
import useFilters from '../../../../../hooks/useFilters'
import { TJob } from '../../../../../api/api.types'

import PagePagination from '../../../../components/common/Pagination/PagePagination'
import SystemNav from '../../../../components/layout/SystemNav/SystemNav'
import EditTaskArgs from '../../../../modals/EditTaskArgs/EditTaskArgs'
import CreateTask from '../../../../modals/CreateTask/CreateTask'

import '../System.css'

const SystemSchedule = () => {
    const { search, handleSearch, handleSearchFilter, searchFilter, onKeyDownSearch, handlePageSize, pageSize, clearFilters, handleDefaultFilter, defaultFilter } = useFilters('status')
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)
    const [editArgs, setEditArgs] = useState(false)
    const [createTask, setCreateTask] = useState(false)
    const [job, setJob] = useState<null | TJob>(null)
    const [deleteJob] = useDeleteJobMutation()
    const [runJob] = useRunJobManuallyMutation()
    const [updateJob] = useUpdateJobMutation()

    const navigate = useNavigate()

    const { data: jobsData } = useGetJobsQuery({
        activePage,
        searchFilter,
        pageSize,
        defaultFilter
    })

    const handleNavigateToDetails = (id: string) => {
        navigate(`/dashboard/system-schedule/${id}`)
    }

    const handleChangePeriod = (e: ChangeEvent<HTMLSelectElement>, id: string) => {
        updateJob({
            data: {
                periodic_schedule: e.target.value,
            }, id
        })
    }

    const handleRunOnceJob = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            updateJob({
                data: {
                    is_one_off: true,
                }, id
            })
        } else {
            updateJob({
                data: {
                    is_one_off: false,
                }, id
            })
        }
    }

    const handleDisableJob = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            updateJob({
                data: {
                    disabled: true,
                }, id
            })
        } else {
            updateJob({
                data: {
                    disabled: false,
                }, id
            })
        }
    }

    const handleOpenEditModal = (job: TJob) => {
        setJob(job)
        setEditArgs(true)
    }

    const handleRunManually = (id: string) => {
        runJob(id)
    }

    const handleDeleteJob = (id: string) => {
        deleteJob(id)
        if (jobsData && jobsData.results?.length === 1 && activePage !== '1') {
            handlePage(String(Number(activePage) - 1))
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (jobsData) {
            handlePagesCount(jobsData.count)
        }
    }, [jobsData, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-2'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2 ms-1">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                    className="catalog-search-input"
                                    placeholder="Search"
                                />
                                <button onClick={() => handleSearchFilter(handlePage)} className="catalog-search-button pb-1">
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Results per page: {pageSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>Results per page: 5</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>Results per page: 10</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>Results per page: 20</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>Results per page: 50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handleDefaultFilter('status', e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <BsSortDown size={18} className='me-2' /> Filter by status
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey="SUCCESS">Success</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey="PENDING">Pending</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey="FAILED">Failed</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> Clear filters/search
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="d-flex align-items-center">
                        <Col className='col-lg-9'>
                            <SystemNav />
                        </Col>
                        <Col className='col-lg-3 d-flex justify-content-end p-0'>
                            <button onClick={() => setCreateTask(true)} className="catalog-button-blue dashboard-catalog-button max-height-38">
                                Create Task
                            </button>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <FaFileSignature className='me-1' size={18} /> Name
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <FaCheckDouble className='me-1' size={18} /> Disabled
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <FiClock className='me-1' size={20} /> Interval
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <BsCardList className='me-1' size={20} /> Last run
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <BsCardList className='me-1' size={18} /> Status
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <BsCardList className='me-1' size={20} /> Run once
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <FiSettings className='me-1' size={20} /> Action
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobsData && jobsData.results.length > 0 && jobsData.results.map((job) => (
                                    <tr key={job.id}>
                                        <td className='text-center max-width-td'>
                                            <span className='wrapped-table-row'>
                                                {job.function_name}
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            <Form.Check
                                                onChange={(e) => handleDisableJob(e, job.id)}
                                                checked={job.disabled}
                                            />
                                        </td>
                                        <td className='text-center'>
                                            <Form.Select onChange={(e) => handleChangePeriod(e, job.id)} defaultValue={job.periodic_schedule} className="form-input-field">
                                                <option value="* * * * *">Every 1 minute</option>
                                                <option value="0 * * * *">Every 1 hour</option>
                                                <option value="0 */6 * * *">Every 6 hour</option>
                                                <option value="0 1 * * *">Every 1 day</option>
                                                <option value="0 0 */3 * *">Every 3 day</option>
                                                <option value="0 0 * * 0">Every week</option>
                                                <option value="0 0 1 * *">Every month</option>
                                                <option value="0 0 1 1 *">Every year</option>
                                            </Form.Select>
                                        </td>
                                        <td className='text-center'>
                                            {job.last_run_at?.split('T')[0]}
                                        </td>
                                        <td className='text-center'>
                                            {job.status}
                                        </td>
                                        <td className='text-center'>
                                            <Form.Check
                                                onChange={(e) => handleRunOnceJob(e, job.id)}
                                                checked={job.is_one_off}
                                            />
                                        </td>
                                        <td className='text-center'>
                                            <Dropdown className='d-flex justify-content-center'>
                                                <Dropdown.Toggle style={{ width: '110px' }} className="catalog-button-blue dropdown-settings-button">
                                                    Select
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleNavigateToDetails(job.id)} className='dropdown-item-hover-green'>
                                                        Details
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleOpenEditModal(job)} className='dropdown-item-hover-green'>
                                                        Args...
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleRunManually(job.id)} className='dropdown-item-hover-green'>
                                                        Run manually
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleDeleteJob(job.id)} className='dropdown-item-hover-green'>
                                                        Remove
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
            {createTask && (<CreateTask show={createTask} onHide={() => setCreateTask(false)} />)}
            {editArgs && job && (<EditTaskArgs job={job} show={editArgs} onHide={() => setEditArgs(false)} />)}
        </section>
    )
}

export default SystemSchedule