import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga'

import { useGetTenantDetailsQuery } from '../../../../api/settingsAPI'
import { useConfirmAmazonIntegrationMutation } from '../../../../api/integrationsAPI';
import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic'
import { selectUser } from '../../../../redux/reducers/auth/authSlice'

import SettingsUsersFinancialsBlock from '../../../components/layout/SettingsUsersFinancialsBlock/SettingsUsersFinancialsBlock';
import SettingsIntegrationsBlock from '../../../components/layout/SettingsIntegrationsBlock/SettingsIntegrationsBlock';
import SettingsAffiliateBlock from '../../../components/layout/SettingsAffiliateBlock/SettingsAffiliateBlock';
import SettingsCompanyBlock from '../../../components/layout/SettingsCompanyBlock/SettingsCompanyBlock';
import SettingsAccountBlock from '../../../components/layout/SettingsAccountBlock/SettingsAccountBlock';
import SettingsUserNav from '../../../components/layout/SettingsUserNav/SettingsUserNav'
import EditIntegration from '../../../modals/EditIntegration/EditIntegration';
import Loader from '../../../components/common/Loader/Loader';

import './SettingsUsers.css'
import SettingsRates from '../../../components/layout/SettingsRates/SettingsRates';

const SettingsUsers = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isPublic, subdomain] = useDetectSubdomainOrPublic()
    const [editId, setEditId] = useState<null | number>(null)
    const [editIntegration, setEditIntegration] = useState(false)
    const user = useAppSelector(selectUser)

    const [confrimAmazonIntegration, { isLoading }] = useConfirmAmazonIntegrationMutation()
    const { data: tenantDetails } = useGetTenantDetailsQuery(subdomain, { skip: isPublic || !subdomain })

    const handleHideEditIntegration = () => {
        setEditId(null)
        setEditIntegration(false)
    }

    const handleEditIntegration = (id: number) => {
        setEditId(id)
        setEditIntegration(true)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        const spapi_oauth_code = searchParams.get('spapi_oauth_code')
        const state = searchParams.get('state')
        const selling_partner_id = searchParams.get('selling_partner_id')

        if (spapi_oauth_code && state && selling_partner_id) {
            const object = {
                spapi_oauth_code,
                state,
                selling_partner_id,
            }
            confrimAmazonIntegration(object).then((res) => {
                if ('error' in res) {
                    toast.error('Something went wrong. Please try again later.')
                    navigate('/dashboard/settings-users/')
                } else {
                    toast.success('Amazon integration added!')
                    navigate('/dashboard/settings-users/')
                    setEditId(res.data.id)
                    setEditIntegration(true)
                }
            })
        }
    }, [searchParams, confrimAmazonIntegration, navigate])

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className='page-wrapper'>
            <div className='dashboard-body pb-4'>
                <SettingsUserNav />
                <div className="row mt-3">
                    <SettingsAccountBlock
                        user={user}
                    />
                    {!isPublic && (
                        <SettingsCompanyBlock
                            tenantDetails={tenantDetails}
                            subdomain={subdomain}
                        />
                    )}
                </div>
                {!isPublic && (
                    <>
                        <SettingsUsersFinancialsBlock isPublic={isPublic} />
                        <SettingsIntegrationsBlock
                            isPublic={isPublic}
                            handleEditIntegration={handleEditIntegration}
                        />
                        <SettingsAffiliateBlock />
                        <SettingsRates />
                    </>
                )}
                {editIntegration && editId && (
                    <EditIntegration
                        show={editIntegration}
                        onHide={() => handleHideEditIntegration()}
                        id={editId}
                    />
                )}
            </div>
        </div>
    )
}

export default SettingsUsers