import { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import ReactGA from 'react-ga'

import { TTenantDetail } from '../../../../../redux/reducers/auth/authTypes'

import './TenantsModal.css'

type TListTenantsModalProps = {
    show: boolean,
    tenants: null | TTenantDetail[],
}

const TenantsModal: FC<TListTenantsModalProps> = ({ show, tenants }) => {
    
    const handleVisitTenant = (url: string) => {
        window.location.href = url
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <Modal centered show={show} >
            <Modal.Header>
                <Modal.Title>Tenants list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { tenants && tenants.map((tenant) => (
                    <div className='tenant-block' key={tenant.id}>
                        <span className='tenant-domain'>{tenant.subdomain}</span>
                        <button onClick={() => handleVisitTenant(tenant.full_url)} className='dark-button'>Visit</button>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
}

export default TenantsModal