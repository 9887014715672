import { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap"
import { useForm } from "react-hook-form";

import { useAcceptUserAsNewMutation } from "../../../api/authAPI";
import { LOGIN_PAGE_URL } from "../../../helpers/constants";
import { passwordForInvitedUserValidationSchema } from "./validator";

type TSetInvitedUserPasswordProps = {
    show: boolean,
    onHide: () => void,
    code: string,
}

export type TSetInvitedUserPasswordSubmitData = {
    password1: string,
    password2: string,
}

const SetInvitedUserPassword: FC<TSetInvitedUserPasswordProps> = ({ show, onHide, code }) => {
    const [acceptUserAsNew, { isSuccess }] = useAcceptUserAsNewMutation()
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm<TSetInvitedUserPasswordSubmitData>({
        resolver: yupResolver(passwordForInvitedUserValidationSchema),
    });

    const redirectToLogin = () => {
        navigate(LOGIN_PAGE_URL)
    }

    const onSubmit = (data: TSetInvitedUserPasswordSubmitData) => {
        acceptUserAsNew({ data, key: code })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Create password</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                {isSuccess ? (
                    <div className="d-flex flex-column align-items-center justify-content-center py-5">
                        <h5 className="p-0 m-0" style={{ color: '#4a4b65' }}>Invitation accepted successfully</h5>
                        <button className="dark-button mt-2" onClick={redirectToLogin}>Go to login page</button>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label className='form-input-label'>New Password</Form.Label>
                            <Form.Control className="form-input-field" type="password" placeholder="Password"  {...register("password1")} />
                            {errors.password1 && <p className="mb-0 form-field-error">{errors.password1.message}</p>}
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label className='form-input-label'>Confirm New Password</Form.Label>
                            <Form.Control className="form-input-field" type="password" placeholder="Confirm Password" {...register("password2")} />
                            {errors.password2 && <p className="mb-0 form-field-error">{errors.password2.message}</p>}
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-3">
                            <button type='submit' className="dashboard-menu-button margin-top">
                                Save
                            </button>
                        </div>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default SetInvitedUserPassword