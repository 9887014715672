import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TGetCategoriesResponse } from "./api.types";

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: builder => ({
        getCategories: builder.query<TGetCategoriesResponse, void>({
            query: () => {
                return {
                    url: `products/category/`,
                    method: 'GET',
                }
            }
        })
    })
})

export const {
    useGetCategoriesQuery,
} = categoriesApi