import { FC } from "react";
import { Modal } from "react-bootstrap";

import { TGetOrderResult } from "../../../api/api.types";

import PayPalButton from "../../components/common/PayPalButton/PayPalButton";

type TStripePaymentProps = {
    show: boolean,
    onHide: () => void,
    orderPay: TGetOrderResult,
    handleCloseManuallPayWithRefetch: () => void,
}


const PayPalPayment: FC<TStripePaymentProps> = ({ show, onHide, orderPay, handleCloseManuallPayWithRefetch }) => {
    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>PayPal Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center w-100">
                <PayPalButton orderPay={orderPay} handleCloseManuallPayWithRefetch={handleCloseManuallPayWithRefetch} />
            </Modal.Body>
        </Modal>
    )
}

export default PayPalPayment