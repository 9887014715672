import StarRatings from 'react-star-ratings';
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { FaFingerprint, FaWarehouse, FaBarcode, FaDollarSign, FaCheck } from 'react-icons/fa'
import { BiSearch } from 'react-icons/bi'
import { GoTrash } from 'react-icons/go'
import { BsSortNumericUpAlt, BsStar } from 'react-icons/bs'
import { TbFileExport } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { FiClock } from 'react-icons/fi'
import { MdArrowForwardIos } from 'react-icons/md'
import { TfiReload } from 'react-icons/tfi';
import { GrClose } from 'react-icons/gr';
import { toast } from 'react-toastify';
import usePagination from '../../../../hooks/usePagination'
import ReactGA from 'react-ga'

import { useCancelProductReviewMutation, useDeleteProductsMutation, useGetProductsQuery, useMakeProductPrivateMutation, useMakeProductPublicMutation } from '../../../../api/productsAPI'
import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic';
import { detectProductStatus } from '../../../../helpers/functions';
import useFilters from '../../../../hooks/useFilters';
import { TProduct } from '../../../../api/api.types'

import PagePagination from '../../../components/common/Pagination/PagePagination'
import SelectIntegration from '../../../modals/SelectIntegration/SelectIntegration';
import ExportCsv from '../../../modals/ExportCsv/ExportCsv';
import ImportCsv from '../../../modals/ImportCsv/ImportCsv';

import './MyProducts.css'

type ProductDataUpdated = TProduct & { expand: boolean }

const MyProducts = () => {
    const { search, handleSearch, handleSearchFilter, pageSize, handlePageSize, clearFilters, searchFilter, productStatusFilter, handleProductsStatusFilter, onKeyDownSearch } = useFilters()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)
    const [isPublic] = useDetectSubdomainOrPublic()
    const [makeProductPublic] = useMakeProductPublicMutation()
    const [makeProductPrivate] = useMakeProductPrivateMutation()
    const [cancelProductReview] = useCancelProductReviewMutation()
    const [deleteProducts] = useDeleteProductsMutation()
    const [productData, setProductData] = useState<ProductDataUpdated[]>([])
    const [selectedProducts, setSelectedProducts] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false)
    const [selectIntegration, setSelectIntegration] = useState(false)
    const [exportCsv, setExportCsv] = useState(false)
    const [importCsv, setImportCsv] = useState(false)
    const { data } = useGetProductsQuery({ activePage, search: searchFilter, pageSize, statusFilters: productStatusFilter })

    const handleOpenModal = () => {
        setSelectIntegration(true)
    }

    const handleSearchInputClick = () => {
        handleSearchFilter(handlePage)
    }

    const handlePageSizeDropdown = (e: string | null) => {
        handlePageSize(e, handlePage)
    }

    const handleMakePublic = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter)
            makeProductPublic({ ids: selectedProducts, select_all: selectedAll, product_status })
            setSelectedProducts([])
            setSelectAll(false)
            setSelectedAll(false)
        }
    }

    const handleCancelReview = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter)
            cancelProductReview({ ids: selectedProducts, select_all: selectedAll, product_status })
            setSelectedProducts([])
            setSelectAll(false)
            setSelectedAll(false)
        }
    }

    const handleMakePrivate = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter)
            makeProductPrivate({ ids: selectedProducts, select_all: selectedAll, product_status })
            setSelectedProducts([])
            setSelectAll(false)
            setSelectedAll(false)
        }
    }

    const handleSelectPageAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const listProducts = productData.map((el) => el.id)
            setSelectedProducts(listProducts)
            setSelectAll(true)
        } else {
            setSelectedProducts([])
            setSelectAll(false)
        }
    }

    const handleSelectProduct = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        if (e.target.checked) {
            setSelectedProducts((prev) => {
                return [...prev, id]
            })
        } else {
            setSelectedProducts((prev) => {
                return prev.filter((el) => el !== id)
            })
        }
    }

    const handleExpandData = (id: number) => {
        const updatedData = productData.map((el) => el.id === id ? { ...el, expand: !el.expand } : el)
        setProductData(updatedData)
    }

    const handleRemoveProducts = () => {
        deleteProducts({ ids_list: selectedProducts }).then((res) => {
            if ('error' in res) {
                toast.error('Something went wrong, please try again later.')
            } else {
                toast.success('The products has been deleted successfully!')
                setSelectedProducts([])
            }
        })
    }

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedAll(true)
        } else {
            setSelectedAll(false)
        }
    }

    const handleOpenCsvModal = () => {
        setExportCsv(true)
    }

    const handleOpenImportCsvModal = () => {
        setImportCsv(true)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (data) {
            handlePagesCount(data?.count)
            const updatedData = data.results.map((el) => {
                return { ...el, expand: false }
            })
            setProductData(updatedData)
        }
    }, [data, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2 ms-1">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                    className="catalog-search-input"
                                    placeholder="Search"
                                />
                                <button onClick={handleSearchInputClick} className="catalog-search-button pb-1">
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={handlePageSizeDropdown}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Results per page: {pageSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>Results per page: 5</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>Results per page: 10</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>Results per page: 20</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>Results per page: 50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handleProductsStatusFilter(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Filter by status
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='public'>Public</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='private'>Private</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey='in_review'>In review</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> Clear filters/search
                                </button>
                            </li>
                            {!isPublic && (
                                <li>
                                    <button onClick={handleOpenCsvModal} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                        <TbFileExport size={18} className='me-2' /> Export
                                    </button>
                                </li>
                            )}
                            {!isPublic && (
                                <li>
                                    <button onClick={handleOpenImportCsvModal} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                        <TbFileExport size={18} className='me-2' /> Import
                                    </button>
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="align-items-center mt-1">
                        <Col className="col-lg-6">
                            <div className="left">
                                <ul className="dashboard-menu-ul ps-0">
                                    <li className="dashboard-menu-li">
                                        <span className="catalog-active active-public public-menu-a">All</span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col className="col-lg-6 d-flex justify-content-end">
                            <div className='d-flex align-items-center'>
                                Select All:
                                <Form.Check
                                    className='ms-2 me-3'
                                    checked={selectedAll}
                                    onChange={(e) => handleSelectAll(e)}
                                />
                            </div>
                            <Dropdown className='d-flex justify-content-center'>
                                <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                    Action
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleMakePublic} className='dropdown-item-hover-green'>
                                        Make public
                                    </Dropdown.Item>
                                    {isPublic ? (
                                        <Dropdown.Item onClick={handleCancelReview} className='dropdown-item-hover-green'>
                                            Make private
                                        </Dropdown.Item>
                                    ) : (
                                        <>
                                            <Dropdown.Item onClick={handleMakePrivate} className='dropdown-item-hover-green'>
                                                Make private
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenModal} className='dropdown-item-hover-blue'>
                                                Export to Store
                                            </Dropdown.Item>
                                        </>
                                    )}
                                    {!isPublic && (
                                        <Dropdown.Item onClick={handleRemoveProducts} className='dropdown-item-hover-red'>
                                            Remove
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <table className='table'>
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <Form.Check
                                            checked={selectAll || selectedAll ? true : false}
                                            onChange={(e) => handleSelectPageAll(e)}
                                        />
                                    </th>
                                    <th></th>
                                    <th className='text-center' scope="col">
                                        <FaFingerprint size={17} /> Product name
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FaCheck size={17} /> Public
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FiClock size={19} /> Created time
                                    </th>
                                    <th className='text-center' scope="col">
                                        <FiClock size={19} /> Last update time
                                    </th>
                                    <th className='text-center' scope="col">
                                        <BsStar size={19} /> Product rating
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productData && productData.length > 0 && productData.map((el) => (
                                    <Fragment key={el.id}>
                                        <tr className='table-row-body'>
                                            <td width="4%" className='text-center'>
                                                <Form.Check
                                                    onChange={(e) => handleSelectProduct(e, el.id)}
                                                    checked={selectedProducts.includes(el.id) || selectedAll ? true : false}
                                                />
                                            </td>
                                            <td className='cursor-pointer' onClick={() => handleExpandData(el.id)} width="2%">
                                                <MdArrowForwardIos className={el.expand ? 'rotate-expand-button' : ''} />
                                            </td>
                                            <td className='text-center max-width-td'>
                                                <span className='wrapped-table-row'>
                                                    {el.title}
                                                </span>
                                            </td>
                                            <td className='text-center'>
                                                {el.in_review ? (
                                                    <TfiReload size={18} />
                                                ) : (
                                                    <>
                                                        {el.is_public ? (
                                                            <FaCheck size={18} />
                                                        ) : (
                                                            <GrClose size={18} />
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                            <td className='text-center'>
                                                {el.created_at.split('T')[0]}
                                            </td>
                                            <td className='text-center'>
                                                {el.updated_at.split('T')[0]}
                                            </td>
                                            <td className='text-center'>
                                                <StarRatings
                                                    rating={parseFloat(el.rank ? el.rank?.rating : '0')}
                                                    numberOfStars={5}
                                                    starRatedColor="yellow"
                                                    starDimension="24px"
                                                    starSpacing="1px"
                                                />
                                            </td>
                                        </tr>
                                        {el.expand && (
                                            <tr>
                                                <td className='p-0' colSpan={7}>
                                                    <table className="table mb-0">
                                                        <thead style={{ backgroundColor: '#d2ebf1' }} className="thead-light">
                                                            <tr className='border-0'>
                                                                <th style={{ paddingLeft: '25px' }} className='text-center' scope="col">
                                                                    <FaFingerprint size={18} /> Variant name
                                                                </th>
                                                                <th className='text-center' scope="col">
                                                                    <FaWarehouse size={20} /> In Stock
                                                                </th>
                                                                <th className='text-center' scope="col">
                                                                    <BsSortNumericUpAlt size={20} /> In Order
                                                                </th>
                                                                <th className='text-center' scope="col">
                                                                    <FaBarcode size={20} /> SKU
                                                                </th>
                                                                <th className='text-center' scope="col">
                                                                    <FaDollarSign size={19} />Cost
                                                                </th>
                                                                <th className='text-center' scope="col">
                                                                    <FaDollarSign size={19} />MSRP
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {el.variants.map((variant) => (
                                                                <tr key={variant.id} className='nested-table-body'>
                                                                    <td width='30%' className='text-center max-width-td padding-product-nested-table'>
                                                                        <span className='wrapped-table-row'>
                                                                            <Link className='my-product-details-link' to={`/dashboard/my-products/${variant.id}`} >
                                                                                {variant.title}
                                                                            </Link>
                                                                        </span>
                                                                    </td>
                                                                    <td className='text-center'>{variant.inventories[0].amount_available}</td>
                                                                    <td className='text-center'>{variant.inventories[0].amount_reserve}</td>
                                                                    <td className='text-center'>{variant.sku}</td>
                                                                    <td className='text-center'>
                                                                        {variant.prices[0].currency.sign}{variant.prices[0].amount / 100}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {variant.prices[0].currency.sign}{variant.prices[0].msrp_amount / 100}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                        {pages.length > 1 && (
                            <PagePagination
                                pages={pages}
                                activePage={activePage}
                                handlePage={handlePage}
                            />
                        )}
                    </Row>
                </div>
            </div>
            {selectIntegration && (
                <SelectIntegration
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                    show={selectIntegration}
                    onHide={() => setSelectIntegration(false)}
                />
            )}
            <ExportCsv
                show={exportCsv}
                onHide={() => setExportCsv(false)}
            />
            <ImportCsv
                show={importCsv}
                onHide={() => setImportCsv(false)}
            />
        </section>
    )
}

export default MyProducts