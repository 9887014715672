export const THIS_FIELD_IS_REQUIRED = 'This field is required'
export const LOGIN_PAGE_URL = '/login'

export const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z.,@#!$%^&*()-_=+;'\"`\\d]{8,}$")

export const CRON_TIME = {
    "* * * * *": "Every 1 minute",
    "0 * * * *": "Every 1 hour",
    "0 */6 * * *": "Every 6 hour",
    "0 1 * * *": "Every 1 day",
    "0 0 */3 * *": "Every 3 day",
    "0 0 * * 0": "Every week",
    "0 0 1 * *": "Every month",
    "0 0 1 1 *": "Every year"
}