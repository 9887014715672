import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useGetIntegrationByIdQuery, useUpdateIntegrationMutation } from "../../../api/integrationsAPI";
import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { editIntegrationValidationSchema } from "./validator";

export type TEditIntegrationSubmitData = {
    name: string,
    is_seller: string | boolean,
    is_vendor: string | boolean,
    is_fulfillment_service: string | boolean,
    status?: string,
}

type TEditIntegrationProps = {
    show: boolean,
    onHide: () => void,
    id: number,
}

const EditIntegration: FC<TEditIntegrationProps> = ({ show, onHide, id }) => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const { data: integrationData } = useGetIntegrationByIdQuery(id)
    const [editIntegration] = useUpdateIntegrationMutation()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TEditIntegrationSubmitData>({
        resolver: yupResolver(editIntegrationValidationSchema),
    });

    const onSubmit = (data: TEditIntegrationSubmitData) => {
        const updateData: TEditIntegrationSubmitData = {
            name: data.name,
            is_seller: data.is_seller === 'true' ? true : false,
            is_vendor: data.is_vendor === 'true' ? true : false,
            is_fulfillment_service: data.is_fulfillment_service === 'true' ? true : false,
        }

        if (integrationData?.status.toUpperCase() !== data.status) {
            updateData.status = data.status
        }

        editIntegration({ data: updateData, id }).then((res) => {
            if ('error' in res) {
                handleCustomError(res.error)
            } else {
                onHide()
                setCustomError(null)
            }
        })
    }

    useEffect(() => {
        toast.error(customError)
    }, [customError])

    useEffect(() => {
        if (integrationData) {
            setValue('name', integrationData.name)
            setValue('is_seller', `${integrationData.is_seller}`)
            setValue('is_vendor', `${integrationData.is_vendor}`)
            setValue('is_fulfillment_service', `${integrationData.is_fulfillment_service}`)
            setValue('status', integrationData.status.toUpperCase())
        }
    }, [integrationData, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Edit Integration</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>First Name</Form.Label>
                        <Form.Control className="form-input-field" type="text" placeholder="Name"  {...register("name")} />
                        {errors.name && <p className="mb-0 form-field-error">{errors.name.message}</p>}
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Seller</Form.Label>
                        <Form.Select className="form-input-field" placeholder="Seller"  {...register("is_seller")}>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Vendor</Form.Label>
                        <Form.Select className="form-input-field" placeholder="Vendor"  {...register("is_vendor")}>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Fullfilment service</Form.Label>
                        <Form.Select className="form-input-field" placeholder="Fullfilment service"  {...register("is_fulfillment_service")}>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className='form-input-label'>Status</Form.Label>
                        <Form.Select className="form-input-field" placeholder="Status"  {...register("status")}>
                            <option value='ACTIVE'>Active</option>
                            <option value='INACTIVE'>Inactive</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button">
                            Update Integration
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditIntegration