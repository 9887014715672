import { FC } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { createTaskValidationSchema } from './validator'
import { useCreateJobMutation } from '../../../api/jobsAPI'

import './CreateTask.css'

export type TCreateTaskSubmitData = {
    function_name: string,
    function_arguments: string,
    periodic_schedule: string,
    allow_retries: string | boolean,
    queue: string,
    is_scheduled_for_retry: string | boolean,
    retry_times: number,
    max_retries: number,
    retry_seconds_interval: number,
    disabled: boolean,
    is_one_off: boolean,
}

type TCreateTaskProps = {
    show: boolean,
    onHide: () => void,
}

const CreateTask: FC<TCreateTaskProps> = ({ show, onHide }) => {
    const [createTask] = useCreateJobMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TCreateTaskSubmitData>({
        resolver: yupResolver(createTaskValidationSchema),
    });

    const onSubmit = (data: TCreateTaskSubmitData) => {
        const object = { 
            ...data, 
            function_arguments: data.function_arguments ? JSON.parse(data.function_arguments) : data.function_arguments 
        }
        createTask(object)
        onHide()
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Create Task</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>Function name <span className='red-color'>*</span></Form.Label>
                        <Form.Control className="form-input-field" type="string" placeholder="Function name"  {...register("function_name")} />
                        {errors.function_name && <p className="mb-0 form-field-error">{errors.function_name.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>Function Arguments</Form.Label>
                        <Form.Control as="textarea" className="form-input-field" type="string" placeholder="Function Arguments"  {...register("function_arguments")} />
                        {errors.function_arguments && <p className="mb-0 form-field-error">{errors.function_arguments.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>Queue</Form.Label>
                        <Form.Control className="form-input-field" type="string" placeholder="Queue"  {...register("queue")} />
                        {errors.queue && <p className="mb-0 form-field-error">{errors.queue.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className='form-input-label'>Interval</Form.Label>
                        <Form.Select className="form-input-field" placeholder="Interval"  {...register("periodic_schedule")}>
                            <option value="* * * * *">Every 1 minute</option>
                            <option value="0 * * * *">Every 1 hour</option>
                            <option value="0 */6 * * *">Every 6 hour</option>
                            <option value="0 1 * * *">Every 1 day</option>
                            <option value="0 0 */3 * *">Every 3 day</option>
                            <option value="0 0 * * 0">Every week</option>
                            <option value="0 0 1 * *">Every month</option>
                            <option value="0 0 1 1 *">Every year</option>
                        </Form.Select>
                        {errors.periodic_schedule && <p className="mb-0 form-field-error">{errors.periodic_schedule.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className='form-input-label'>Allow retries</Form.Label>
                        <Form.Select defaultValue="false" className="form-input-field" placeholder="Allow retries"  {...register("allow_retries")}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Select>
                        {errors.allow_retries && <p className="mb-0 form-field-error">{errors.allow_retries.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className='form-input-label'>Scheduled for Retry</Form.Label>
                        <Form.Select defaultValue="false" className="form-input-field" placeholder="Scheduled for Retry"  {...register("is_scheduled_for_retry")}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Select>
                        {errors.is_scheduled_for_retry && <p className="mb-0 form-field-error">{errors.is_scheduled_for_retry.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>Retry times</Form.Label>
                        <Form.Control className="form-input-field" type="number" placeholder="Retry times"  {...register("retry_times")} />
                        {errors.retry_times && <p className="mb-0 form-field-error">{errors.retry_times.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>Maximum retries</Form.Label>
                        <Form.Control className="form-input-field" type="number" placeholder="Maximum retries"  {...register("max_retries")} />
                        {errors.max_retries && <p className="mb-0 form-field-error">{errors.max_retries.message}</p>}
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>Retry Seconds Interval</Form.Label>
                        <Form.Control className="form-input-field" type="number" placeholder="Retry Seconds Interval"  {...register("retry_seconds_interval")} />
                        {errors.retry_seconds_interval && <p className="mb-0 form-field-error">{errors.retry_seconds_interval.message}</p>}
                    </Form.Group>
                    <Form.Group className="mt-2 d-flex aling-items-center">
                        <Form.Check
                            type='checkbox'
                            {...register("disabled")}
                        />
                        <Form.Label style={{ color: '#4a4b65' }} className='ms-2 mb-0'>Disabled</Form.Label>
                    </Form.Group>
                    <Form.Group className="mt-1 d-flex aling-items-center">
                        <Form.Check
                            type='checkbox'
                            {...register("is_one_off")}
                        />
                        <Form.Label style={{ color: '#4a4b65' }} className='ms-2 mb-0'>Run only once <span className='red-color'>*</span></Form.Label>
                    </Form.Group>
                    <div className='d-flex justify-content-end mt-3'>
                        <button type='submit' className="dashboard-menu-button">
                            Create Task
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateTask