import * as yup from 'yup'

import { THIS_FIELD_IS_REQUIRED } from './../../../helpers/constants';

export const editIntegrationValidationSchema = yup.object().shape({
    name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    is_seller: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    is_vendor: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    is_fulfillment_service: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    status: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
});