import * as yup from "yup";

import { THIS_FIELD_IS_REQUIRED } from "../../../../../helpers/constants";

export const loginValidatonSchema = yup.object().shape({
    email: yup
        .string()
        .email("Email address is invalid")
        .required(THIS_FIELD_IS_REQUIRED),
    password: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
});