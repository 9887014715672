import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ReactGA from 'react-ga'

import { useResetPasswordMutation } from '../../../../../api/authAPI';
import { resetPasswordValidationSchema } from './validator';

import Loader from '../../../../components/common/Loader/Loader';

import emailVerification from '../../../../assets/images/email_verification.png'

import './ResetPassword.css'

export type TResetPasswordForm = {
    email: string,
}

const ResetPassword = () => {
    const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TResetPasswordForm>({
        resolver: yupResolver(resetPasswordValidationSchema),
    });

    const onSubmit = (data: TResetPasswordForm) => {
        resetPassword(data)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <Container className='min-block-height d-flex align-items-center justify-content-center'>
            {isSuccess ? (
                <div className="text-center register-pending-wrapper">
                    <h2 className="mb-2">Reset Password</h2>
                    <div className="login-wrap">
                        <div>
                            <label className='mb-1'>
                                Please check your email inbox. To reset password, please follow the link in the email you receive.
                            </label>
                            <div className="mt-3">
                                <img className="register-pending-img" src={emailVerification} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Form className='login-wrap' onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="login-title">Reset Password </h3>
                    <Form.Group className='mt-3'>
                        <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                        {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button className="dark-button" type="submit">Send email</button>
                    </div>
                </Form>
            )}
        </Container>
    )
}

export default ResetPassword