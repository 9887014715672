import { FC } from "react"
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { CiCircleCheck } from 'react-icons/ci'

type TUserAlertProps = {
    type?: 'success' | 'announcement',
    text1?: string,
    text2?: string,
}

const UserAlert: FC<TUserAlertProps> = ({ type = 'success', text1, text2 }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center py-5">
            { type === 'success' && (
                <CiCircleCheck className="mb-3" fill='rgb(18, 63, 115)' size={80} />
            )}
            { type === 'announcement' && (
                <AiOutlineExclamationCircle className="mb-3" fill='rgb(18, 63, 115)' size={80} />
            )}
            { text1 && ( 
                <h5 className="p-0 m-0 text-center" style={{ color: 'rgb(18, 63, 115)' }}>{text1}</h5> 
            )}
            { text2 && ( 
                <h5 className="p-0 m-0 text-center" style={{ color: 'rgb(18, 63, 115)' }}>{text2}</h5>
            )}
        </div>
    )
}

export default UserAlert