import { ChangeEvent, FC, useState } from "react"
import { Modal } from "react-bootstrap"
import { useImportCsvFileMutation } from "../../../api/productsAPI"
import { toast } from "react-toastify"

type TImportCsvProps = {
    show: boolean,
    onHide: () => void,
}

const ImportCsv: FC<TImportCsvProps> = ({ show, onHide }) => {
    const [file, setFile] = useState<File | null>(null)

    const [importFile] = useImportCsvFileMutation()

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0])
        }
    }

    const handleSubmitFile = () => {
        if (file) {
            const formData = new FormData()

            formData.append('csv_file', file)

            importFile(formData).then((res) => {
                if ("error" in res) {
                    const { status } = res.error as { status: number }
                    if (status === 403) {
                        toast.error("You don't have permission to perform this action")
                    }
                    else {
                        toast.error('Something went wrong')
                    }
                } else {
                    if (res.data.created) {
                        toast.success("Update of product list started, you'll receive notification after its end")
                        onHide()
                    } else {
                        toast.error('Update of product list already started')
                        onHide()
                    }
                }
            })
        }
    }

    return (
        <Modal size="lg" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Import Products</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="d-flex flex-column">
                    <h5>Select a file:</h5>
                    <span className="mt-1">Please, make sure that you are using CSV file with comma or semicolon delimiter and Unicode UTF-8 encoding.</span>
                    <div style={{ border: '1px solid #ccc' }} className="p-2 mt-2">
                        <input onChange={(e) => handleChangeFile(e)} type="file" accept="text/csv" />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38" onClick={handleSubmitFile}>Import</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImportCsv