import { useEffect, useState } from "react"

const useDetectSubdomainOrPublic = (): [boolean, string] => {
    const [isPublic, setIsPublic] = useState(window.location.origin === process.env.REACT_APP_PUBLIC_TENANT_URL ? true : false)
    const [subdomain, setSubdomain] = useState('')

    useEffect(() => {
        const url = window.location.hostname
        const splittedUrl = url.split('.')

        if (window.location.origin !== process.env.REACT_APP_PUBLIC_TENANT_URL) {
            setSubdomain(splittedUrl[0])
            setIsPublic(false)
        } else {
            setSubdomain('')
            setIsPublic(true)
        }
    }, [])

    return [isPublic, subdomain]
}

export default useDetectSubdomainOrPublic