import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga'

import "./Terms.css"

const Terms = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
       <div className='min-block-height d-flex justify-content-center align-items-center'>
            <Container className='privacy-policy mt-4 mb-4'>
                <h3>CloudOrder.US Terms and Conditions</h3>
                <br /> 
                <div>
                    <p>Welcome to our website. We provide its
                    services to you subject to the notices, terms, and conditions set forth in this
                    agreement (the “Agreement”). In addition, when you use any service (e.g.,
                    Customer Reviews), you will be subject to the rules, guidelines, policies,
                    terms, and conditions applicable to such services, and they are incorporated
                    into this Agreement by this reference. Our website reserve the right to change
                    this site and these terms and conditions at any time.</p>
                    <br />
                    <p>Accessing, br /owsing, or otherwise using the
                    site indicates your agreement to all the terms and conditions in this
                    agreement. Please read this agreement carefully before proceeding.</p>
                    <br />
                    <p>You represent and warrant that you are at
                    least 18 years old or visiting the Site under the supervision of a parent or
                    guardian. Subject to the terms and conditions of this Agreement, our website
                    hereby grant you a limited, revocable, non-transferable and non-exclusive
                    license to access and use the Site by displaying it on your internet br /owser
                    only for the purpose of shopping on the Site and not for use on behalf of any
                    third party, except as explicitly permitted by our website in advance. Any
                    br /each of this Agreement shall result in the immediate revocation of the
                    license granted in this paragraph without notice to you.</p>
                    <br />
                    <p>Except as permitted in the paragraph above,
                    you may not reproduce, distribute, display, sell, lease, transmit, create
                    derivative works from, translate, modify, reverse-engineer, disassemble,
                    decompile or otherwise exploit this Site or any portion of it unless expressly
                    permitted by our website in writing. You may not make any commercial use of any
                    of the information provided on the Site or make any use of the Site for the
                    benefit of another business unless explicitly permitted by our website in
                    advance. Our website reserve the right to refuse service, terminate accounts,
                    and/or cancel orders in its discretion, including, without limitation, if our
                    website believe that customer conduct violates applicable law or is harmful to
                    our website’s interests.</p>
                    <br />
                    <p>You shall not upload to, distribute, or
                    otherwise publish through this Site any Content, information, or other material
                    that (a) violates or infringes the copyrights, patents, trademarks, service
                    marks, trade secrets, or other proprietary rights of any person; (b) is
                    libelous, threatening, defamatory, obscene, indecent, pornographic, or could
                    give rise to any civil or criminal liability under U.S. or international law;
                    or (c) includes any bugs, viruses, worms, trap doors, Trojan horses or other
                    harmful code or properties. Our website may assign you a password and account
                    identification to enable you to access and use certain portions of this Site.
                    Each time you use a password or identification, you will be deemed to be
                    authorized to access and use the Site in a manner consistent with the terms and
                    conditions of this Agreement, and our website has no obligation to investigate
                    the authorization or source of any such access or use of the Site.</p>
                    <br />
                    <p>You will be solely responsible for all access
                    to and use of this site by anyone using the password and identification
                    originally assigned to you whether or not such access to and use of this site
                    is actually authorized by you, including without limitation, all communications
                    and transmissions and all obligations (including, without limitation, financial
                    obligations) incurred through such access or use. You are solely responsible
                    for protecting the security and confidentiality of the password and
                    identification assigned to you. You shall immediately notify our website of any
                    unauthorized use of your password or identification or any other br /each or
                    threatened br /each of this Site’s security.</p>
                    <br />
                    <h3>Reviews and Comments</h3>
                    <br />
                    <p>Except as otherwise provided elsewhere in
                    this Agreement or on the site, anything that you submit or post to the site
                    and/or provide our website, including, without limitation, ideas, know-how,
                    techniques, questions, reviews, comments, and suggestions (collectively,
                    “Submissions”) is and will be treated as non-confidential and nonproprietary,
                    and by submitting or posting, you agree to irrevocably license the entry and
                    all IP rights related thereto (excluding the moral rights such as authorship
                    right) to our website without charge and our website shall have the
                    royalty-free, worldwide, perpetual, irrevocable, and transferable right to use,
                    copy, distribute, display, publish, perform, sell, lease, transmit, adapt,
                    create derivative works from such Submissions by any means and in any form, and
                    to translate, modify, reverse-engineer, disassemble, or decompile such
                    Submissions. All Submissions shall automatically become the sole and exclusive
                    property of our website and shall not be returned to you and you agree not to
                    raise any dispute in connection with any use of the entry by our website in the
                    future.</p>
                    <br />
                    <p>You warrant that your Submissions, in whole
                    or in part, are clear and free of any IP right infringement, disputes or third
                    party claims. Our website assumes no liability for any misuse of copyright or
                    any other rights of third parties by you. You undertake to defense for and
                    indemnify the Sponsor against any losses caused due to the use of the entries
                    for any purposes.</p>
                    <br />
                    <p>In addition to the rights applicable to any
                    Submission, when you post comments or reviews to the site, you also grant our
                    website the right to use the name that you submit with any review, comment, or
                    other Content, if any, in connection with such review, comment, or other
                    content. You represent and warrant that you own or otherwise control all of the
                    rights to the reviews, comments, and other Content that you post on this site
                    and that use of your reviews, comments, or other Content by our website will
                    not infringe upon or violate the rights of any third party. You shall not use a
                    false e-mail address, pretend to be someone other than yourself, or otherwise
                    mislead our website or third parties as to the origin of any Submissions or Content.
                    Our website may, but shall not be obligated to remove or edit any Submissions
                    (including comments or reviews) for any reason.</p>
                    <br />
                    <h3>Copyright</h3>
                    <br />
                    <p>All text, graphics, photographs or other
                    images, button icons, audio clips, logos, slogans, trade names or word software
                    and other contents on the website of our website (collectively, “Content”),
                    belongs exclusively to our website or its appropriate content suppliers. You
                    may not use, reproduce, copy, modify, transmit, display, publish, sell,
                    license, publicly perform, distribute or commercially exploit any of the
                    Content or otherwise dispose of any of the Content in a way not permitted by
                    our website, without its express prior written consent. The use of data mining,
                    robots, or similar data gathering and extraction tools on our website as well
                    as the use of our website trademarks or service marks in meta-tags is strictly
                    prohibited. You may view and use the Content only for your personal information
                    and for shopping and ordering on the site and for no other purpose. The
                    collection, arrangement, and assembly of all content on this site (the
                    “Compilation”) belong exclusively to our website. You may not use our website’s
                    Content or Compilation in any manner that disparages or discredits our website
                    or in any way that is likely to cause confusion or violation of any applicable
                    laws or regulations. All software used on this Site (the “Software”) is the
                    property of our website and/or its Software suppliers. The Content, the
                    Compilation and the Software are all protected under state, national and
                    international copyright laws. All rights not expressly granted are reserved by
                    our website. Violators will be prosecuted to the full extent of the law.</p>
                    <br />
                    <p>Our website recognizes and respects all
                    copyrights and trademarks. As such, any usage of television, motion picture, music,
                    film festival or other names or titles have no connection to our website and
                    are the sole property of the copyright or trademark holders.</p>
                    <br />
                    <h3>Intellectual Property Infringement Policy</h3>
                    <br />
                    <p>It is the policy of our website to take
                    appropriate action where necessary to uphold and recognize all relevant State,
                    Federal and International laws in connection with material that is claimed to
                    be infringing any trademark, copyright, patent and all or any other
                    Intellectual Property laws. If you are an intellectual property rights owner
                    and you believe that our website sells, offers for sale, or makes available
                    goods and/or services that infringe your intellectual property rights, then
                    contact us at Support Center.</p>
                    <br />
                    <h3>Information
                    required</h3>
                    <br />
                    <p>1. An electronic or physical signature of the
                    person authorized to act on behalf of the owner of an exclusive that is
                    allegedly infringed;</p>
                    <br />
                    <p>2. A description of the allegedly infringing
                    work or material;</p>
                    <br />
                    <p>3. A description of where the allegedly
                    infringing material is located on the site (product(s) URL);</p>
                    <br />
                    <p>4. Information reasonably sufficient to allow
                    us to contact you, such as your address, telephone number and e-mail address;</p>
                    <br />
                    <p>5. A statement by you that you have a good
                    faith belief that the disputed use of the material is not authorized by the copyright
                    or other proprietary right owner, its agent, or the law;</p>
                    <br />
                    <p>6. Identification of the intellectual
                    property rights that you claim are infringed by the Website(e.g. “XYZ
                    copyright”, “ABC trademark, Reg. No. 123456, registered 1/1/04”,etc);</p>
                    <br />
                    <p>7. A statement by you that the above
                    information and notification is accurate, and under penalty of perjury, that
                    you are the copyright owner or authorized to act on behalf of the owner whose
                    exclusive right is allegedly infringed.&lt;</p>
                    <br />
                    <h3>Termination
                    and Effect of Termination</h3>
                    <br />
                    <p>In addition to any other legal or equitable
                    remedies, our website may, without prior notice to you, immediately terminate
                    the Agreement or revoke any or all of your rights granted under this Agreement.
                    Upon any termination of this Agreement, you shall immediately cease all access
                    to and use of the site and our website shall, in addition to any other legal or
                    equitable remedies, immediately revoke all passwords, and account
                    identification issued to you and deny your access to and use of this Site in
                    whole or in part. Any termination of this Agreement shall not affect the
                    respective rights and obligations (including without limitation, payment
                    obligations) of the parties arising before the date of termination.</p>
                    <br />
                    <h3>Order Acceptance</h3>
                    <br />
                    <p>Please note that there may be certain orders
                    that we are unable to accept and must cancel. Our website reserves the right,
                    at sole discretion, to refuse or cancel any order for any reason. Some
                    situations that may result in your order being canceled include limitations on
                    quantities available for purchase, inaccuracies or errors in product or pricing
                    information, or problems identified by our credit and fraud avoidance
                    department. We may also require additional verifications or information before
                    accepting any order. We will contact you if all or any portion of your order is
                    canceled or if additional information is required to accept your order.</p>
                    <br />
                    <h3>Typographical
                    Errors</h3>
                    <br />
                    <p>While our website strives to provide accurate
                    product and pricing information, pricing or typographical errors may occur. Our
                    website cannot confirm the price of an item until after you order. In the event
                    that an item is listed at an incorrect price or with incorrect information due
                    to an error in pricing or product information, our website shall have the
                    right, at our sole discretion, to refuse or cancel any orders placed for that
                    item. In the event that an item is mis-priced, our website may, at our
                    discretion, either contact you for instructions or cancel your order and notify
                    you of such cancellation.</p>
                    <br />
                    <h3>Pricing
                    in Different Currencies</h3>
                    <br />
                    <p>Pricing of products sold by our website is
                    based upon figures calculated in U.S. Dollars (US$). Prices displayed in other
                    currencies are converted from U.S. Dollars according to the most up to date
                    conversion rates. Due to fluctuating currency values, prices displayed in
                    non-U.S. denominations of currency on the Site, other than on the individual
                    product page, may not be the most current. Areas of the Site where non-U.S.
                    denominations of currency might be inaccurate include, but are not limited to,
                    promotional banners, promotional pages, and information on product category
                    pages. The price displayed on an individual product page, regardless of
                    currency denomination, is the current price you are liable to pay to our
                    website, excluding shipping.</p>
                    <br />
                    <h3>Links</h3>
                    <br />
                    <p>This site may contain links to other sites on
                    the Internet that are owned and operated by third parties. You acknowledge that
                    our website is not responsible for the operation of or content located on or
                    through any such site.</p>
                    <br />
                    <h3>Remedies</h3>
                    <br />
                    <p>You agree that our website’s remedy at law
                    for any actual or threatened br /each of this Agreement would be inadequate and
                    that our website shall be entitled to specific performance or injunctive
                    relief, or both, in addition to any damages that our website may be legally
                    entitled to recover, together with reasonable expenses of any form of dispute
                    resolution, including, without limitation, attorneys’ fees.</p>
                    <br />
                    <p>No right or remedy of our website shall be
                    exclusive of any other, whether at law or in equity, including, without limitation,
                    damages injunctive relief, attorneys’ fees and expenses.</p>
                    <br />
                    <p>No instance of waiver by our website of its
                    rights or remedies under these terms and conditions shall imply any obligation
                    to grant any similar, future or other waiver.</p>
                    <br />
                    <h3>GOVERNING
                    LAW</h3>
                    <br />
                    <p>These terms and conditions are governed by
                    and are to be construed in accordance with the laws of United States.</p>
                    <br />
                    <p>Company name: Ichin Inc</p>
                    <br />
                    <p>&nbsp;</p>
                </div>    
            </Container>
       </div>
    );
}
export default Terms;