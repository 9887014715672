import { FC, useState } from 'react'
import { Carousel, Col, Modal, Row } from 'react-bootstrap'
import { IoClose } from 'react-icons/io5'
import { FiCheck } from 'react-icons/fi'
import StarRatings from 'react-star-ratings'

import { TProduct } from '../../../api/api.types'

import './DashboardCatalogProduct.css'

type TDashboardCatalogProductProps = {
    show: boolean,
    onHide: () => void,
    viewProduct: TProduct,
}

const DashboardCatalogProduct: FC<TDashboardCatalogProductProps> = ({ show, onHide, viewProduct }) => {
    const [activeVariant, setActiveVariant] = useState(0)

    const handleChangeVariant = (variant: number) => {
        setActiveVariant(variant)
    }

    return (
        <Modal size="xl" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
                <Row>
                    <Col className="col-lg-4">
                        <Carousel className='product-details-carousel'>
                            {viewProduct?.variants && viewProduct?.variants[activeVariant]?.images.length > 0 ? viewProduct?.variants[activeVariant]?.images?.map((el) => (
                                <Carousel.Item key={el.id}>
                                    <img
                                        className="d-block"
                                        src={el?.url}
                                        style={{ height: '352px', width: '352px' }}
                                        alt=""
                                    />
                                </Carousel.Item>
                            )) : (
                                <Carousel.Item>
                                    <div
                                        className="d-block"
                                        style={{ height: '352px', width: '352px' }}
                                    />
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className='product-variants-choose-block'>
                            {viewProduct.variants && viewProduct.variants.length > 0 && viewProduct.variants.map((variant, idx) => (
                                <div key={idx} onClick={() => handleChangeVariant(idx)} className={activeVariant === idx ? 'product-variant-block-active' : 'product-variant-block'}>
                                    <img src={variant.images[0]?.url} alt="" />
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col className='col-lg-8'>
                        <h5>{viewProduct.title}</h5>
                        <div className='d-flex flex-column mt-2'>
                            <span>
                                Product SKU: {viewProduct.variants[activeVariant].sku}
                            </span>
                            <span>
                                Product Price: {viewProduct.variants[activeVariant].prices[0].currency.sign}{viewProduct.variants[activeVariant].prices[0].amount / 100}
                            </span>
                            <span>
                                MSRP: {viewProduct.variants[activeVariant].prices[0].currency.sign}{viewProduct.variants[activeVariant].prices[0].msrp_amount / 100}
                            </span>
                            <span className='dashboard-catalog-product-description'>
                                Description: {viewProduct.variants[activeVariant].description}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <h6 className='mb-2'>Customers reviews:</h6>
                        {viewProduct.variants[activeVariant].reviews.length > 0 ? viewProduct.variants[activeVariant].reviews.map((review, idx) => (
                            <div className='review-block' key={idx}>
                                <div className='d-flex justify-content-between'>
                                    <span>
                                        <span className='bold-text'>Title</span>: {review.review_title}
                                    </span>
                                    <span>{review.review_date}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span>
                                        <span className='bold-text'>Review variant</span>: {review.review_variant}
                                    </span>
                                    <span>
                                        <span className='bold-text'>Verified</span>:
                                        {review.review_verified ? <FiCheck size={22} color="green" /> : <IoClose size={22} color='red' />}
                                    </span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span>{review.review_content}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span>
                                        <span className='bold-text'>Author</span>: {review.review_author}
                                    </span>
                                    <span>
                                        <StarRatings
                                            rating={parseFloat(review.review_rating ? String(review.review_rating) : '0')}
                                            numberOfStars={5}
                                            starRatedColor="yellow"
                                            starDimension="20px"
                                            starSpacing="1px"
                                        />
                                    </span>
                                </div>
                            </div>
                        )) : (
                            <div className='product-no-reviews-block'>
                                This product don`t have any reviews yet!
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default DashboardCatalogProduct