import * as yup from 'yup'

import { THIS_FIELD_IS_REQUIRED } from './../../../helpers/constants';

export const createTaskValidationSchema = yup.object().shape({
    function_name: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    function_arguments: yup
        .string()
        .notRequired(),
    periodic_schedule: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED),
    allow_retries: yup
        .string()
        .notRequired(),
    queue: yup
        .string()
        .notRequired(),
    is_scheduled_for_retry: yup
        .string()
        .notRequired(),
    retry_times: yup
        .number().transform((value, originalValue) => {
            return originalValue.trim() === '' ? undefined : value;
        })
        .notRequired(),
    max_retries: yup
        .number().transform((value, originalValue) => {
            return originalValue.trim() === '' ? undefined : value;
        })
        .notRequired(),
    retry_seconds_interval: yup
        .number().transform((value, originalValue) => {
            return originalValue.trim() === '' ? undefined : value;
        })
        .notRequired(),
    disabled: yup
        .boolean()
        .notRequired(),
    is_one_off: yup
        .boolean()
        .notRequired(),
});