import { FC, useEffect } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { toast } from "react-toastify"

import { useGetStripePaymentMethodQuery, useUpdateStripePaymentMethodMutation } from "../../../api/paymentsAPI"

export type TEditStripePaymentMethod = {
    default: string,
}

type TStripePaymentMethodProps = {
    show: boolean,
    onHide: () => void,
    id: number,
    refetchPaymentMethodsAfterUpdate: () => void,
}

const StripePaymentMethod: FC<TStripePaymentMethodProps> = ({ show, onHide, id, refetchPaymentMethodsAfterUpdate }) => {
    const [updateStripeMethod] = useUpdateStripePaymentMethodMutation()
    const { data } = useGetStripePaymentMethodQuery(id)

    const { register, handleSubmit, setValue } = useForm<TEditStripePaymentMethod>();

    const onSubmit = (data: TEditStripePaymentMethod) => {
        updateStripeMethod({ data: { default: data.default === "true" ? true : false }, id }).then((res) => {
            if ("error" in res) {
                toast.error('Something went wrong')
            } else {
                onHide()
                setTimeout(() => {
                    refetchPaymentMethodsAfterUpdate()
                }, 3000)
            }
        })
    }

    useEffect(() => {
        if (data) {
            setValue('default', data.default ? "true" : "false")
        }
    }, [data, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Stripe payment method</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column w-100 pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">Stripe card:</span>
                        <span>**** **** **** {data?.last4}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">Brand:</span>
                        <span>{data?.brand}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">Expiration month:</span>
                        <span>{data?.exp_month}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">Expiration year:</span>
                        <span>{data?.exp_year}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span className="me-2 bold-text">Default card:</span>
                        <Form.Select style={{ width: '110px' }} {...register("default")}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="dark-button">Save</button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default StripePaymentMethod