import { FC } from "react";

import Category from "../Category/Category";

import { TCategory } from "../../../../api/api.types";

import './CategoryTree.css'

type TCategoryTree = {
    categories: TCategory[],
    handleCategoryFilter: (value: string, handlePage?: (value: string) => void) => void,
    handlePage: (value: string) => void,
    setOpenCategories: (value: boolean) => void,
}

const CategoryTree: FC<TCategoryTree> = ({ categories, handleCategoryFilter, handlePage, setOpenCategories }) => {
    return (
        <div className="category-list">
            <ul>
                {categories.map((category) => (
                    <li key={category.id}>
                        <Category
                            name={category.name}
                            id={category.id}
                            children={category.children}
                            handleCategoryFilter={handleCategoryFilter}
                            handlePage={handlePage}
                            setOpenCategories={setOpenCategories}
                        />
                    </li>
                ))}
            </ul >
        </div>
    );
}

export default CategoryTree