import StarRatings from 'react-star-ratings'
import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { SlArrowRight } from 'react-icons/sl'
import { GoTrash } from 'react-icons/go'
import { BiSearch } from 'react-icons/bi'
import ReactGA from 'react-ga'

import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic'
import { useGetPublicProductsQuery } from '../../../../api/productsAPI'
import { useGetCategoriesQuery } from '../../../../api/categoriesAPI'
import usePagination from '../../../../hooks/usePagination'
import useFilters from '../../../../hooks/useFilters'
import { TProduct } from '../../../../api/api.types'

import DashboardCatalogProduct from '../../../modals/DashboardCatalogProduct/DashboardCatalogProduct'
import SelectIntegration from '../../../modals/SelectIntegration/SelectIntegration'
import PagePagination from '../../../components/common/Pagination/PagePagination'
import CategoryTree from '../../../components/layout/CategoryTree/CategoryTree'

import './DashboardCatalog.css'

const DashboardCatalog = () => {
    const { search, handleSearch, handleSearchFilter, searchFilter, onKeyDownSearch, handlePageSize, pageSize, clearFilters, categoryFilter, handleCategoryFilter } = useFilters()
    const [isPublic] = useDetectSubdomainOrPublic()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)
    const [selectedProducts, setSelectedProducts] = useState<number[]>([])
    const [viewProduct, setViewProduct] = useState<null | TProduct>(null)
    const [viewModal, setViewModal] = useState(false)
    const [showIntegrationsModal, setShowIntegrationsModal] = useState(false)
    const [openCategories, setOpenCategories] = useState(false)

    const { data: categories } = useGetCategoriesQuery()
    const { data: publicProductsData } = useGetPublicProductsQuery({
        activePage,
        searchFilter,
        pageSize,
        categoryFilter
    })

    const handleOpenIntegrationModal = () => {
        setShowIntegrationsModal(true)
    }

    const handleQuickView = (product: TProduct) => {
        setViewProduct(product)
        setViewModal(true)
    }

    const handleSelectProduct = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        if (e.target.checked) {
            setSelectedProducts((prev) => {
                return [...prev, id]
            })
        } else {
            setSelectedProducts((prev) => {
                return prev.filter((el) => el !== id)
            })
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        if (publicProductsData) {
            handlePagesCount(publicProductsData.count)
        }
    }, [publicProductsData, handlePagesCount])

    return (
        <section className='p-0' >
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2 ms-1">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                                    className="catalog-search-input"
                                    placeholder="Search"
                                />
                                <button onClick={() => handleSearchFilter(handlePage)} className="catalog-search-button pb-1">
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            Results per page: {pageSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>Results per page: 5</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>Results per page: 10</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>Results per page: 20</Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>Results per page: 50</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> Clear filters/search
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body catalog-dashboard-title-block'>
                    Select Products and Export to your store
                </div>
            </div>
            <div className='page-wrapper pt-0'>
                <div className='dashboard-body'>
                    <form className="h-100">
                        <div className="categories-wrapper-container">
                            <button onClick={() => setOpenCategories(!openCategories)} className="categories-button-show pl-0" type="button">
                                <span className='me-2'>Categories</span>
                                <SlArrowRight size={15} className={openCategories ? 'categories-item-icon-active' : ''} />
                            </button>
                            {openCategories && categories && (
                                <CategoryTree
                                    categories={categories?.results}
                                    handleCategoryFilter={handleCategoryFilter}
                                    handlePage={handlePage}
                                    setOpenCategories={setOpenCategories}
                                />
                            )}
                        </div>
                    </form>
                    <Row className="align-items-center mt-3">
                        <Col className="col-lg-6">
                            <div className="left">
                                <ul className="dashboard-menu-ul ps-0">
                                    <li className="dashboard-menu-li">
                                        <span className="catalog-active active-public public-menu-a">Products</span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        {!isPublic && (
                            <Col className="col-lg-6 d-flex justify-content-end">
                                <Dropdown className='d-flex justify-content-center'>
                                    <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                        Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={handleOpenIntegrationModal}>
                                            Export products to Store
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        )}
                    </Row>
                    <div className="row justify-content-center mt-5">
                        {publicProductsData && publicProductsData.results.length > 0 && publicProductsData.results.map((product) => (
                            <div key={product.id} className="card card-catalog mb-3">
                                <span onClick={() => handleQuickView(product)} className="quick-view">
                                    Quick View
                                </span>
                                <img className="category-cart-image" src={product?.variants[0]?.images[0]?.url} alt='' />
                                <div className="category-card-info d-flex justify-content-end flex-column">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className='d-flex align-items-center'>
                                            <Form.Check
                                                checked={selectedProducts.includes(product.id) ? true : false}
                                                onChange={(e) => handleSelectProduct(e, product.id)}
                                                className='dashboard-catalog-checkbox mt-1 me-3'
                                            />
                                            <div className='me-1' style={{ marginBottom: '3px' }}>
                                                <StarRatings
                                                    rating={parseFloat(product.rank ? product.rank.rating : '0')}
                                                    numberOfStars={5}
                                                    starRatedColor="yellow"
                                                    starDimension="20px"
                                                    starSpacing="1px"
                                                />
                                            </div>
                                            <span className="number-rating mt-1">{product.rank ? product.rank.rating : '0'}</span>
                                        </div>
                                    </div>
                                    <p className="text-truncate text-center">
                                        {product.title}
                                    </p>
                                </div>
                            </div>
                        ))}
                        {pages.length > 1 && (
                            <PagePagination
                                activePage={activePage}
                                pages={pages}
                                handlePage={handlePage}
                            />
                        )}
                    </div>
                </div>
            </div>
            {viewModal && viewProduct && (
                <DashboardCatalogProduct
                    show={viewModal}
                    onHide={() => setViewModal(false)}
                    viewProduct={viewProduct}
                />
            )}
            {showIntegrationsModal && (
                <SelectIntegration
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                    show={showIntegrationsModal}
                    onHide={() => setShowIntegrationsModal(false)}
                />
            )}
        </section>
    )
}

export default DashboardCatalog