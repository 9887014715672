import { useEffect, useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga'

import { useLoginMutation, useGetTenantsListMutation, useGetUserMutation } from '../../../../../api/authAPI';
import useHandleCustomRtkQueryError from '../../../../../hooks/useHandleCustomRtkQueryError';
import { selectAccessToken } from '../../../../../redux/reducers/auth/authSlice';
import { useAppSelector } from '../../../../../redux/hooks';
import { loginValidatonSchema } from './validator';

import Loader from '../../../../components/common/Loader/Loader';
import TenantsModal from '../TenantsModal/TenantsModal';

import './Login.css'

export type TLoginForm = {
    email: string,
    password: string,
}

const Login = () => {
    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const accessToken = useAppSelector(selectAccessToken)
    const [getUser] = useGetUserMutation()
    const [getTenants, { data: tenantsData }] = useGetTenantsListMutation()
    const [login, { data, isLoading, isSuccess }] = useLoginMutation()
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm<TLoginForm>({
        resolver: yupResolver(loginValidatonSchema),
    });

    const onSubmit = (data: TLoginForm) => {
        login(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
            }
        })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        const cookies = new Cookies()
        const invitationLink = cookies.get('invitationLink')

        if (invitationLink && accessToken) {
            navigate(`/invitations/accept_as_existing/${invitationLink}`)
        } else if (isSuccess && data && data.user.is_staff === true) {
            getUser()
        } else if (isSuccess && data && data.user.is_staff === false) {
            getTenants(data.access_token)
            setShowModal(true)
        }
    }, [data, isSuccess, getTenants, getUser, navigate, accessToken])

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="min-block-height d-flex justify-content-center align-items-center">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Form onSubmit={handleSubmit(onSubmit)} className="mt-5 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="login-title text-center">Log in to your account </h3>
                        <div className="login-wrap pt-3 d-flex justify-content-center flex-column">
                            <Form.Group>
                                <Form.Label className='form-input-label'>Email</Form.Label>
                                <Form.Control className="form-input-field" type="text" placeholder="Email"  {...register("email")} />
                                {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='form-input-label'>Password</Form.Label>
                                <Form.Control className="form-input-field" type="password" placeholder="Password" {...register("password")} />
                                {errors.password && <p className="mb-0 form-field-error">{errors.password.message}</p>}
                            </Form.Group>
                            { customError && (<p className="mb-0 mt-1 form-field-error">{customError}</p>)}
                            <div className="d-flex justify-content-between mt-3">
                                <p><Link to='/reset-password' className="dark-text"> Reset Password?</Link></p>
                                <button className="dark-button" type="submit">Log in</button>
                            </div>
                        </div>
                    </Form>
                </Row>
            </Container>
            <TenantsModal show={showModal} tenants={tenantsData ? tenantsData.results : null} />
        </div>
    )
}

export default Login